import styled from 'styled-components'

import CloseLineIcon from 'remixicon-react/CloseLineIcon'

import ModalBackground from '../../assets/images/others/modal-background.svg'

export const Container = styled.div`
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
  min-width: 30rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);

  @media (max-width: 48rem) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    min-width: 0;
  }
`

export const ModalMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  color: var(--white);
  font-family: 'Roboto', sans-serif;
  background-image: url(${ModalBackground});
  background-repeat: no-repeat;
  background-position: left top;
  background-color: var(--primary);
`
export const Content = styled.div`
  background-color: var(--white);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
`
export const ChildContent = styled.div`
  padding: 1rem;
`

export const Left = styled.div``
export const Right = styled.div``
export const Title = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  color: #fff;
`
export const Description = styled.div`
  color: #fff;
  font-size: 0.82rem;
  opacity: 0.5;
  font-weight: 400;
`

export const CloseIcon = styled(CloseLineIcon)`
  color: #fff;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(var(--black-rgb), 0.042);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0.7rem 1rem;
  gap: 1rem;
`
