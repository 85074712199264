import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../../constants'
import { HttpRequest, RequestType } from '../../plugins/httpRequest'
import { User } from '../../interfaces/user'
import { Company } from '../../interfaces/company'
// import { User } from "../../interfaces/user";

export const login = createAsyncThunk('auth', async ({ email, password }: { email: string; password: string }) => {
  const response = await fetch(`${API_URL}auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      password
    })
  })
  const result = await response.json()
  return result
})

export const register = createAsyncThunk('register', async ({ fullName, email, phone, password, announcementsIsActive }: { fullName: string; email: string; phone: string; password: string, announcementsIsActive: boolean }) => {
  const response = await fetch(`${API_URL}auth/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      fullName,
      email,
      phone,
      password,
      announcementsIsActive
    })
  })
  const result = await response.json()
  return result
}
)

export const getCurrentUser = createAsyncThunk('currentUser', async () => {
  const response = await HttpRequest<null, User>({
    url: 'auth/getUser',
    method: RequestType.GET
  })
  return response
})

export const getCurrentCompany = createAsyncThunk('currentCompany', async () => {
  const response = await HttpRequest<null, Company>({
    url: 'auth/currentCompany',
    method: RequestType.GET
  })
  return response
})

export const changeCompany = createAsyncThunk('changeCompany', async (companyId: any) => {
  const token = JSON.parse(window.localStorage.getItem('token') || '')
  const response = await fetch(`${API_URL}auth/switchCompany`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      mode: 'no-cors',
    },
    body: JSON.stringify({
      companyId
    })
  })
  const result = await response.json()
  return result
})

export const getCompany = createAsyncThunk('getCompany', async ({ companyId }: { companyId: string }) => {
  const response = await HttpRequest<null, Company>({
    url: `companies/${companyId}`,
    method: RequestType.GET
  })
  return response
}
)

// export const forgotPassword = createAsyncThunk(
//   "forgotPassword",
//   async ({ email }: { email: string }) => {
//     const response = await fetch(
//       `${API_URL}Auth/ForgotPassword?email=${email}`,
//       {
//         method: "POST",
//       }
//     );
//     const result = await response.json();
//     return result;
//   }
// );

// export const changePassowrd = createAsyncThunk(
//   "changePassowrd",
//   async ({
//     email,
//     password,
//     secretKey,
//   }: {
//     email: string;
//     password: string;
//     secretKey: string;
//   }) => {
//     const response = await fetch(
//       `${API_URL}Auth/ChangePassword?email=${email}&password=${password}&secretKey=${secretKey}`,
//       {
//         method: "POST",
//       }
//     );
//     const result = await response.json();
//     return result;
//   }
// );
