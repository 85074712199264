import styled from 'styled-components'

export const Container = styled.ul``

export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0 !important;
  }
`

export const ItemBottom = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0 !important;
  }
`

export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const Icon = styled.div<{ color: string; $nextcolor: string }>`
  background-color: ${({ color }) => color + '2A'};
  border-radius: 50%;
  width: 2.625rem;
  height: 2.625rem;
  color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.0625rem dashed ${({ color }) => color};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 0.0625rem;
    height: 1.625rem;
    background: ${({ color, $nextcolor }) => `linear-gradient(180deg, ${color} 0%, ${$nextcolor} 100%)`};
    bottom: -1.625rem;
    left: 50%;
    transform: translateX(-50%);
  }

  ${Item}:last-child &::before,
  ${ItemBottom}:last-child &::before {
    display: none;
  }
`

export const Texts = styled.div``

export const Date = styled.div<{ $activity: boolean }>`
  color: var(--text-second);
  font-size: 0.875rem;
  font-weight: 700;
  ${(props) => props.$activity && 'color: var(--icon);'}
  ${(props) => props.$activity && 'font-weight: 500;'}
  ${(props) => props.$activity && 'font-size: 0.625rem;'}
`

export const Title = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  font-size: 0.75rem;
  font-weight: 500;
  max-width: 9.375rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 0.25rem;
`

export const Right = styled.div``

export const Amount = styled.div<{ color: string }>`
  border-radius: 0.375rem;
  background: ${({ color }) => color + '2B'};
  color: ${({ color }) => color};
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.5rem;
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const Bottom = styled.div``

export const TopRight = styled.div`
  width: 100%;
`

export const TitleBottom = styled.div<{ color: string; $activity: boolean }>`
  color: ${(props) => (props.$activity ? 'var(--text-second)' : props.color)};
  font-size: 0.75rem;
  font-weight: 500;
  margin-top: 0.25rem;

  b {
    font-weight: 700;
    color: ${({ color }) => color};
  }

  span {
    font-weight: 700;
    color: var(--text-second);
  }
`
