import React, { useEffect } from 'react'
import Layout from '../../components/layout'
import { Container, Right } from '../Customers/detail/styles'
import DetailLeft from '../../components/detail/DetailLeft'
import { useAppDispatch, useAppSelector } from '../../services'
import Skeleton from 'react-loading-skeleton'

import DetailTitle from '../../components/detail/DetailTitle'
import DetailFooter from '../../components/detail/DetailFooter'

import UserFillIcon from "remixicon-react/UserFillIcon";
import MailFillIcon from "remixicon-react/MailFillIcon";
import MapPin2FillIcon from 'remixicon-react/MapPin2FillIcon'

import Folder3FillIcon from 'remixicon-react/Folder3FillIcon'
import BillFillIcon from 'remixicon-react/BillFillIcon'
import { BoxTitle, Left, StickyLeft, SummaryWrapper } from './styles'

import ProfileSummary from './components/ProfileSummary'
import { getCurrentCompany } from '../../services/authSlice'
import SubscriptionInfo from './components/SubscriptionInfo'
import { getSubscriptionInfo } from '../../services/subscriptionSlice'


interface ProfileDataItem {
    icon: JSX.Element;
    label: string;
    content: JSX.Element | null;
}

const ProfilePage = () => {
  const dispatch = useAppDispatch();
    const {email, fullName, _id, addres} = useAppSelector((state) => state.auth.user);
    const profileData: ProfileDataItem[] = [];
    const loading = useAppSelector((state) => state.customers.loading);
    const {company } = useAppSelector((state) => state.auth);

    const { subscription } = useAppSelector((state) => state.subscriptions)

    const subscriptionLoading = useAppSelector((state) => state.subscriptions.loading);

    useEffect(() => {
      dispatch(getCurrentCompany());
      dispatch(getSubscriptionInfo())
    }
    , [dispatch]);

  
    const pushInfo = (icon: JSX.Element, label: string, content: JSX.Element | null) => {
      if (content !== undefined && content !== null) {
        profileData.push({ icon, label, content });
      }else if(loading){
        profileData.push({ icon, label, content: <Skeleton width={200} /> });
      }
    };
    
    pushInfo(<UserFillIcon size={18} />, "Adı Soyadı", fullName ? <span>{fullName}</span> : null);
    pushInfo(<MailFillIcon size={18} />, "Eposta Adresi", email ? <span>{email}</span> : null);
    pushInfo(<MapPin2FillIcon size={18} />, "Adres", addres ? <span>{addres}</span> : null);

    const footerData = [
      {
        icon: <Folder3FillIcon size={20} />,
        label: "Dökümanlar",
        style: { background: '#565AE9' }
      },
      {
        icon: <BillFillIcon size={20} />,
        label: "Faturalarınız",
        style: { background: '#FF3D00' }
      }
    ]

    const profileSummary = [
      {
        name: "MÜŞTERİ",
        color: "#565AE9",
        count: company.customers?.length || 0,
      },
      {
        name: "TEDARİKÇİ",
        color: "#FF3D00",
        count: company.suppliers?.length || 0,
      },
      {
        name: "KAYITLI ÜRÜN",
        color: "#4CAF50",
        count: company.products?.length || 0,
      },
      {
        name: "SATIŞ",
        color: "#F5B74A",
        count: 36000,
      },
      {
        name: "ALIŞ",
        color: "#23B7E5",
        count: 22000,
      },
      {
        name: "TEKLİF",
        color: "#C62828",
        count: 12,
      },

    ]

  return (
    <Layout>
      <Container>
        <Left>

      <StickyLeft>
          <DetailTitle name={fullName} id={_id} />
          <DetailLeft data={profileData} />
          <DetailFooter data={footerData} />
        </StickyLeft>
        <StickyLeft>
          <BoxTitle><span>😍</span> Muhasebia Özetin</BoxTitle>
          <SummaryWrapper>
            {
              profileSummary.map(item => <ProfileSummary key={item.name} {...item} /> )
            }
          </SummaryWrapper>
        </StickyLeft>
        </Left>
        <Right>
          {
            subscriptionLoading ? <Skeleton count={30} /> : subscription.active ? <SubscriptionInfo subscription={subscription} /> : <p>Abonelik Başlat Kartı Gelecek</p>
          }
        </Right>
      </Container>
    </Layout>
  )
}

export default ProfilePage
