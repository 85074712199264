import React, { useEffect, useState } from 'react'
import { Container, Content, ContentWrapper, HeaderWrapper, SidebarWrapper } from './styles'
import Header from './header'
import Sidebar from './sidebar'
import Footer from './footer'

const Layout = ({ children }: any) => {
  const [menuState, setMenuState] = useState(
    localStorage.getItem('userConfig') ? JSON.parse(localStorage.getItem('userConfig') || '{}').menuState : false
  )

  useEffect(() => {
    const handleResize = () => {
      const isScreenSmall = window.innerWidth <= 900

      if (isScreenSmall) {
        setMenuState(true)
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [menuState])

  return (
    <Container>
      <SidebarWrapper>
        <Sidebar menuState={menuState} />
      </SidebarWrapper>

      <ContentWrapper>
        <HeaderWrapper>
          <Header menuState={menuState} setMenuState={setMenuState} />
        </HeaderWrapper>

        <Content>{children}</Content>

        <Footer />
      </ContentWrapper>
    </Container>
  )
}

export default Layout
