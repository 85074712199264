import React, { useState } from 'react'
import { useAppDispatch } from '../../../services'
import { register, login, getCurrentUser } from '../../../services/authSlice'
import { useNavigate } from 'react-router-dom'

import { motion } from 'framer-motion'

import {
  Author,
  AuthorRight,
  Comment,
  CommentBottom,
  CommentWrapper,
  Container,
  ImageWrapper,
  RegisterForm,
  RegisterWrapper,
  SliderWrapper,
  Stars,
  WelcomeDescription,
  WelcomeText,
  InputLabel,
  RegisterFormItem,
  RegisterFormBottom,
  ForgotPassword,
  LoginText,
  Logo
} from './styles'

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

import SliderImageTwo from '../../../assets/images/login/slider-img-2.jpg'

import StarLineIcon from 'remixicon-react/StarLineIcon'
import StarFillIcon from 'remixicon-react/StarFillIcon'
import { Button, Checkbox, Input } from 'antd'
import GoogleGIcon from '../../../assets/icons/auth/GoogleGIcon'
import { Link } from 'react-router-dom'
import LogoLight from '../../../assets/LogoLight'
import { toast } from 'react-toastify'

import PhoneInput from 'antd-phone-input'

import './style.css'

const RegisterPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [passwordCheck, setPasswordCheck] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [announcementsIsActive, setAnnouncementsIsActive] = useState(false)

  const handleRegister = async () => {
    if (!fullName) {
      return toast.error('👤 Adınızı giriniz')
    }
    if (!email) {
      return toast.error('📧 E-posta adresinizi giriniz')
    }
    if (!password) {
      return toast.error('🔑 Şifrenizi giriniz')
    }
    if (!passwordCheck) {
      return toast.error('🔑 Şifrenizi tekrar giriniz')
    }
    if (password !== passwordCheck) {
      return toast.error('🔐 Şifreler uyuşmuyor')
    }
    if (!termsAccepted) {
      return toast.error('📜 Kullanım koşullarını kabul etmelisiniz')
    }

    const registerPromise = new Promise(async (resolve, reject) => {
      try {
        const res = await dispatch(register({ fullName, email, phone, password, announcementsIsActive }))

        if (res.payload.error) {
          reject(res.payload.error)
          toast.error(res.payload.error)
        } else {
          resolve('Hesabınız Başarıyla Oluşturuldu 🎉')
          const loginRes = await dispatch(login({ email, password }))

          if (loginRes.payload.accessToken) {
            localStorage.setItem('token', JSON.stringify(loginRes.payload.accessToken))
            dispatch(getCurrentUser())
            navigate('/')
          }
        }
      } catch (error) {
        reject(error)
      }
    })

    toast.promise(registerPromise, {
      pending: 'Kayıt yapılıyor...',
      success: 'Hesabınız Başarıyla Oluşturuldu 🎉',
      error: 'Kayıt olurken bir hata oluştu'
    })
  }
  return (
    <Container>
      <motion.div
        initial={{ translateX: 600, opacity: 0 }}
        animate={{ translateX: 0, opacity: 1 }}
        exit={{ translateX: -600, opacity: 0 }}
      >
        <SliderWrapper>
          <ImageWrapper>
            <img src={SliderImageTwo} alt='' />
          </ImageWrapper>

          <CommentWrapper>
            <Comment>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer quis velit nec eros semper tempus id id
              ligula.”
            </Comment>

            <CommentBottom>
              <Author>
                <h1>Gökhan Balık</h1>
                <p>İmza Group Veri Teknolojileri A.Ş</p>
                <span>Yönetim Kurulu Başkanı</span>
              </Author>
              <AuthorRight>
                <Stars>
                  <StarFillIcon />
                  <StarFillIcon />
                  <StarFillIcon />
                  <StarFillIcon />
                  <StarLineIcon />
                </Stars>
              </AuthorRight>
            </CommentBottom>
          </CommentWrapper>
        </SliderWrapper>
      </motion.div>
      <RegisterWrapper>
        <Logo>
          <LogoLight />
        </Logo>
        <WelcomeText>Hesap Oluştur</WelcomeText>
        <WelcomeDescription>Hemen bilgileri doldur ve 14 Gün boyunca tüm özellikleri ücretsiz dene.</WelcomeDescription>

        <RegisterForm>
          <RegisterFormItem>
            <InputLabel htmlFor='fullName'>Adınız Soyadınız *</InputLabel>
            <Input
              size='large'
              type='text'
              id='fullName'
              style={{ marginTop: '8px', height: '46px' }}
              placeholder='Ad Soyad'
              onChange={(e) => setFullName(e.target.value)}
              onPressEnter={handleRegister}
            />
          </RegisterFormItem>

          <RegisterFormItem>
            <InputLabel htmlFor='mail'>E-posta Adresiniz *</InputLabel>
            <Input
              size='large'
              type='mail'
              id='mail'
              style={{ marginTop: '8px', height: '46px' }}
              placeholder='ornek@gmail.com'
              onChange={(e) => setEmail(e.target.value)}
              onPressEnter={handleRegister}
            />
          </RegisterFormItem>

          <RegisterFormItem>
            <InputLabel htmlFor='phone'>Telefon numaranız</InputLabel>

            <PhoneInput
              searchNotFound='Ülke Bulunmadı'
              preferredCountries={['tr']}
              id='phone'
              className='phoneInput'
              enableSearch
              onChange={(e) => {
                e.phoneNumber && e.countryCode && e.areaCode
                  ? setPhone(e.countryCode + e.areaCode + e.phoneNumber)
                  : setPhone('')
              }}
            />
          </RegisterFormItem>

          <RegisterFormItem>
            <InputLabel htmlFor='password'>Şifre</InputLabel>

            <Input.Password
              placeholder='Şifrenizi giriniz'
              size='large'
              id='password'
              style={{ marginTop: '8px', height: '46px' }}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              onChange={(e) => setPassword(e.target.value)}
              onPressEnter={handleRegister}
            />
          </RegisterFormItem>
          <RegisterFormItem>
            <InputLabel htmlFor='password-check'>Şifre Tekrarı</InputLabel>

            <Input.Password
              placeholder='Şifrenizi tekrar giriniz'
              size='large'
              id='password-check'
              style={{ marginTop: '8px', height: '46px' }}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              onChange={(e) => setPasswordCheck(e.target.value)}
              onPressEnter={handleRegister}
            />
          </RegisterFormItem>
          <RegisterFormBottom>
            <Checkbox onChange={(e) => setTermsAccepted(e.target.checked)}>
              Hesabınızı oluşturarak, <Link to='#'>Kullanım Koşulları'nı</Link> kabul etmiş olursunuz.
            </Checkbox>
            <Checkbox onChange={(e) => setAnnouncementsIsActive(e.target.checked)}>
              Kampanya, duyuru ve gelişmelerden haberdar et.
            </Checkbox>
          </RegisterFormBottom>

          <Button type='primary' size='large' style={{ height: '46px' }} onClick={handleRegister}>
            {' '}
            Hesabı Oluştur
          </Button>

          <Button
            style={{
              fontSize: '16px',
              color: 'rgba(56, 56, 83, 0.90);',
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '16px',
              borderWidth: '2px',
              height: '46px'
            }}
            icon={<GoogleGIcon />}
            size='large'
          >
            Google ile kayıt ol
          </Button>
          <LoginText>
            Zaten hesabınız var mı?
            <Link to='/kullanici-girisi'> Giriş Yap</Link>
          </LoginText>
        </RegisterForm>
      </RegisterWrapper>
    </Container>
  )
}

export default RegisterPage
