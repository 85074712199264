import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { getVariants, deleteVariant, createVariant, updateVariant, updateVariantValue } from './api'
export {  getVariants, deleteVariant, createVariant, updateVariant, updateVariantValue }

export interface VariantState {
  variants: any
  loading: boolean
  error: any
}

const initialState: VariantState = {
  variants: [],
  loading: true,
  error: ''
}

export const variantsSlice = createSlice({
  name: 'variants',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVariants.pending, (state) => {
        state.loading = true
        state.error = ''
      })
      .addCase(getVariants.fulfilled, (state, action) => {
        if (action.payload) {
          state.variants = action.payload
        }
        state.loading = false
      })
      .addCase(getVariants.rejected, (state) => {
        state.error = 'Error fetching variants data!'
        toast.error('Get variants failed!')
      })

    builder
      .addCase(createVariant.pending, (state) => {
        state.error = ''
      }
      )
      .addCase(createVariant.fulfilled, (state, action) => {
        if (action.payload) {
          const payload = action.payload as { error?: boolean; message?: string }

          const status = payload.error ? 'error' : 'success'
          const message = payload.message

          if (message) {
            toast[status](message)
          }
        }
      })
      .addCase(createVariant.rejected, (state) => {
        state.error = 'Error creating customer!'
        toast.error('Varyant oluşturulurken bir hata oluştu!')
      })
      
    builder
      .addCase(updateVariant.pending, (state) => {
        state.error = ''
      }
      )
      .addCase(updateVariant.fulfilled, (state, action) => {
        if (action.payload) {
          const payload = action.payload as { error?: boolean; message?: string }

          const status = payload.error ? 'error' : 'success'
          const message = payload.message

          if (message) {
            toast[status](message)
          }
        }
      })
      .addCase(updateVariant.rejected, (state) => {
        state.error = 'Error updating customer!'
        toast.error('Varyant değeri güncellenirken bir hata oluştu!')
      })

    builder
      .addCase(updateVariantValue.pending, (state) => {
        state.error = ''
      }
      )
      .addCase(updateVariantValue.fulfilled, (state, action) => {
        if (action.payload) {
          const payload = action.payload as { error?: boolean; message?: string }

          const status = payload.error ? 'error' : 'success'
          const message = payload.message

          if (message) {
            toast[status](message)
          }
        }
      })
      .addCase(updateVariantValue.rejected, (state) => {
        state.error = 'Error updating customer!'
        toast.error('Varyant değeri güncellenirken bir hata oluştu!')
      })
      

    builder
      .addCase(deleteVariant.pending, (state) => {
        state.error = ''
      })
      .addCase(deleteVariant.fulfilled, (state, action) => {
        if (action.payload) {
          const payload = action.payload as { error?: boolean; message?: string }

          const status = payload.error ? 'error' : 'success'
          const message = payload.message

          if (message) {
            toast[status](message)
          }
        }
      })
      .addCase(deleteVariant.rejected, (state) => {
        state.error = 'Error deleting customer!'
        toast.error('Varyant silinirken bir hata oluştu!')
      })
  }
})

export default variantsSlice.reducer
