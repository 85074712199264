import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  :root {
    --default-font-size: ${(props) => props.theme.themeFontSize.toString() + 'px'};

    --primary: ${(props) => props.theme.themeColor};
    --primary-rgb: ${(props) => props.theme.themeRgbColor};
    --white: ${(props) => props.theme.selectedTheme.white};
    --white-rgb: ${(props) => props.theme.selectedTheme.whiteRgb};
    --black: ${(props) => props.theme.selectedTheme.black};
    --black-rgb: ${(props) => props.theme.selectedTheme.blackRgb};
    --text-primary: ${(props) => props.theme.selectedTheme.textPrimary};
    --text-second: ${(props) => props.theme.selectedTheme.textSecond};
    --icon: ${(props) => props.theme.selectedTheme.icon};

    --bg-primary: ${(props) => props.theme.selectedTheme.body};
    --sidebarBg: ${(props) => props.theme.selectedTheme.sidebarBg};
    --header: ${(props) => props.theme.selectedTheme.header};
    --header-item: ${(props) => props.theme.selectedTheme.headerItem};

    //Dashboard
    --expense-bg: ${(props) => props.theme.selectedTheme.expenseBg};
    --stock-bg: ${(props) => props.theme.selectedTheme.stockBg};
    --turnover-bg: ${(props) => props.theme.selectedTheme.turnoverBg};

  }

  html{
    font-size: var(--default-font-size);
  }

  body {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  body #root {
    overflow-x: clip;
  }
`

export const lightTheme = {
  body: '#F8F9F9',
  textPrimary: '#333335',
  textSecond: '#383853',
  icon: '#7C7C8D',

  // Primary
  white: '#ffffff',
  whiteRgb: '255, 255, 255',
  black: '#211C19',
  blackRgb: '33, 28, 25',
  sidebarBg: '#211c19',

  //Header
  header: '#f8f9f9',
  headerItem: '#ffffff',

  //Dashboard
  turnoverBg: '#fff4de',
  expenseBg: '#dcfce7',
  stockBg: '#f3e8ff'
}
export const darkTheme = {
  body: '#121212',
  textPrimary: '#ffffff',
  textSecond: '#c7c7c7',
  icon: '#ffffff',

  // Primary
  white: '#1e1e1e',
  whiteRgb: '30, 30, 30',
  black: '#ffffff',
  blackRgb: '255, 255, 255',
  sidebarBg: '#181818',

  //Header
  header: '#1f1f1f',
  headerItem: '#121212',

  //Dashboard
  turnoverBg: '#a1503d',
  expenseBg: '#005630',
  stockBg: '#370056'
}
