const currencyList = [
    {
        code: 'tr-TR',
        value: 'TRY',
        label: 'Türk Lirası (₺)',
        symbol: '₺',
    },
    {
        code: 'en-US',
        value: 'USD',
        label: 'Amerikan Doları ($)',
        symbol: '$',
    },
    {
        code: 'de-DE',
        value: 'EUR',
        label: 'Euro (€)',
        symbol: '€',
    },
    {
        code: 'en-GB',
        value: 'GBP',
        label: 'İngiliz Sterlini (£)',
        symbol: '£',
    },
    {
        code: 'ja-JP',
        value: 'JPY',
        label: 'Japon Yeni (¥)',
        symbol: '¥',
    },
    {
        code: 'ru-RU',
        value: 'RUB',
        label: 'Rus Rublesi (₽)',
        symbol: '₽',
    },
    {
        code: 'sv-SE',
        value: 'CHF',
        label: 'İsviçre Frangı (₣)',
        symbol: '₣',
    },
    {
        code: 'en-CA',
        value: 'CAD',
        label: 'Kanada Doları (C$)',
        symbol: 'C$',
    },
    {
        code: 'en-AU',
        value: 'AUD',
        label: 'Avustralya Doları (A$)',
        symbol: 'A$',
    },
    {
        code: 'zh-CN',
        value: 'CNY',
        label: 'Çin Yuanı (¥)',
        symbol: '¥',
    },
    {
        code: 'ta-IN',
        value: 'INR',
        label: 'Hindistan Rupisi (₹)',
        symbol: '₹',
    },
    {
        code: 'ko-KR',
        value: 'KRW',
        label: 'Güney Kore Wonu (₩)',
        symbol: '₩',
    },
    {
        code: 'ar-SA',
        value: 'SAR',
        label: 'Suudi Arabistan Riyali (﷼)',
        symbol: '﷼',
    },
    {
        code: 'en-ZA',
        value: 'ZAR',
        label: 'Güney Afrika Randı (R)',
        symbol: 'R',
    },
    {
        code: 'en-US',
        value: 'BGN',
        label: 'Bulgar Levası (лв)',
        symbol: 'лв',
    },
    {
        code: 'cs-CZ',
        value: 'CZK',
        label: 'Çek Korunası (Kč)',
        symbol: 'Kč',
    },
    {
        code: 'da-DK',
        value: 'DKK',
        label: 'Danimarka Kronu (kr)',
        symbol: 'kr',
    },
    {
        code: 'hu-HU',
        value: 'HUF',
        label: 'Macar Forinti (Ft)',
        symbol: 'Ft',
    },
    {
        code: 'pl-PL',
        value: 'PLN',
        label: 'Polonya Zlotisi (zł)',
        symbol: 'zł',
    },
    {
        code: 'ro-RO',
        value: 'RON',
        label: 'Romen Leyi (lei)',
        symbol: 'lei',
    },
    {
        code: 'de-CH',
        value: 'SEK',
        label: 'İsveç Kronu (kr)',
        symbol: 'kr',
    }
]

export default currencyList;