import { useEffect, useState } from 'react'
import SuppliersDetailedListTable from './detailed-list'
import SuppliersTableFilter from './filter'
import SuppliersListTable from './list'
import SuppliersSelectedItemActions from './filter/components/selected-actions'
import { AnimatePresence, motion } from 'framer-motion'
import SuppliersGridTable from './grid'
import { useAppDispatch, useAppSelector } from '../../../../services'
import { getSuppliers } from '../../../../services/suppliersSlice'
import { deleteSupplier } from '../../../../services/suppliersSlice'

const SuppliersTable = ({suppliers}: any) => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector((state) => state.suppliers.loading)
  const [page, setPage] = useState(1)

  const [tableViewType, setTableViewType] = useState('list')
  const [hasItemSelect, setHasItemSelect] = useState(false)
  useEffect(() => {
    dispatch(getSuppliers({ page: page }))
  }, [page, dispatch])

  // tablolar arası geçişte sayfa numarasını sıfırlıyoruz
  useEffect(() => {setPage(1)}, [tableViewType])

  // müşteriyi silerken bekliyoruz sonra tekrardan çekiyoruz listeden kaybolması için
  const deleteSupplierHandler = async (supplierId: string) => {
    await dispatch(deleteSupplier(supplierId))
    dispatch(getSuppliers({ page: page }))
  }

  return (
    <>
      <AnimatePresence>
        <div style={{ position: 'relative' }}>
          {hasItemSelect ? (
            <motion.div
              initial={{ opacity: 0, position: 'absolute', left: -24 }}
              key='SuppliersSelectedItemActions'
              animate={{ opacity: 1, position: 'relative', left: 0 }}
              exit={{ opacity: 0, position: 'absolute', left: -24 }}
              transition={{ duration: 0.3 }}
            >
              <SuppliersSelectedItemActions />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0, position: 'absolute', right: -24 }}
              key='SuppliersTableFilter'
              animate={{ opacity: 1, position: 'relative', right: 0 }}
              exit={{ opacity: 0, position: 'absolute', right: -24 }}
            >
              <SuppliersTableFilter tableViewType={tableViewType} setTableViewType={setTableViewType} />
            </motion.div>
          )}
        </div>
      </AnimatePresence>

      {tableViewType === 'list' && (
        <SuppliersListTable
          setHasItemSelect={setHasItemSelect}
          suppliers={suppliers}
          setPage={setPage}
          loading={loading}
          deleteSupplierHandler={deleteSupplierHandler}
        />
      )}
      {tableViewType === 'detailed' && <SuppliersDetailedListTable setHasItemSelect={setHasItemSelect} />}
      {tableViewType === 'grid' && <SuppliersGridTable setPage={setPage} loading={loading} suppliers={suppliers} />}
    </>
  )
}

export default SuppliersTable
