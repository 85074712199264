import { createSlice } from '@reduxjs/toolkit'
import { ToastContent, toast } from 'react-toastify'
import { getSuppliers, getSupplierById, createSupplier, updateSupplier, deleteSupplier } from './api'
import { Suppliers } from '../../interfaces/suppliers'
export { getSuppliers, getSupplierById, createSupplier, updateSupplier, deleteSupplier }

export interface SupplierState {
  suppliers: any
  supplier: Suppliers
  /* meta: any;
  skip: number;
  length: number; */
  loading: boolean
  error: any
}

const initialState: SupplierState = {
  suppliers: [],
  supplier: {
      _id: '',
      name: '',
      taxExempt: false,
      supplierCurrency: 'try'
    },
  /* meta: null,
  skip: 0,
  length: 0, */
  loading: true,
  error: ''
}

export const suppliersSlice = createSlice({
  name: 'supliers',
  initialState,
  reducers: {
    /* incrementSkip: (state) => {
      state.skip += 1;
    },
    decrementSkip: (state) => {
      state.skip -= 1;
    },
    resetSkip: (state) => {
      state.skip = 0;
    },
    setSkip: (state, action) => {
      state.skip = action.payload;
    }, */
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSuppliers.pending, (state) => {
        state.loading = true
        state.error = ''
      })
      .addCase(getSuppliers.fulfilled, (state, action) => {
        if (action.payload) {
          state.suppliers = action.payload
        }
        state.loading = false
      })
      .addCase(getSuppliers.rejected, (state) => {
        state.error = 'Error fetching suppliers data!'
        toast.error('Get suppliers failed!')
      })

    builder
      .addCase(getSupplierById.pending, (state) => {
        state.loading = true
        state.error = ''
      })
      .addCase(getSupplierById.fulfilled, (state, action) => {
        if (action.payload) {
          state.supplier = action.payload as Suppliers
        }
        state.loading = false
      })
      .addCase(getSupplierById.rejected, (state) => {
        state.error = 'Error fetching supplier data!'
        toast.error('Get supplier failed!')
      })

    builder
      .addCase(createSupplier.pending, (state) => {
        state.error = ''
      }
      )
      .addCase(createSupplier.fulfilled, (state, action) => {
        if (action.payload) {
          const payload = action.payload as { error?: boolean; message?: string }

          const status = payload.error ? 'error' : 'success'
          const message = payload.message

          if (message) {
            toast[status](message)
          }
        }
      })
      .addCase(createSupplier.rejected, (state) => {
        state.error = 'Error suplier supplier!'
        toast.error('Tedarikçi oluşturulurken bir hata oluştu!')
      })
      
    builder
      .addCase(updateSupplier.pending, (state) => {
        state.error = ''
      }
      )
      .addCase(updateSupplier.fulfilled, (state, action) => {
        if (action.payload) {
          const payload = action.payload as { error?: boolean; message?: string }

          const status = payload.error ? 'error' : 'success'
          const message = payload.message

          if (message) {
            toast[status](message)
          }
        }
      })
      .addCase(updateSupplier.rejected, (state) => {
        state.error = 'Error updating supplier!'
        toast.error('Tedarikçi güncellenirken bir hata oluştu!')
      })

    builder
      .addCase(deleteSupplier.pending, (state) => {
        state.error = ''
      })
      .addCase(deleteSupplier.fulfilled, (state, action) => {
        if (action.payload) {
          const payload = action.payload as { error?: boolean; message?: string }

          const status = payload.error ? 'error' : 'success'
          const message = payload.message

          if (message) {
            toast[status](message)
          }
        }
      })
      .addCase(deleteSupplier.rejected, (state) => {
        state.error = 'Error deleting supplier!'
        toast.error('Tedarikçi silinirken bir hata oluştu!')
      })
  }
})

export default suppliersSlice.reducer
