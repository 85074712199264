import React, { useEffect, useState } from 'react'
import { HeaderItem } from '../styles'
import SettingsIcon from 'remixicon-react/Settings3LineIcon'
import {
  CloseIcon,
  ColorItem,
  Header,
  Modal,
  ModalMask,
  SettingsColor,
  SettingsItemTitle,
  SettingsItemDescription,
  SettingsFont,
  SettingsTheme,
  SettingsTitle,
  ThemeColorItems,
  ThemeImage,
  ThemeItem,
  ThemeItemContainer,
  ThemeName,
  ButtonContainer
} from './styles'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'

import MocksThemeSettings from '../../../../../mocks/header-settings/tema-ayarlari.json'
import { Button, Slider } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  chageThemeColor,
  changeThemeFontSize,
  changeThemeMode,
  changeThemeRgbColor
} from '../../../../../services/theme/themeSlice'

const HeaderUserSettingsButton = () => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    document.body.style.overflow = showModal ? 'hidden' : 'unset'
  }, [showModal])

  const currentThemeColor = useSelector((state: any) => state.theme.themeColor)
  const [themeColor, setThemeColor] = useState(currentThemeColor)

  const handleThemeColorChange = (color: string, rgbColor: string) => {
    setThemeColor(color)
    dispatch(chageThemeColor(color))
    dispatch(changeThemeRgbColor(rgbColor))
  }

  const [themeMode, setThemeMode] = useState<string>('light')
  const handleThemeModChange = (mode: string) => {
    dispatch(changeThemeMode(mode))
    setThemeMode(mode)
  }


  const marks: any = MocksThemeSettings.fontSizes.reduce((acc: any, item) => {
    acc[item.value] = item.id
    return acc
  }, {})

  const [fontSize, setFontSize] = useState<number>(16)
  const handleFontSizeChange = (value: number) => {
    dispatch(changeThemeFontSize(value))
    setFontSize(value as number)
  }

  const handleDefaultThemeSettings = () => {
    dispatch(chageThemeColor('#565ae9'))
    setThemeColor('#565ae9')
    dispatch(changeThemeRgbColor('86, 90, 233'))
    dispatch(changeThemeMode('light'))
    setThemeMode('light')
    dispatch(changeThemeFontSize(16))
    setFontSize(16)
    setShowModal(false)
  }

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <motion.div
            key='settings-modal-mask'
            style={{ zIndex: 998, position: 'absolute', top: 0, right: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25 }}
          >
            <ModalMask onClick={handleCloseModal} />
          </motion.div>
        )}

        {showModal && (
          <motion.div
            style={{ zIndex: 999, position: 'absolute', top: 0, right: 0 }}
            initial={{ opacity: 0, transform: 'translateX(600px)' }}
            animate={{ opacity: 1, transform: 'translateX(0px)' }}
            exit={{ opacity: 0, transform: 'translateX(600px)' }}
            transition={{ duration: 0.25 }}
          >
            <Modal>
              <Header>
                <SettingsTitle>Tema Ayarları</SettingsTitle>
                <CloseIcon onClick={handleCloseModal} size={30} />
              </Header>
              <SettingsColor>
                <SettingsItemTitle>Tema Rengi</SettingsItemTitle>
                <SettingsItemDescription>Temanın temel rengini değiştirebilirsiniz.</SettingsItemDescription>
                <ThemeColorItems>
                  {MocksThemeSettings.colors.map((color, index) => (
                    <ColorItem
                      onClick={() => handleThemeColorChange(color.hexcode, color.rgb)}
                      isSelected={themeColor === color.hexcode}
                      key={index}
                      color={color.hexcode}
                    />
                  ))}
                </ThemeColorItems>
              </SettingsColor>

              <SettingsTheme>
                <SettingsItemTitle>Tema Modu</SettingsItemTitle>
                <SettingsItemDescription>Temanın modunu buradan değiştirebilirsiniz.</SettingsItemDescription>
                <ThemeItemContainer>
                  {MocksThemeSettings.theme.map((theme, index) => (
                    <ThemeItem
                      isSelected={themeMode === theme.id}
                      onClick={() => handleThemeModChange(theme.id)}
                      key={index}
                    >
                      <ThemeImage src={theme.image} />
                      <ThemeName>{theme.name}</ThemeName>
                    </ThemeItem>
                  ))}
                </ThemeItemContainer>
              </SettingsTheme>

              <SettingsFont>
                <SettingsItemTitle>Yazı Tipi</SettingsItemTitle>
                <SettingsItemDescription>Temanın yazı büyüklüğünü buradan ayarlayabilirsiniz.</SettingsItemDescription>

                <Slider
                  onChange={handleFontSizeChange}
                  marks={marks}
                  step={null}
                  defaultValue={fontSize}
                  min={14}
                  max={18}
                />
              </SettingsFont>

              <ButtonContainer>
                <Button type='primary' size='large' block>
                  Kaydet
                </Button>
                <Button onClick={handleDefaultThemeSettings} type='dashed' size='large' block>
                  Varsayılana Dön
                </Button>
              </ButtonContainer>
            </Modal>
          </motion.div>
        )}
      </AnimatePresence>
      <HeaderItem onClick={handleOpenModal}>
        <SettingsIcon size="1.375rem" />
      </HeaderItem>
    </>
  )
}

export default HeaderUserSettingsButton
