import React, { useState } from 'react'
import { AntInputNumber, Container, ItemContainer, PriceListContainer } from './styles'
import { Checkbox, Col, Row, Select, Switch, Tooltip } from 'antd'
import FormInput from '../../../../../components/input'
import { InformationIcon, Title } from '../../../../../components/input/styles'

import HomeSmile2LineIcon from 'remixicon-react/HomeSmile2LineIcon'
import PassportLineIcon from 'remixicon-react/PassportLineIcon'
import AlarmWarningLineIcon from 'remixicon-react/AlarmWarningLineIcon'
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon'
import ScissorsCutLineIcon from 'remixicon-react/ScissorsCutLineIcon'
import Wallet3LineIcon from 'remixicon-react/Wallet3LineIcon'
import currencyList from '../../../../../mocks/enums/currencyList'

const CustomersCurrentInformationTab = ({setData}: any) => {
  const [currentCurrency, setCurrentCurrency] = useState<any>('₺')
  const [priceListIsEnabled, setPriceListIsEnabled] = useState<boolean>(false)

  const handleCustomerCurrencyChange = (value: { value: string; label: React.ReactNode }) => {
    const label = value?.label?.toString()
    const match = label?.match(/\((.*?)\)/)
    const currencyValue = value?.value

    const currency = match ? match[1] : null

    setCurrentCurrency(currency)

    setData({ target: { name: 'customerCurrency', value: currencyValue } })
  }

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const handlePriceListIsEnabledChange = (value: boolean) => {
    setPriceListIsEnabled(value)
  }

  return (
    <Container>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <FormInput
            label='Vergi Dairesi'
            icon={<HomeSmile2LineIcon size='1.5rem' />}
            placeholder='Vergi Dairesi'
            tooltip='Veri Dairesinin adı.'
            tooltipPosition='input'
            name="taxAdministration"
            onChange={setData}
          />
        </Col>
        <Col span={24}>
          <FormInput
            label='Vergi / TC Kimlik No'
            icon={<PassportLineIcon size='1.5rem' />}
            placeholder='Vergi / TC Kimlik No'
            tooltip='Vergi Numarası veya TC Kimlik Numarası.'
            tooltipPosition='input'
            name="trIdNumber"
            onChange={setData}
          />
        </Col>
        <Col span={24}>
          <FormInput
            label='Banka Bilgileri'
            icon={<PassportLineIcon size='1.5rem' />}
            placeholder='Banka Bilgileri'
            textArea={true}
            name="bankInfo"
            onChange={setData}
          />
        </Col>
        <Col span={24}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox type="checkbox" name="taxExempt" onChange={setData}>Vergiden Muhaf Mı?</Checkbox>
            <Tooltip title='Faturasız satış yaptığınız müşterileriniz varsa bu kutucuğu seçerek KDVsiz işlem görmesini sağlarsınız. Satış ekranında KDV oranını değiştirebilirsiniz.'>
              <InformationIcon size='1.35rem' color='var(--icon)' />
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <ItemContainer>
            <Title> Müşteri Para Birimi </Title>
            <Select
              showSearch
              labelInValue
              defaultValue={{ value: 'try', label: 'Türk Lirası (₺)' }}
              style={{ width: '100%', height: '3.125rem'}}
              size='large'
              onChange={handleCustomerCurrencyChange}
              options={currencyList.map((currency) => ({
                value: currency.value,
                label: `${currency.label}`
              }))}
              filterOption={filterOption}
            />
          </ItemContainer>
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Açık Hesap Risk Limiti
              <Tooltip title='Müşterinin borcu bu limiti geçtiğinde satış ekranı uyarı verir. Limit vermek istemiyorsanız boş bırakabilirsiniz.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <AntInputNumber
            size='large'
            addonBefore={<AlarmWarningLineIcon size="1.5rem" color='rgba(var(--black-rgb), 0.6)' />}
            addonAfter={currentCurrency}
            placeholder='1.125.000'
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: string | undefined) => (value ? value.replace(/\D/g, '') : '')}
            name="openAccountRiskLimit"
            onChange={(value) => setData({ target: { name: 'openAccountRiskLimit', value } })}
          />

          </ItemContainer>
        </Col>

        <Col span={24}>
          <ItemContainer>
            <Title>
              Vadesi
              <Tooltip title='Vadeli çalıştığınız müşterilerde buraya girdiğiniz gün sayısına göre satışlarınızda fatura vadesi otomatik hesaplanır.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <AntInputNumber
              size='large'
              addonBefore={<Calendar2LineIcon size="1.5rem" color='rgba(var(--black-rgb), 0.6)' />}
              addonAfter={'Gün'}
              placeholder='30'
              min={1}
              max={30}
              name="due"
              onChange={(value) => setData({ target: { name: 'due', value } })}
            />
          </ItemContainer>
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Yüzde Sabit İskonto
              <Tooltip title='Müşterinize uyguladığınız belli bir iskonto varsa satış ekranında otomatik hesaplarız. İsterseniz satış yaparken iskontoyu değiştirebilirsiniz. Belli bir iskonto ile satış yapmıyorsanız boş bırakabilirsiniz.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <AntInputNumber
              size='large'
              addonBefore={<ScissorsCutLineIcon size="1.5rem" color='rgba(var(--black-rgb), 0.6)' />}
              addonAfter={'%'}
              placeholder='24'
              //   formatter={(value) => `%${value}`}
              //   parser={(value: string | undefined) => (value ? value.replace('%', '') : '')}
              min={0}
              max={100}
              name="fixedDiscount"
              onChange={(value) => setData({ target: { name: 'fixedDiscount', value } })}
            />
          </ItemContainer>
        </Col>

        <Col span={24}>
          <ItemContainer>
            <Title>
              Özel Fiyat Listesi
              <Tooltip title={<>Bu müşteriye bazı ürünlerinizi özel fiyattan satmak isterseniz bu özelliği kullanabilirsiniz. Özel fiyat listelerini "Ürünler" menüsünün altındaki <a href=""><b>Özel Fiyat Listeleri"</b></a> sayfasında hazırlayabilirsiniz.</>}   getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <PriceListContainer>
              <Switch
                onChange={handlePriceListIsEnabledChange}
                checkedChildren='Uygulansın'
                unCheckedChildren='Uygulanmasın'
                style={{ width: '100%', maxWidth: 120 }}
              />
              <Select
                showSearch
                placeholder='Özel fiyat listesi seçiniz'
                optionFilterProp='children'
                filterOption={filterOption}
                style={{ width: '100%' }}
                disabled={!priceListIsEnabled}
                options={[
                  {
                    value: 'ornek1',
                    label: 'Örnek liste 1'
                  },
                  {
                    value: 'ornek2',
                    label: 'Örnek liste 2'
                  },
                  {
                    value: 'ornek3',
                    label: 'Örnek liste 3'
                  }
                ]}
              />
            </PriceListContainer>
          </ItemContainer>
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Açılış Bakiyesi
              <Tooltip title='Yeni kaydettiğiniz müşterinin an itibariyle eski borcu varsa buraya girebilirsiniz. Müşteriniz alacaklı ise eksi bakiye girin.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <AntInputNumber
              size='large'
              addonBefore={<Wallet3LineIcon size="1.5rem" color='rgba(var(--black-rgb), 0.6)' />}
              addonAfter={currentCurrency}
              placeholder='1.125.000'
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              parser={(value: string | undefined) => (value ? value.replace(/\./g, '') : '')}
              name="openingBalance"
              onChange={(value) => setData({ target: { name: 'openingBalance', value } })}
            />
            <span style={{ color: '#d9363e', fontSize: '0.75rem' }}>Müşteri sizden alacaklı ise eksi (-) giriniz.</span>
          </ItemContainer>
        </Col>
      </Row>
    </Container>
  )
}

export default CustomersCurrentInformationTab
