import React from 'react'
import { ExclamationCircleFilled } from '@ant-design/icons';
import { AntdButton, Container } from './styles'
import { Modal } from 'antd';
import InboxArchiveLineIcon from "remixicon-react/InboxArchiveLineIcon"


const { confirm } = Modal;

const CustomersSelectedItemActions = () => {
    const showDeleteModal = () => {
        confirm({
          title: 'Müşterileri Sil',
          icon: <ExclamationCircleFilled />,
          content: 'Seçilen 3 müşteriyi silmek istediğine emin misin?',
          okText: 'Evet, sil',
          cancelText: 'Vazgeç',
          onOk() {
            return new Promise((resolve, reject) => {
              setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {},
        });
      };
  return (
    <Container>
        <AntdButton size='large' style={{background: "#4CAF50"}} type='primary' ><InboxArchiveLineIcon size={20} /> Arşive Ekle</AntdButton>
        <AntdButton onClick={showDeleteModal} size='large' style={{background: "#FF5252"}} type='primary' ><InboxArchiveLineIcon size={20} /> Sil</AntdButton>
        <AntdButton size='large' style={{background: "#FFC107"}} type='primary' ><InboxArchiveLineIcon size={20} /> Kategori / Etiket</AntdButton>
        <AntdButton size='large' style={{background: "#2196F3"}} type='primary' ><InboxArchiveLineIcon size={20} /> Toplu Güncelle</AntdButton>
    </Container>
  )
}

export default CustomersSelectedItemActions