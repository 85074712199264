import { Button } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`

export const ItemContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.4375rem;
  width: 100%;
  align-items: center;

  .ant-alert-message {
    color: #4b84a8;
    font-size: 1rem;
    font-weight: 700;
  }

  .ant-alert-description {
    color: #4b84a8;
    font-size: 0.875rem;
    font-weight: 400;
  }
`

export const AntAddBranchesButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
`
