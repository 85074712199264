import React from 'react'
import { Amount, Container, Detail, Title } from './styles'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'

const DashboardStockComponent = () => {
  return (
    <Container>
        <Title>Stok Değerim</Title>
        <Amount>1,201.792,65 ₺</Amount>
        <Detail>Detayları Görüntüle <ArrowRightSLineIcon size={16} /></Detail>
    </Container>
  )
}

export default DashboardStockComponent