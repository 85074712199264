import { Button } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.5rem;
  gap: 0.75rem;

  @media (max-width: 48rem) {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
`

export const NextButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-width: 8.75rem;
  min-height: 3.125rem;
  justify-content: center;
  font-size: 1.375rem;
  font-weight: 500;

  @media (max-width: 48rem) {
    width: 100%;
    min-height: 2.875rem;
  }
`

export const PreviousButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-width: 8.75rem;
  min-height: 3.125rem;
  justify-content: center;
  font-size: 1.375rem;
  font-weight: 500;
  @media (max-width: 48rem) {
    width: 100%;
    min-height: 2.875rem;
  }
`

export const SaveButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-width: 8.75rem;
  min-height: 3.125rem;
  justify-content: center;
  font-size: 1.375rem;
  font-weight: 500;
  border-color: #3f9a43;
  @media (max-width: 48rem) {
    width: 100%;
    min-height: 2.875rem;
  }

  &:hover {
    background: #3f9a43 !important;
    border-color: #3f9a43;
  }
`
