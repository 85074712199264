import { Button, Table, Tag } from 'antd'
import styled from 'styled-components'

export const AntdTable = styled(Table)`
  .ant-table-cell {
    background: transparent !important;
  }
  .ant-table-expanded-row {
    background: rgba(0, 0, 0, 0.06);

    .ant-table-cell {
      padding: 0px !important;

      @media (max-width: 768px) {
    font-size: 12px !important;
  }

    }
  }
 
`

export const AntdTag = styled(Tag)<{ color: string }>`
  background-color: ${(props) => (props.color === 'green' ? 'rgba(76, 175, 80, 0.10);' : 'rgba(198, 40, 40, 0.10)')};
  color: ${(props) => (props.color === 'green' ? '#4caf50' : '#C62828')};
  border-color: ${(props) => (props.color === 'green' ? 'rgba(76, 175, 80, 0.50)' : 'rgba(198, 40, 40, 0.50)')};
  border-radius: 6px;

  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 12px;
`

export const ExpandedButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const AntdButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 0px;
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  @media (max-width: 768px) {
   width: 100%;
   font-size: 14px;
  }
`
export const ExpandedContent = styled.div`
  margin-top: 8px;
`

export const ExpandeTable = styled.table`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const ExpandedHeader = styled.thead`
  display: flex;
  justify-content: space-around;
  background: var(--white);
  align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  padding: 3px 0;
  text-align: center;

  width: 100%;
  color: var(--text-primary);
  font-size: 14px;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

export const ExpandedRow = styled.tr`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`

export const ExpandedCell = styled.td`
  width: 100%;
  border: none !important;
  text-align: center;
  padding: 4px 0px;
  &:nth-child(1) {
    width: 40%;
  }
  &:nth-child(2) {
    width: 20%;
  }
  &:nth-child(3) {
    width: 20%;
  }
  &:nth-child(4) {
    width: 20%;
  }

`
