import styled from 'styled-components'

import StockBackground from '../../../../assets/icons/dashboard/stok-bg.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--stock-bg);
  position: relative;
  padding: 1.125rem 1.25rem;
  border-radius: 0.75rem;
  height: 6.875rem;
  background-image: url(${StockBackground});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: cover;
  box-shadow: 0rem 0.125rem 0.125rem 0rem rgba(62, 25, 103, 0.06);
  font-family: 'Lato', sans-serif;
  margin-top: 1.75rem;
`
export const Title = styled.div`
  color: var(--black);
  font-size: 0.875rem;
  font-weight: 600;
`

export const Amount = styled.div`
  color: var(--black);
  font-size: 1.375rem;
  font-weight: 700;
  margin-top: 1.625rem;
  width: max-content;
`
export const Detail = styled.div`
  color: rgba(var(--black-rgb), 0.5);
  font-size: 0.6875rem;
  font-weight: 600;
  margin-top: 1.875rem;
  display: flex;
  align-items: center;
  gap: 0.125rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: max-content;

  &:hover {
    color: var(--black);
    transform: translateX(0.3125rem);
  }
`
