import Layout from '../../components/layout'
import { useAppSelector } from '../../services'
import ProdcutsNavigation from './components/navigation'
import ProdcutsTable from './components/table'

const ProdcutsPage = () => {
  const products = useAppSelector((state) => state.products.products)
  return (
    <Layout>
      <ProdcutsNavigation totalProducts={products.length} />
      <ProdcutsTable products={products} />
    </Layout>
  )
}

export default ProdcutsPage
