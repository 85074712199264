import React, { useEffect, useState } from 'react'
import Layout from '../../../components/layout'
import { Container, PageTitle, TabIcon } from './styles'
import { Tabs } from 'antd'

import User2LineIcon from 'remixicon-react/User2LineIcon'
import ContactsBook2LineIcon from 'remixicon-react/ContactsBook2LineIcon'
import BankLineIcon from 'remixicon-react/BankLineIcon'
import SettingsLineIcon from 'remixicon-react/SettingsLineIcon'
import SuppliersIdInformationTab from './components/id'
import FormStepButtons from '../../../components/form/stepButtons'

import useWindowWidth from '../../../utils/useWindowWidth'
import SuppliersContactInformationTab from './components/contact'
import SuppliersCurrentInformationTab from './components/current'
import SuppliersOtherInformationTab from './components/other'
import { Suppliers } from '../../../interfaces/suppliers'
import { useAppDispatch } from '../../../services'
import { createSupplier, updateSupplier } from '../../../services/suppliersSlice/api'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {
  pageState: 'save' | 'edit'
}

const SuppliersCreatePage = () => {
  const dispatch = useAppDispatch()
  const width = useWindowWidth()
  const navigate = useNavigate()
  const location = useLocation() as { state: { data: Suppliers } };
  const [pageState, setPageState] = useState<Props['pageState']>('save')
  const [activeTab, setActiveTab] = useState<string>('1')
  const [supplierData, _setSupplierData] = useState<Suppliers>({
    _id: '',
    name: '',
    taxExempt: false,
    supplierCurrency: 'try'
  })

  const setData = (e: any) => {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      _setSupplierData({ ...supplierData, [name]: checked })
      return
    } else {
      _setSupplierData({ ...supplierData, [name]: value })
    }
  }

  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([])
  const [mails, setMails] = useState<string>('')

  useEffect(() => {
    setData({ target: { name: 'phone', value: Object.values(phoneNumbers) } })
  }, [phoneNumbers])

  useEffect(() => {
    setData({ target: { name: 'mail', value: Object.values(mails) } })
  }, [mails])

useEffect(() => {
    if(location?.state?.data) {
      const {
        _id,
        name,
        taxAdministiration,
        trIdNumber,
        taxExempt,
        bankInfo,
        supplierCurrency,
        maturity,
        openingBalance,
        authPerson,
        mail,
        address,
        phone,
        otherContactInfo,
        accountingCode,
        note
      } = location?.state?.data

      _setSupplierData({
        _id,
        name,
        taxAdministiration,
        trIdNumber,
        taxExempt,
        bankInfo,
        supplierCurrency,
        maturity,
        openingBalance,
        authPerson,
        mail,
        address,
        phone,
        otherContactInfo,
        accountingCode,
        note
      })
      setPageState('edit')
    }
  }
  , [location?.state?.data])

  const handleSaveSupplier = async () => {
    const { 
      name,
      taxAdministiration,
      trIdNumber,
      taxExempt,
      bankInfo,
      supplierCurrency,
      maturity,
      openingBalance,
      authPerson,
      mail,
      address,
      phone,
      otherContactInfo,
      accountingCode,
      note
    } = supplierData

    const body = {
      name,
      taxAdministiration,
      trIdNumber,
      taxExempt,
      bankInfo,
      supplierCurrency,
      maturity,
      openingBalance,
      authPerson,
      mail,
      address,
      phone,
      otherContactInfo,
      accountingCode,
      note
    } as Suppliers

    console.log(body)

    if(pageState === 'save') {
      await dispatch(createSupplier(body))
      navigate('/tedarikciler')
    }else if(pageState === 'edit') {
      await dispatch(updateSupplier({ supplierId: location.state.data._id, supplier: body }))
       navigate('/tedarikciler')
    }
  }
  const tabItems = [
    {
      key: '1',
      label: 'Kimlik Bilgileri',
      children: <SuppliersIdInformationTab data={supplierData} setData={setData} />,
      icon: (
        <TabIcon>
          <User2LineIcon size={width < 768 ? 16 : 20} />
        </TabIcon>
      )
    },
    {
      key: '2',
      label: 'Cari Bilgiler',
      children: <SuppliersCurrentInformationTab setData={setData} />,
      icon: (
        <TabIcon>
          <BankLineIcon size={width < 768 ? 16 : 20} />
        </TabIcon>
      )
    },
    {
      key: '3',
      label: 'İletişim Bilgileri',
      children: (
        <SuppliersContactInformationTab
          data={supplierData}
          setData={setData}
          phoneNumbers={phoneNumbers}
          setPhoneNumbers={setPhoneNumbers}
          mails={mails}
          setMails={setMails}
          pageState={pageState}
        />
      ),
      icon: (
        <TabIcon>
          <ContactsBook2LineIcon size={width < 768 ? 16 : 20} />
        </TabIcon>
      )
    },
    {
      key: '4',
      label: 'Diğer Bilgiler',
      children: <SuppliersOtherInformationTab setData={setData} />,
      icon: (
        <TabIcon>
          <SettingsLineIcon size={width < 768 ? 16 : 20} />
        </TabIcon>
      )
    },
  ]

  const handleTabChange = (key: string) => {
    setActiveTab(key)
  }

  return (
    <Layout>
      <Container>
        <PageTitle>{pageState === 'save' ? 'Yeni Tedarikçi Ekle' : 'Tedarikçiyi Düzenle'}</PageTitle>
        <Tabs
          defaultActiveKey='1'
          type='card'
          size='large'
          items={tabItems}
          tabBarGutter={0}
          activeKey={activeTab}
          onChange={handleTabChange}
        />
        <FormStepButtons
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          handleSave={handleSaveSupplier}
          totalStep={tabItems.length}
        />
      </Container>
    </Layout>
  )
}

export default SuppliersCreatePage
