import { InputNumber } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;

  .ant-select-selector .ant-select-selection-search-input {
    height: 100% !important;
  }

  .ant-tooltip-inner {
    font-weight: 400;

    b {
      font-weight: 700;
    }
  }

  @media (max-width: 48rem) {
    grid-template-columns: 1fr;
  }
`

export const ItemContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.4375rem;
  width: 100%;
`

export const AntInputNumber = styled(InputNumber)`
  width: 100%;
  height: 3.125rem;

  .ant-input-number-wrapper {
    height: 100%;

    .ant-input-number-input-wrap {
      height: 100%;
      input {
        height: 100%;
      }
    }
  }
`
export const PriceListContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  @media (max-width: 768px) {
    flex-direction: column;

    button {
      max-width: 100% !important;
    }
  }
`
