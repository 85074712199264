import React from 'react'
import { Buttons, Container, Details, AntdButton } from './styles'

import AddIcon from 'remixicon-react/AddBoxLineIcon'
import ExcelIcon from 'remixicon-react/FileExcel2LineIcon'

import UserIcon from 'remixicon-react/UserLineIcon'

import useWindowWidth from '../../../../utils/useWindowWidth'
import { Link } from 'react-router-dom'

const ProdcutsNavigation = ({totalProducts}: any) => {
  const width = useWindowWidth()
  return (
    <Container>
      <Buttons>
        <Link to='/urunler/yeni'>
          <AntdButton type='primary' style={{ background: '#28bf94' }}>
            <AddIcon size={20} /> {width < 768 ? 'Ürün Ekle' : 'Yeni Ürün Ekle'}
          </AntdButton>
        </Link>
        <AntdButton type='primary' style={{ background: '#FF876C' }}>
          <ExcelIcon size={20} /> {width < 768 ? 'Ürün Aktar' : 'Excelden Ürün Aktar'}
        </AntdButton>
      </Buttons>
      <Details>
        <UserIcon size={20} /> <b>{totalProducts}</b> {width > 825 && 'Toplam Ürün'}
      </Details>
    </Container>
  )
}

export default ProdcutsNavigation
