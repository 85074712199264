import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpRequest, RequestType } from '../../plugins/httpRequest'
import { Subscription } from '../../interfaces/subscription'

export const getSubscriptionInfo = createAsyncThunk('getSubscriptionInfo', async () => {
  const response = await HttpRequest<null, Subscription>({
    url: 'subscriptions',
    method: RequestType.GET
  })
  return response
})