import React, { useEffect } from 'react'
import Layout from '../../../components/layout'
import { useParams } from 'react-router-dom'
import { Container, StickyLeft, Right } from './styles'
import ProductStats from './components/Right/Stats'
import ProductsTransactions from './components/Right/Transactions'
import ProductDetailTables from './components/Right/Tables'
import { useAppDispatch, useAppSelector } from '../../../services'
import DetailLeft from '../../../components/detail/DetailLeft'

import HashtagIcon from "remixicon-react/HashtagIcon";
import ListCheck2Icon from "remixicon-react/ListCheck2Icon";
import BracketsFillIcon from "remixicon-react/BracketsFillIcon";
import ArchiveDrawerFillIcon from "remixicon-react/ArchiveDrawerFillIcon";
import PercentLineIcon from "remixicon-react/PercentLineIcon";
import Skeleton from 'react-loading-skeleton'
import DetailFooter from '../../../components/detail/DetailFooter'

import DetailTitle from '../../../components/detail/DetailTitle'
import { getProductById } from '../../../services/productsSlice'

interface ProductDataItem {
  icon: JSX.Element;
  label: string;
  content: JSX.Element | null;
}

const ProductsDetailPage = () => {
  const productId = useParams<{ id: string }>().id || ''
  const dispatch = useAppDispatch()
  const {_id, name, productCode, category, brand, shelfLocation, salesVatRate} = useAppSelector((state) => state.products.product)

  useEffect(() => {
    dispatch(getProductById(productId))
  }
  , [dispatch, productId])

  console.log('productCode', productCode)


  const productData: ProductDataItem[] = [];
  const loading = useAppSelector((state) => state.products.loading);

  const pushInfo = (icon: JSX.Element, label: string, content: JSX.Element | null) => {
    if (content !== undefined && content !== null) {
      productData.push({ icon, label, content });
    }else if(loading){
      productData.push({ icon, label, content: <Skeleton width={200} /> });
    }
  };
  
  pushInfo(<HashtagIcon size={18} />, "Ürün Kodu", productCode ? <span>{productCode}</span> : null);
  pushInfo(<ListCheck2Icon size={18} />, "Kategori", category ? <span>{category}</span> : null);
  pushInfo(<BracketsFillIcon size={18} />, "Marka", brand ? <span>{brand}</span> : null);
  pushInfo(<ArchiveDrawerFillIcon size={18} />, "Raf", shelfLocation ? <span>{shelfLocation}</span> : null);
  pushInfo(<PercentLineIcon size={18} />, "KDV Oranı", salesVatRate ? <span>{salesVatRate}</span> : null);

  return (
    <Layout>
      <Container>
        <StickyLeft>
          <DetailTitle name={name} id={_id} />
          <DetailLeft data={productData} />
          <DetailFooter />
        </StickyLeft>
        <Right>
          <ProductStats />
          <ProductsTransactions />
          <ProductDetailTables />
        </Right>
      </Container>
    </Layout>
  )
}

export default ProductsDetailPage
