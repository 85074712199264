import styled from 'styled-components'
import { Button, Input } from 'antd'
import InformationLineIcon from 'remixicon-react/InformationLineIcon'
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon'
import TextArea from 'antd/es/input/TextArea'

export const Container = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.4375rem;
  width: 100%;
`

export const Title = styled.div`
  color: var(--text-second);
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 1rem;
  font-weight: 500;

  @media (max-width: 48rem) {
    font-size: 0.875rem;
  }
`

export const AntInput = styled(Input)`
  width: 100%;
  height: 3.125rem;
  padding: 0 0.875rem;
  font-size: 1rem;

  input {
    padding-left: 0.625rem !important;
  }

  ::placeholder {
    color: rgba(var(--black-rgb), 0.25);
  }
  svg {
    fill: var(--icon);
    display: block !important;
  }

  @media (max-width: 48rem) {
    width: 100%;
    height: 2.625rem;
    font-size: 0.875rem;
    padding: 0 0.875rem;
    svg {
      display: none;
    }
  }
`

export const InformationIcon = styled(InformationLineIcon)`
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    fill: var(--primary);
  }
`

export const AddLineButton = styled(Button)`
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  background: var(--primary);
  width: 2.125rem !important;
  height: 2.125rem !important;

  svg {
    fill: #fff;
  }
`

export const AntTextArea = styled(TextArea)`
  font-size: 1rem;

  input {
    padding-left: 0.625rem !important;
  }

  ::placeholder {
    color: rgba(var(--black-rgb), 0.25);
  }
  svg {
    fill: var(--icon);
  }

  .ant-input-data-count {
    bottom: -1.5rem;
    font-size: 0.8rem;
  }
`

export const InputRemoveIcon = styled(CloseCircleLineIcon)`
  height: 3.125rem;
  position: absolute;
  padding: 0 1.5rem;
  right: 0;
  cursor: pointer;
  opacity: 0.5;
  transition: 0.2s;

  &:hover {
    transform: scale(1.1);
    opacity: 1;
  }
`
