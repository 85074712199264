import React from 'react';
import { Container } from '../../../styles';
import CustomerStatsComponent from './components/supplier-stats/SupplierStats';

import OpenBalanceIcon from '../../../../../../assets/icons/customers/acık-bakiyesi.svg';
import CheckBalanceIcon from '../../../../../../assets/icons/customers/cek-bakiyesi.svg';
import NoteBalanceIcon from '../../../../../../assets/icons/customers/senet-bakiyesi.svg';
import TurnoverIcon from '../../../../../../assets/icons/customers/cirosu.svg';

const statsData = [
  { title: 'Açık Bakiyesi', value: '12.345,21 ₺', icon: OpenBalanceIcon, color: '40, 191, 148', status: 'creditor' },
  { title: 'Çek Bakiyesi', value: '2.945,21 ₺', icon: CheckBalanceIcon, color: '86, 90, 233'},
  { title: 'Senet Bakiyesi', value: '245,21 ₺', icon: NoteBalanceIcon, color: '255, 61, 0'},
  { title: 'Cirosu', value: '62.345,11 ₺', icon: TurnoverIcon, color: '35, 183, 229'},
];

const CustomerStats = () => {
  return (
    <Container>
      {statsData.map((stats, index) => (
        <CustomerStatsComponent
          key={index}
          title={stats.title}
          value={stats.value}
          icon={stats.icon}
          color={stats.color}
          status={stats?.status as 'debtor' | 'creditor' | undefined}
        />
      ))}
    </Container>
  );
}

export default CustomerStats;
