import { Avatar } from "antd";
import styled from "styled-components";

export const DropdownProfileName = styled.div`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
`;

export const DropdownProfileTitle = styled.div`
  color: var(--icon);
  font-size: 0.75rem;
  line-height: 1rem;
`;
export const MenuItem = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  color: var(--text-second);
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: rgba(var(--black-rgb), 0.1);
  }

  span{
    position: absolute;
    right: 0.75rem;
    background-color: #ECF8F4;
    color: #28BF94;
    border-radius: 0.25rem;
    padding: 0.25rem 0.625rem;
    font-size: 0.75rem;
    font-weight: 700;
  }
`;

export const DropdownProfile = styled(Avatar)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 999px;
  display: flex;
  cursor: pointer;
`;