import React, { useEffect } from 'react'
import { useAppDispatch } from '../../../services'
import { Container, Content } from './styles'
import ChangeCompanyFooter from './components/Footer'
import ComapanyContent from './components/Content'
import { getSubscriptionInfo } from '../../../services/subscriptionSlice/api'

const ChangeCompanyPage = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getSubscriptionInfo())
  }, [dispatch])
  return (
    <Container>
      <Content>
        <ComapanyContent />
        <ChangeCompanyFooter />
      </Content>
    </Container>
  )
}

export default ChangeCompanyPage
