import React from "react";

function HalkBankIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.1875rem"
      height="1.375rem"
      fill="none"
      viewBox="0 0 19 22"
    >
      <path
        fill="#005696"
        d="M3.205 1.89v18.22h-.637V1.89h.637zM1.952 3.78V18.2h-.658V3.78h.658zM.637 5.668V16.35H0V5.669h.637zM13.969 22V11.318H4.48V22h-.637V0h.636v10.682h9.491V0h.637v22h-.637zm1.295-1.869V1.911h.636v18.22h-.636zm1.252-1.91V3.8h.637v14.42h-.637zm1.295-1.869V5.67h.636v10.682h-.636zm-5.116-6.326H5.075V1.87h.637V9.39h6.983V1.871h.658v8.155h-.658zm-.883-1.274H6.37V3.761h.637v4.354h4.437V3.761h.636v4.991h-.267zM10.396 7.52H7.602V5.652h.637v1.231h1.91V5.652h.637V7.52h-.39zm-4.684 4.397H13.332v8.154h-.637v-7.498H5.712v7.518h-.637v-8.155s.657 0 .637-.02zm.903 1.273H12.06v4.992h-.637v-4.334H6.985v4.354h-.637v-4.991s.267 0 .267-.02zm1.398 1.232h2.793v1.869h-.637v-1.232H8.26v1.232h-.637v-1.869c0 .021.39.021.39 0z"
      ></path>
    </svg>
  );
}

export default HalkBankIcon;
