import styled from 'styled-components'

export const Modal = styled.div`
  border-radius: 0.75rem;
  background-color: var(--white);
  box-shadow: 0rem 0.125rem 0.125rem 0rem rgba(0, 0, 0, 0.1);
  min-width: 32.1875rem;
  min-height: 26.875rem;
  z-index: 999;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.25rem;
`

export const ModalMask = styled.div`
  background: rgba(var(--primary-rgb), 0.15);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  overflow: hidden;
`
export const FilterTitle = styled.h2`
  font-family: 'Lato', sans-serif;
  font-size: 1.125rem;
  color: var(--icon);
  font-weight: 500;
  margin: 1.5rem 0;
`
export const FilterList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.625rem;
`

export const FilterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  background-color: rgba(var(--black-rgb), 0.06);
  border: 0.0625rem solid rgba(var(--black-rgb), 0.07);
  padding: 0.5rem 0.4375rem;
  border-radius: 0.375rem;
  font-size: 0.8125rem;
  font-weight: 600;
  color: var(--text-second);
  font-family: 'Lato', sans-serif;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(var(--black-rgb), 0.15);
    color: var(--text-primary);
  }

  svg {
    color: var(--text-second);
    transition: all 0.2s ease-in-out;
    &:hover {
      scale: 1.2;
    }
  }
`

export const AddFilterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  border-radius: 0.375rem;
  border: 0.0625rem dashed rgba(var(--black-rgb), 0.15);
  font-size: 0.8125rem;
  color: var(--text-second);
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(var(--black-rgb), 0.15);
    color: var(--text-primary);
    border: 0.0625rem solid rgba(var(--black-rgb), 0.15);
  }
`

export const ItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

export const LastSearchItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.375rem;
`

export const LastSearchItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 0.0625rem solid rgba(var(--black-rgb), 0.07);
  border-radius: 0.375rem;
  padding: 0.375rem 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  justify-content: space-between;
  font-family: 'Lato', sans-serif;
  font-size: 0.875rem;

  &:hover {
    color: var(--text-primary);
  }

  b {
    font-weight: 700;
    color: var(--primary);
  }
  span {
    font-weight: 700;
  }

  &:hover {
    background-color: rgba(var(--black-rgb), 0.15);
  }

  svg {
    transition: all 0.2s ease-in-out;
    &:hover {
      scale: 1.2;
    }
  }
`

export const SearchItemTitle = styled.div``
export const SearchItemIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
export const SearchItemIcon = styled.div``

export const SearchButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1.5rem;
`
export const SearchButton = styled.div`
  box-shadow: 0rem 0.125rem 0rem 0rem rgba(var(--primary-rgb), 0.04);
  border-radius: 0.25rem;
  border: 0.0625rem solid rgba(var(--primary-rgb), 0.15);

  background: rgba(var(--primary-rgb), 0.15);
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: var(--primary);
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: rgba(var(--primary-rgb), 0.25);
  }
`
