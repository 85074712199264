import { Container, ItemContainer } from './styles'
import FormInput from '../../../../../components/input'

import { Col, Row, Select } from 'antd'
import { Title } from '../../../../../components/input/styles'

import AlignJustifyIcon from 'remixicon-react/AlignJustifyIcon'

const ProductsIdInformationTab = ({ data, setData }: any) => {
  const salesUnit = [
    { value: 'adet', label: 'Adet' },
    { value: 'ay', label: 'Ay' },
    { value: 'bağ', label: 'Bağ' },
    { value: 'bidon', label: 'Bidon' },
    { value: 'boy', label: 'Boy' },
    { value: 'cc', label: 'Cc' },
    { value: 'cilt', label: 'Cilt' },
    { value: 'cm2', label: 'Cm2' },
    { value: 'çift', label: 'Çift' },
    { value: 'çuval', label: 'Çuval' },
    { value: 'dakika', label: 'Dakika' },
    { value: 'dekar', label: 'Dekar' },
    { value: 'desi', label: 'Desi' },
    { value: 'deste', label: 'Deste' },
    { value: 'dilim', label: 'Dilim' },
    { value: 'dönem', label: 'Dönem' },
    { value: 'düzine', label: 'Düzine' },
    { value: 'galon', label: 'Galon' },
    { value: 'gram', label: 'Gram' },
    { value: 'gross', label: 'Gross' },
    { value: 'grup', label: 'Grup' },
    { value: 'gün', label: 'Gün' },
    { value: 'hektar', label: 'Hektar' },
    { value: 'ibc', label: 'Ibc' },
    { value: 'karat', label: 'Karat' },
    { value: 'kasa', label: 'Kasa' },
    { value: 'kavanoz', label: 'Kavanoz' },
    { value: 'kilogram', label: 'Kilogram' },
    { value: 'kilometre', label: 'Kilometre' },
    { value: 'kişi', label: 'Kişi' },
    { value: 'koçan', label: 'Koçan' },
    { value: 'koli', label: 'Koli' },
    { value: 'kontör', label: 'Kontör' },
    { value: 'kova', label: 'Kova' },
    { value: 'kutu', label: 'Kutu' },
    { value: 'kwatt', label: 'Kwatt' },
    { value: 'kwh', label: 'Kwh' },
    { value: 'libre', label: 'Libre' },
    { value: 'litre', label: 'Litre' },
    { value: 'makara', label: 'Makara' },
    { value: 'metre', label: 'Metre' },
    { value: 'metre2', label: 'Metre2' },
    { value: 'metre3', label: 'Metre3' },
    { value: 'metretül', label: 'Metretül' },
    { value: 'mililitre', label: 'Mililitre' },
    { value: 'mwh', label: 'Mwh' },
    { value: 'paket', label: 'Paket' },
    { value: 'palet', label: 'Palet' },
    { value: 'porsiyon', label: 'Porsiyon' },
    { value: 'puan', label: 'Puan' },
    { value: 'rulo', label: 'Rulo' },
    { value: 'saat', label: 'Saat' },
    { value: 'saniye', label: 'Saniye' },
    { value: 'sayfa', label: 'Sayfa' },
    { value: 'seans', label: 'Seans' },
    { value: 'servis', label: 'Servis' },
    { value: 'set', label: 'Set' },
    { value: 'sqft2', label: 'Sqft2' },
    { value: 'sütun/cm', label: 'Sütun/Cm' },
    { value: 'şişe', label: 'Şişe' },
    { value: 'tabaka', label: 'Tabaka' },
    { value: 'takım', label: 'Takım' },
    { value: 'teneke', label: 'Teneke' },
    { value: 'tepsi', label: 'Tepsi' },
    { value: 'test', label: 'Test' },
    { value: 'tır', label: 'Tır' },
    { value: 'ton', label: 'Ton' },
    { value: 'top', label: 'Top' },
    { value: 'torba', label: 'Torba' },
    { value: 'ünite', label: 'Ünite' },
    { value: 'varil', label: 'Varil' },
    { value: 'viyol', label: 'Viyol' },
    { value: 'yard', label: 'Yard' },
    { value: 'yıl', label: 'Yıl' }
  ]

  return (
    <Container>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <FormInput
            label='Ürün Adı'
            icon={<AlignJustifyIcon size='1.5rem' />}
            placeholder='Ürün adını girin.'
            name='name'
            value={data.name}
            onChange={setData}
          />
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>Ürün Tipi</Title>
            <Select
              labelInValue
              defaultValue={{ value: 'stoklu', label: 'Stoklu ürün' }}
              style={{ width: '100%', height: '3.125rem' }}
              size='large'
              value={data.productType}
              onChange={(value) => setData({ target: { name: 'productType', value: value.value } })}
              options={[
                { value: 'stoklu', label: 'Stoklu ürün' },
                { value: 'stoksuz', label: 'Stoksuz ürün (Hizmet / Danışmanlık)' }
              ]}
            />
          </ItemContainer>
        </Col>
      </Row>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <Col span={24}>
            <ItemContainer>
              <Title>Satış Birimi</Title>
              <Select
                showSearch
                labelInValue
                defaultValue={{ value: 'adet', label: 'Adet' }}
                style={{ width: '100%', height: '3.125rem' }}
                size='large'
                value={data.salesUnit}
                onChange={(value) => setData({ target: { name: 'salesUnit', value: value.value } })}
                options={salesUnit}
              />
            </ItemContainer>
          </Col>
        </Col>
      </Row>
    </Container>
  )
}

export default ProductsIdInformationTab
