import styled from 'styled-components'

export const Container = styled.div`
  padding: 0.125rem 1.5rem;

  @media (max-width: 48rem) {
    padding: 0rem;
  }

  .ant-tabs .ant-tabs-tab-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-tabs-nav {
    margin-bottom: 0rem !important;
  }

  .ant-tabs-nav-list {
    display: grid !important;
    grid-template-columns: auto auto auto auto auto;
    width: 100%;
    @media (max-width: 48rem) {
      grid-template-columns: auto;
    }
  }

  .ant-tabs-content-holder {
    background: var(--white) !important;
    padding: 2rem;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    border-bottom: 0.0625rem solid rgba(var(--black-rgb), 0.07) ;
    border-left: 0.0625rem solid rgba(var(--black-rgb), 0.07) ;
    border-right: 0.0625rem solid rgba(var(--black-rgb), 0.07) ;
  }

  @media (max-width: 48rem) {
    .ant-tabs-content-holder {
    padding: 1rem;
  }
  }

  .ant-tabs-tab {
    border-right: 0.0313rem solid rgba(var(--black-rgb), 0.07) !important;
    border-top: 0.0625rem solid rgba(var(--black-rgb), 0.07) !important;
    border-left: 0.0313rem solid rgba(var(--black-rgb), 0.07) !important;
    border-radius: 0rem !important;
    p {
      color: var(--primary);
    }
    span {
      color: rgba(var(--black-rgb), 0.45);
      font-family: 'Roboto', sans-serif;
      font-size: 1rem;

      @media (max-width: 48rem) {
        font-size: 0.75rem;
      }
    }

    background: rgba(var(--black-rgb), 0.02) !important;

    &:first-child {
      border-top-left-radius: 0.75rem !important;
      border-left: 0.0625rem solid rgba(var(--black-rgb), 0.07) !important;
    }

    &:nth-last-child(2) {
      border-top-right-radius: 0.75rem !important;
      border-right: 0.0625rem solid rgba(var(--black-rgb), 0.07) !important;
    }
  }

  @media (max-width: 48rem) {
    .ant-tabs-tab {
      &:first-child {
      border-top-left-radius: 0.75rem !important;
      border-top-right-radius: 0.75rem !important;

    }

    &:nth-last-child(2) {
      border-top-right-radius: 0rem !important;
    }
  }
  }


  .ant-tabs-tab-active {
    background: var(--white) !important;
    p {
      background: var(--primary) !important;
      color: #fff !important;
    }
    span {
      color: var(--text-second) !important;
      user-select: none;
    }
  }
`

export const PageTitle = styled.h1`
  color: var(--text-second);
  font-family: 'Roboto', sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 1.625rem;

  @media (max-width: 48rem) {
    font-size: 1.25rem;
  }
`

export const TabIcon = styled.p`
  width: 2.4375rem;
  height: 2.4375rem;
  background: rgba(var(--primary-rgb), 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  @media (max-width: 48rem) {
    width: 1.875rem;
    height: 1.875rem;
  }
`
