import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpRequest, RequestType } from '../../plugins/httpRequest'
import { Customers } from '../../interfaces/customers'
import { PaginationReponse } from '../../interfaces/general'

export const getCustomers = createAsyncThunk(
  'getCustomers',
  async ({ page, pageSize = 10 }: { page: number; pageSize?: number }) => {
    const response = await HttpRequest<null, PaginationReponse<Customers>>({
      url: 'customers',
      method: RequestType.GET,
      params: {
        page: page,
        pageSize: pageSize
      }
    })
    return response
  }
)

export const getCustomerById = createAsyncThunk('getCustomerById', async function (customerId: string) {
  const response = await HttpRequest({
    url: `customers/${customerId}`,
    method: RequestType.GET
  })
  return response
})

export const createCustomer = createAsyncThunk('createCustomer', async function (customer: Customers) {
  const response = await HttpRequest({
    url: 'customers',
    method: RequestType.POST,
    body: customer
  })
  return response
})

export const updateCustomer = createAsyncThunk('updateCustomer', async function ({ customerId, customer }: { customerId: string; customer: Customers }) {
  const response = await HttpRequest({
    url: `customers/${customerId}`,
    method: RequestType.PATCH,
    body: customer
  })
  return response
})

export const deleteCustomer = createAsyncThunk('deleteCustomer', async function (customerId: string) {
  const response = await HttpRequest({
    url: `customers/${customerId}`,
    method: RequestType.DELETE
  })
  return response
})
