import React from 'react'
import { Buttons, Container, AntdButton } from './styles'

import AddIcon from 'remixicon-react/AddBoxLineIcon'

import useWindowWidth from '../../../utils/useWindowWidth'

const ProductVariantsNavigation = ({setIsModalOpen, setModalType, setVariantName}: any) => {
  const width = useWindowWidth()
  return (
    <Container>
      <Buttons>
          <AntdButton onClick={() => {
            setVariantName('')
            setModalType('create')
            setIsModalOpen(true)
          }} type='primary' style={{ background: '#28bf94' }}>
            <AddIcon size={20} /> {width < 768 ? 'Varyant Ekle' : 'Yeni Varyant Ekle'}
          </AntdButton>
      </Buttons>
    </Container>
  )
}

export default ProductVariantsNavigation
