import React from 'react'
import {
  Amount,
  Bottom,
  Container,
  Date,
  Icon,
  Item,
  ItemBottom,
  Left,
  Right,
  Texts,
  TitleBottom,
  Top,
  TopRight
} from './styles'

import HomeIcon from 'remixicon-react/Home3LineIcon'
import CarIcon from 'remixicon-react/CarLineIcon'
import FlaslightIcon from 'remixicon-react/FlashlightLineIcon'
import MoneyIcon from 'remixicon-react/Wallet2LineIcon'
import PencilIcon from 'remixicon-react/EditCircleLineIcon'

import dayjs from 'dayjs'
import IsBankasiIcon from '../../../assets/icons/dashboard/banka/isbankasi'
import EnParaIcon from '../../../assets/icons/dashboard/banka/enpara'
import VakifBankIcon from '../../../assets/icons/dashboard/banka/vakifbank'
import GarantiBankIcon from '../../../assets/icons/dashboard/banka/garantibank'
import HalkBankIcon from '../../../assets/icons/dashboard/banka/halkbank'

const iconMap: any = {
  // Yaklaşan Masraflar
  home: <HomeIcon size="1.5rem" />,
  car: <CarIcon size="1.5rem" />,
  bolt: <FlaslightIcon size="1.5rem" />,

  // Yaklaşan Kredi Ödemeleri
  isbakasi: <IsBankasiIcon />,
  enpara: <EnParaIcon />,
  vakıfbank: <VakifBankIcon />,
  garantibank: <GarantiBankIcon />,
  halkbank: <HalkBankIcon />,

  // Son İşlemler
  money: <MoneyIcon size="1.5rem" />,
  pencil: <PencilIcon size="1.5rem" />
}

const DashboardList = ({ data, bottomName, activity }: any) => {
  const formatMoney = (money: number) => {
    return money.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }

  return (
    <Container>
      {data.map((item: any, index: number) => {
        const nextItem = data[index + 1]
        const nextcolor = nextItem ? nextItem.color : 'var(--white)'
        return bottomName ? (
          <ItemBottom key={index} color={item.color}>
            <Left>
              <Icon color={item.color} $nextcolor={nextcolor}>
                {iconMap[item.icon]}
              </Icon>
            </Left>
            <TopRight>
              <Top>
                <Date $activity={activity}>
                  {dayjs(item.date).format(activity ? 'DD.MM.YYYY - HH:mm' : 'DD.MM.YYYY')}
                </Date>
                {item.amount && (
                  <Amount color={item.color}>
                    {formatMoney(item.amount)} <span style={{ float: 'right' }}>₺</span>
                  </Amount>
                )}
              </Top>
              <Bottom>
                <TitleBottom
                  $activity={activity}
                  color={item.color}
                  dangerouslySetInnerHTML={{ __html: `${item.title}` }}
                />
              </Bottom>
            </TopRight>
          </ItemBottom>
        ) : (
          <Item key={index} color={item.color}>
            <Left>
              <Icon color={item.color} $nextcolor={nextcolor}>
                {iconMap[item.icon]}
              </Icon>
              <Texts>
                <Date $activity={activity}>{dayjs(item.date).format('DD.MM.YYYY')}</Date>
                <TitleBottom
                  $activity={activity}
                  color={item.color}
                  dangerouslySetInnerHTML={{ __html: `${item.title}` }}
                />
              </Texts>
            </Left>
            <Right>
              <Amount color={item.color}>
                {formatMoney(item.amount)} <span style={{ float: 'right' }}>₺</span>
              </Amount>
            </Right>
          </Item>
        )
      })}
    </Container>
  )
}

export default DashboardList
