import React from 'react'
import DashobardList from '../../../../components/dashboard/list'

import Data from '../../../../mocks/dashboard/yaklasan-masraflar.json'


const DashobardUpcomingExpenseComponent = () => {
  return (
    <DashobardList data={Data} />
  )
}

export default DashobardUpcomingExpenseComponent