import React from "react";

function IsBankasiIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0.625rem"
      height="1.375rem"
      fill="none"
      viewBox="0 0 10 22"
    >
      <path
        fill="#00559F"
        d="M3.32 1.203c0 .663.542 1.202 1.21 1.202.67 0 1.212-.539 1.212-1.202C5.742.539 5.2 0 4.53 0c-.668 0-1.21.539-1.21 1.203zm4.882 3.649V4.68h-.27l-.141.214c-.13.164-.246.223-.495.249-.289.032-.659-.123-.659-.123l-.546-.217v.548c.237.131.343.238.458.322.144.106.37.325.634.678.265.354.46.804.523.995.047.14.109.434.139.604l.037.228h.32V4.852zm-6.332 13.4c.132.03.796.258.796.258.489.204.896.35 1.35.431-.39.193-.66.593-.66 1.056 0 .498.311.886.75 1.04.174.085.195.146.245.3.042.132-.096.663-.096.663h.307s.2-.244.478-.627c.122-.167.31-.504.42-.653.15-.218.271-.425.271-.722a1.18 1.18 0 00-.524-.98c.073-.001.09-.005.09-.005.685-.04 1.321-.3 1.321-.3 2.18-.735 2.677-2.646 2.677-2.646a3.96 3.96 0 00.134-1.015c0-1.16-.62-2.206-1.427-2.926 0 0-.25-.239-.455-.389-.424-.328-1.783-1.047-1.783-1.047S3.481 9.458 2.906 9.102c-.574-.357-.846-.638-.846-.638-.44-.374-.724-.883-.724-1.529 0-1.003.912-1.704 1.976-1.882 0 0 .072-.008.176-.014v3.844l2.086 1.143V4.082c.017-.153-.055-.678.507-.828l.306-.08V2.88H2.676v.295l.292.076c.578.146.503.677.52.832v.397c-.086.006-.178.012-.26.027C1.362 4.769 0 6.234 0 8.004c0 1.319.63 2.144 1.636 2.894.522.39.877.557 1.507.9l.346.191v3.757c-.017.156.056.68-.507.829l-.306.082v.293h3.711v-.293l-.292-.077c-.578-.146-.504-.677-.52-.834v-2.601l.405.226s1.222.59 1.693 1.66c.473 1.07.164 2.603-1.718 3.276 0 0-2.24.692-4.271-1.198 0 0-.701-.73-1.047-1.71C.583 15.247.52 15 .52 15H.227v3.723h.249s.15-.217.279-.322a.718.718 0 01.091-.067c.164-.1.4-.227.98-.096l.044.013z"
      ></path>
    </svg>
  );
}

export default IsBankasiIcon;
