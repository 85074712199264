import React from 'react'
import { Container } from './styles'

import HeaderLeft from './Left'
import HeaderRight from './Right'

const Header = ({ menuState, setMenuState }: any) => {
  return (
    <Container>
      <HeaderLeft menuState={menuState} setMenuState={setMenuState} />
      <HeaderRight />
    </Container>
  )
}

export default Header
