import { Button, Tag } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  font-family: 'Roboto', sans-serif;
  justify-content: space-between;
  gap: 1.5rem;
  margin-top: 1.5rem;
  flex-wrap: wrap;

  @media (max-width: 75rem) {
    flex-direction: column;
    gap: 1rem;
  }
`

export const ProductVariant = styled.div`
  width: 100%;
  border: 0.0625rem solid rgba(var(--black-rgb), 0.07);
  border-radius: 0.75rem;
  box-sizing: border-box;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background: var(--primary);
  padding: 1.125rem 1.5rem;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  align-items: center;

  @media (max-width: 75rem) {
    flex-direction: column;
    gap: 1rem;
    padding: 0.75rem 0 0 0;
  }
`
export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: var(--white);
  padding: 1.125rem 1.5rem;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  gap: 0.3rem;
`
export const Title = styled.div`
  font-size: 1.375rem;
  font-weight: 700;
  color: var(--white);
  cursor: pointer;
`
export const AntdButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 1rem 0.75rem;

  @media (max-width: 75rem) {
    gap: 0.25rem;
    font-size: 0.75rem;
    width: 100%;
    justify-content: center;
    border-radius: 0;
  }
`

export const AntdTag = styled(Tag)`
  padding: 0.375rem 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
`

export const ItemContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.4375rem;
  width: 100%;
  align-items: center;
  margin-top: 1rem;

  .ant-alert {
    width: 100%;
  }

  .ant-alert-message {
    color: #4b84a8;
    font-size: 1rem;
    font-weight: 700;
  }

  .ant-alert-description {
    color: #4b84a8;
    font-size: 0.875rem;
    font-weight: 400;

    b {
      font-weight: 700;
      cursor: pointer;
    }
  }
`

export const VariantContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media (max-width: 75rem) {
    grid-template-columns: 1fr;
  }
`
