import Layout from '../../components/layout'
import { useAppSelector } from '../../services'
import CustomersNavigation from './components/navigation'
import CustomersTable from './components/table'

const SuppliersPage = () => {
  const suppliers = useAppSelector((state) => state.suppliers.suppliers)
  return (
    <Layout>
      <CustomersNavigation totalSuppliers={suppliers.length} />
      <CustomersTable suppliers={suppliers} />
    </Layout>
  )
}

export default SuppliersPage
