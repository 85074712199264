import React from 'react'
import { Amount, Container, Detail, Title } from './styles'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import dayjs from 'dayjs'
import 'dayjs/locale/tr'

const DashboardTurnoverComponent = () => {
  return (
    <Container>
        <Title>{dayjs().locale('tr').format('MMMM')} Cirom</Title>
        <Amount>201.792,65 ₺</Amount>
        <Detail>Detayları Görüntüle <ArrowRightSLineIcon size={16} /></Detail>
    </Container>
  )
}

export default DashboardTurnoverComponent