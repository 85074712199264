import React from "react";

function GarantiBankIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0.9375rem"
      height="1.375rem"
      fill="none"
      viewBox="0 0 15 22"
    >
      <path
        fill="url(#paint0_radial_167_51027)"
        fillRule="evenodd"
        d="M3.929.226c-.71.454-1.045 1.431-1.156 1.8 2.353 1.487 3.83 3.428 4.652 4.816.544.907.891 1.7 1.073 2.182.167-.722.153-1.345.153-1.36C8.581 3.485 7.231.567 5.155.057A1.77 1.77 0 004.719 0c-.282 0-.549.075-.79.227zm6.992 2.069C9.765 3.329 9.613 6.347 9.557 7.48l-.014.212c-.042.695.014 1.53.056 1.955 2.563-2.153 3.44-5.312 3.538-5.737-.14-1.785-1.212-1.941-1.254-1.955a1.228 1.228 0 00-.961.34zM.002 5.468c-.014.75.264 1.502.793 2.21.558.737 1.352 1.346 2.299 1.756 2.967 1.29 4.638.496 5.043.242-1.839-4.619-5.391-6.73-5.92-7.027C.808 3.244.013 4.249 0 5.469zm12.857.822c-.502 1.048-1.407 2.536-2.898 3.81.71.142 2.201.128 3.539-1.94.599-.978.835-1.856.668-2.621a2.13 2.13 0 00-.612-1.105c-.098.382-.32 1.048-.697 1.856zm-2.605 4.83c.752.694 2.312 2.423 3.19 5.766a3.85 3.85 0 00.683-1.346c.292-1.062 0-2.068-.878-2.975-1.2-1.221-2.284-1.445-2.933-1.445h-.062zm-7.8 1.771C.877 13.94.07 15.13.153 16.32c.07 1.077.878 2.111 2.173 2.791.516-.326 3.761-2.564 5.892-7.692a3.932 3.932 0 00-1.156-.155c-1.018 0-2.6.3-4.61 1.628zm7.229.1v.297c0 1.12-.014 4.109 1.045 5.185.264.27.57.397.933.397h.028c.042 0 1.03.142 1.295-1.417-.125-.51-1.142-4.32-3.19-5.992-.041.311-.111.864-.111 1.53zm-4.625 4.816c-1.003 1.12-1.838 1.77-2.13 1.983.139.468.737 2.21 2.089 2.21 1.574 0 2.535-2.224 2.549-2.253v-.014c.083-.17 1.88-4.179 1.03-7.678a19.269 19.269 0 01-3.538 5.751z"
        clipRule="evenodd"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_167_51027"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(9.57756 .08459 -.16433 18.60607 8.53 10.565)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7DC242"></stop>
          <stop offset="0.11" stopColor="#6BB643"></stop>
          <stop offset="0.322" stopColor="#3C9E46"></stop>
          <stop offset="0.588" stopColor="#007B44"></stop>
          <stop offset="0.722" stopColor="#006940"></stop>
          <stop offset="1"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default GarantiBankIcon;
