import { createSlice } from '@reduxjs/toolkit'
import { getCurrentCompany, getCurrentUser, login, register } from './api'
import { User } from '../../interfaces/user'
import { toast } from 'react-toastify'
import { Company } from '../../interfaces/company'
export { login, register, getCurrentUser, getCurrentCompany }

export interface AuthState {
  user: User
  company: Company
  token: string
  loading: boolean
  error: any
}

const initialState: AuthState = {
  user: {} as User,
  company: {} as Company,
  token: '',
  loading: true,
  error: ''
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
    setCompany: (state, action) => {
      state.company = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true
      state.error = ''
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.token = action.payload.accessToken
      state.loading = false
    })
    builder.addCase(login.rejected, (state) => {
      state.loading = false
      state.error = 'Login Error!'
      toast.error('Bir hata oluştu.')
    })

    builder.addCase(register.pending, (state) => {
      state.loading = true
      state.error = ''
    })
    builder.addCase(register.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(register.rejected, (state) => {
      state.loading = false
      state.error = 'Register Error!'
      toast.error('Bir hata oluştu.')
    })

    builder.addCase(getCurrentUser.pending, (state) => {
      state.loading = true
      state.error = ''
    })
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.user = action.payload
      state.loading = false
    })
    builder.addCase(getCurrentUser.rejected, (state) => {
      state.loading = false
      state.error = 'Login Error!'
    })

    builder.addCase(getCurrentCompany.pending, (state) => {
      state.loading = true
      state.error = ''
    })
    builder.addCase(getCurrentCompany.fulfilled, (state, action) => {
      state.company = action.payload
      state.loading = false
    })
    builder.addCase(getCurrentCompany.rejected, (state) => {
      state.loading = false
      state.error = 'Login Error!'
    })
  }
})

export const { setToken } = authSlice.actions

export default authSlice.reducer
