import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import ProductVariantsNavigation from './navigation'
import {
  AntdButton,
  AntdTag,
  Container,
  Content,
  Header,
  ItemContainer,
  ProductVariant,
  Title,
  VariantContainer
} from './styles'
import AddIcon from 'remixicon-react/AddLineIcon'
import { Alert, Col, Row } from 'antd'
import { AnimatePresence, motion } from 'framer-motion'
import AdvancedModal from '../../components/modal'
import FormInput from '../../components/input'
import HashtagIcon from 'remixicon-react/HashtagIcon'
import { useAppDispatch, useAppSelector } from '../../services'
import { createVariant, getVariants, updateVariant, updateVariantValue, deleteVariant } from '../../services/variantsSlice'

const ProductVariantsPage = () => {
  const dispatch = useAppDispatch()
  const variants = useAppSelector((state) => state.variants.variants)

  const [modalType, setModalType] = useState<string>('create')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isValueModalOpen, setIsValueModalOpen] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState<any>(null)
  const [selectedVariantValue, setSelectedVariantValue] = useState<any>(null)
  const [variantName, setVariantName] = useState<string>('')
  const [variantValueName, setVariantValueName] = useState<string>('')

  useEffect(() => {
    dispatch(getVariants())
  }, [dispatch])

  useEffect(() => {
    if (modalType === 'update' && selectedVariant) {
      setVariantName(selectedVariant.name)
      if (selectedVariantValue) {
        setVariantValueName(selectedVariantValue)
      } else {
        setVariantValueName('')
      }
    } else {
      setVariantName('')
      setVariantValueName('')
    }
  }, [selectedVariant, selectedVariantValue, modalType])

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleOpenValueModal = () => {
    setIsValueModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleCloseValueModal = () => {
    setIsValueModalOpen(false)
  }

  const handleSaveVariant = async () => {
    if (modalType === 'create') {
      const variant = { name: variantName }
      await dispatch(createVariant(variant))
      dispatch(getVariants())
      setIsModalOpen(false)
    } else {
      await dispatch(
        updateVariant({
          variantId: selectedVariant._id,
          variant: { name: variantName }
        })
      )
      dispatch(getVariants())
      setIsModalOpen(false)
    }
  }

  const handleSaveVariantValue = async () => {
    if (modalType === 'create') {
      await dispatch(
        updateVariantValue({
          variantId: selectedVariant._id,
          variantValue: [...selectedVariant.variantValues, variantValueName]
        })
      )
      dispatch(getVariants())
      setIsValueModalOpen(false)
    } else {
      const index = selectedVariant.variantValues.indexOf(selectedVariantValue);
      const newValues = [...selectedVariant.variantValues]; 
      newValues[index] = variantValueName;

      await dispatch(
        updateVariantValue({
          variantId: selectedVariant._id,
          variantValue: newValues
        })
      )
      dispatch(getVariants())
      setIsValueModalOpen(false)
    }
  }

  const handleDeleteVariant = async () => {
    await dispatch(deleteVariant(selectedVariant._id))
    dispatch(getVariants())
    setIsModalOpen(false)
  }

  const handleDeleteVariantValue = async () => {
    const index = selectedVariant.variantValues.indexOf(selectedVariantValue);
    const newValues = [...selectedVariant.variantValues]; 
    newValues.splice(index, 1);

    await dispatch(
      updateVariantValue({
        variantId: selectedVariant._id,
        variantValue: newValues
      })
    )
    dispatch(getVariants())
    setIsValueModalOpen(false)
  }

  return (
    <Layout>
      <ProductVariantsNavigation setVariantName={setVariantName} setModalType={setModalType} setIsModalOpen={setIsModalOpen} />

      {!variants ? (
        <ItemContainer>
          <Alert
            message='Bilgilendirme'
            description={
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    'Muhasebia üzerinde "renk", "ebat"... gibi dilediğiniz şekilde ürün varyantı tanımlayabilir ve ürünlerinizde bunları seçebilirsiniz. <br /> Yeni varyant tanımı yapmak için yukarıda bulunan " <b>Yeni Varyant Ekle</b> " düğmesini kullanabilirsiniz.'
                }}
              ></div>
            }
            type='info'
          />
        </ItemContainer>
      ) : (
        <VariantContainer>
          {variants.map((variant: any) => (
            <Container key={variant._id}>
              <ProductVariant>
                <Header>
                  <Title
                    onClick={() => {
                      setModalType('update')
                      setSelectedVariant(variant)
                      handleOpenModal()
                    }}
                  >
                    {variant.name}
                  </Title>
                  <AntdButton
                    onClick={() => {
                      setVariantValueName('')
                      setModalType('create')
                      setSelectedVariant(variant)
                      handleOpenValueModal()
                    }}
                    type='primary'
                    style={{ background: '#28bf94' }}
                  >
                    <AddIcon size={18} /> Varyant Değeri Ekle
                  </AntdButton>
                </Header>
                <Content>
                  {variant.variantValues.map((value: any) => (
                    <AntdTag
                      key={value}
                      onClick={() => {
                        setModalType('update')
                        setSelectedVariant(variant)
                        setSelectedVariantValue(value)
                        handleOpenValueModal()
                      }}
                      color='#000000a8'
                    >
                      {value}
                    </AntdTag>
                  ))}
                </Content>
              </ProductVariant>
            </Container>
          ))}
        </VariantContainer>
      )}

      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            key='modal-content'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999 }}
          >
            <AdvancedModal
              title={modalType === 'create' ? 'Yeni Varyant Ekle' : 'Varyant Güncelle'}
              description='Bu pencere altından yeni bir varyant ekleyebilirsiniz.'
              onOpen={handleOpenModal}
              onSave={handleSaveVariant}
              onDelete={modalType === 'update' ? handleDeleteVariant : undefined}
              onClose={handleCloseModal}
            >
              <Row>
                <Col span={24}>
                  <FormInput
                    label='Varyant Adı'
                    icon={<HashtagIcon size='1.5rem' />}
                    placeholder='Örneğin: "Renk, "Ebat", "Beden" vs...'
                    onChange={(e: any) => setVariantName(e.target.value)}
                    value={variantName}
                  />
                </Col>
              </Row>
            </AdvancedModal>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isValueModalOpen && (
          <motion.div
            key='modal-content'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999 }}
          >
            <AdvancedModal
              title={modalType === 'create' ? 'Varyant Değeri Ekle' : 'Varyant Değeri Güncelle'}
              description='Bu pencere altından yeni bir varyant ekleyebilirsiniz.'
              onOpen={handleOpenValueModal}
              onSave={handleSaveVariantValue}
              onDelete={modalType === 'update' ? handleDeleteVariantValue : undefined}
              onClose={handleCloseValueModal}
            >
              <Row>
                <Col span={24}>
                  <FormInput
                    label='Varyant Değeri Adı'
                    icon={<HashtagIcon size='1.5rem' />}
                    placeholder='Örneğin: "Kırmızı, "Mavi", "Sarı" vs...'
                    onChange={(e: any) => setVariantValueName(e.target.value)}
                    value={variantValueName}
                  />
                </Col>
              </Row>
            </AdvancedModal>
          </motion.div>
        )}
      </AnimatePresence>
    </Layout>
  )
}

export default ProductVariantsPage
