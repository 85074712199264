import React from 'react'
import { Container, Icon, SubTitle, Texts, Title } from './styles'
import { Link } from 'react-router-dom'

import RocketIcon from '../../../../../assets/images/company/rocket.png'

const ChangeCompanyFooter = () => {
  return (
    <Container>
    <Icon><img src={RocketIcon} /></Icon>
    <Texts>
        <Title>Daha fazla firma eklemeye mi ihtiyacınız var?</Title>
        <SubTitle>Daha fazla firma eklemek için hemen <Link to="#">FİRMA EKLEME HAKKI</Link> satın al.</SubTitle>
    </Texts>
</Container>
  )
}

export default ChangeCompanyFooter