import { Tabs } from 'antd'
import { TabsProps } from 'antd/lib'
import { Container } from './styles'

import InboxArchiveLineIcon from 'remixicon-react/InboxArchiveLineIcon'
import InboxUnarchiveLineIcon from 'remixicon-react/InboxUnarchiveLineIcon'
import CustomerPreviousSales from './components/previous-sales'
import useWindowWidth from '../../../../../../utils/useWindowWidth'


const ProductDetailTables = () => {
    const width = useWindowWidth()

    const items: TabsProps['items'] = [
        {
          key: '1',
          label: 'Önceki Satışlar',
          children: <CustomerPreviousSales />,
          icon: <InboxUnarchiveLineIcon  size={width < 768 ? 20 : 24} />
        },
        {
          key: '2',
          label: 'Önceki Alışlar',
          children: 'Content of Tab Pane 3',
          icon: <InboxArchiveLineIcon  size={width < 768 ? 20 : 24} />
        }
      ]
  return (
    <Container>
      <Tabs defaultActiveKey='1' items={items} />
    </Container>
  )
}

export default ProductDetailTables
