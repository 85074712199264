import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpRequest, RequestType } from '../../plugins/httpRequest'
import { Company } from '../../interfaces/company'

export const getCompanyById = createAsyncThunk('getSubscriptionInfo', async (companyId: any) => {
  const response = await HttpRequest<null, Company>({
    url: `companies/${companyId}`,
    method: RequestType.GET
  })
  return response
})