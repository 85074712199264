import React from 'react'
import DashobardList from '../../../../components/dashboard/list'

import Data from '../../../../mocks/dashboard/son-islemler.json'


const DashobardLatestActivityComponent = () => {
  return (
    <DashobardList data={Data} bottomName activity="true" />
  )
}

export default DashobardLatestActivityComponent