import React from "react";

function VakifBankIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.625rem"
      height="1.0625rem"
      fill="none"
      viewBox="0 0 26 17"
    >
      <path
        fill="#FBBA00"
        d="M25.96 4.525C26.293 2.635 24.55 0 19.805 0H8.01C4.779 0 2.752 2.216 2.39 4.447L0 17c2.1-.005 15.941 0 15.913 0h.899c4.956-.027 7.81-1.14 8.33-4.73.346-2.416-1.78-3.976-3.516-4.308 1.756-.32 3.89-.916 4.334-3.437zm-11.074.736c-1.202 1.52-5.573 6.798-6.457 7.8-1.974 2.242-6.045 1.907-6.072 1.85 0-.002 1.467-6.93 1.943-8.884 1.304-5.312 5.803-4.054 5.803-4.054s-1.198.173-2.045 1.25C7.2 4.317 6.226 9.64 5.676 12.129c2.578-3.008 6.113-6.89 6.715-7.615 2.57-3.104 6.592-2.676 6.592-2.676s-1.567.218-4.097 3.425V5.26z"
      ></path>
    </svg>
  );
}

export default VakifBankIcon;
