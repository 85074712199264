import React from 'react'
import { Container, BoxTitle, Content } from './styles'
import SubscriptionDetail from './components/SubscriptionDetail'
import CompanyTokenDetail from './components/CompanyTokenDetail'
import { Subscription } from '../../../../interfaces/subscription'

const SubscriptionInfo = ({subscription}:{subscription: Subscription}) => {
  return (
    <Container>
      <BoxTitle>
        <span>Abonelik Bilgileri</span>
        <div>
          <p>Detayları Gör</p> <svg xmlns='http://www.w3.org/2000/svg' width='4' height='6' fill='none' viewBox='0 0 4 6'><path fill='#565AE9' d='M2 3L0 1l1-1 3 3-3 3-1-1 2-2z'></path></svg>
        </div>
      </BoxTitle>

      <Content>
       <SubscriptionDetail subscription={subscription} />
        <CompanyTokenDetail subscription={subscription} />
      </Content>
    </Container>
  )
}

export default SubscriptionInfo
