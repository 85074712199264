import styled from 'styled-components'

export const Company = styled.div`
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.02);
  cursor: pointer;
  transition: all 0.35s ease-in-out;

  &:hover {
    transform: scale(1.02) translateY(-2px);
  }
`

export const CompanyDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 18px;
`
export const ProfileImage = styled.img`
  width: 96px;
  height: 96px;
  border-radius: 6px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06);
`
export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-around;
`
export const DetailTitle = styled.div`
  color: var(--text-second);

  font-size: 18px;

  font-weight: 700;
  margin-bottom: 6px;
`
export const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  font-size: 12px;

  font-weight: 500;

  span {
    max-width: 200px;
  }
  svg {
    fill: var(--primary);
  }

  @media (max-width: 850px) {
    span {
      max-width: none;
    }
  }
`

export const SubscriptionDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 10px 18px 18px 18px;
`
export const SubscriptionDetailItem = styled.div`
  color: var(--icon);
  font-size: 12px;

  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 6px;

  svg {
    width: 16px;
  }
`

export const Actions = styled.div`
  display: flex;
  flex: 1;
`

export const EditButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 0;
  cursor: pointer;
  justify-content: center;
  color: var(--primary);
  border-radius: 0px 0px 12px 12px;
  background: rgba(var(--primary-rgb), 0.2);
  font-size: 14px;

  font-weight: 700;
  transition: all 0.35s ease-in-out;

  &:hover {
    background: rgba(var(--primary-rgb), 0.3);
  }
`

export const BlankContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(var(--black-rgb), 0.15);
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 0.35s ease-in-out;
  min-height: 150px;

  &:hover {
    background: rgba(0, 0, 0, 0.02);
    color: rgba(var(--black-rgb), 0.3);
    border-color: rgba(0, 0, 0, 0.3);
  }
`
