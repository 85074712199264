import React, { useState } from 'react'
import { useAuth } from '../../../../../utils/useAuth';
import { setToken } from '../../../../../services/authSlice';
import { useAppDispatch, useAppSelector } from '../../../../../services';
import { Link, useNavigate } from "react-router-dom";

import { Avatar, Dropdown, Space, Flex, Divider } from "antd";
import UserIcon from "remixicon-react/UserSmileLineIcon";
import ArrowLeftRightFillIcon from "remixicon-react/ArrowLeftRightFillIcon";
import MailIcon from "remixicon-react/MailLineIcon";
import SettingsIcon from "remixicon-react/Settings3LineIcon";
import LogoutIcon from "remixicon-react/LogoutCircleRLineIcon";
import DefaultAvatar from "../../../../../assets/images/avatars/default-avatar.jpg";

import { DropdownProfile, DropdownProfileName, DropdownProfileTitle, MenuItem } from "./styles";

const HeaderProfileMenu = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { token } = useAuth() as any;

  const contentStyle: React.CSSProperties = {
    backgroundColor: "var(--white)",
    borderRadius: "0.5rem",
    boxShadow: "0rem 0.125rem 0.75rem 0rem rgba(0, 0, 0, 0.05)",
    minWidth: "10.875rem",
  };

  const menuItems = [
    { icon: <UserIcon size="1.375rem" style={{ minWidth: "1.5rem" }} color="#7C7C8D" />, title: "Profilim", link: "/profilim" },
    { icon: <ArrowLeftRightFillIcon size="1.375rem" style={{ minWidth: "1.5rem" }} color="#7C7C8D" />, title: "Şirket Değiştir", link: "/sirket-sec" },
    { icon: <MailIcon size="1.375rem" style={{ minWidth: "1.5rem" }} color="#7C7C8D" />, title: "Gelen", count: 12, link: "/mail" },
    { icon: <SettingsIcon size="1.375rem" style={{ minWidth: "1.5rem" }} color="#7C7C8D" />, title: "Ayarlar", link: "/ayarlar"  },
    { icon: <LogoutIcon size="1.375rem" style={{ minWidth: "1.5rem" }} color="#7C7C8D" />, title: "Çıkış Yap", link: "/kullanici-girisi"  },
  ];

  const handleLogOut = () => {
    setAnchorElUser(null);
    localStorage.removeItem("token");
    dispatch(setToken(""));
    navigate("/kullanici-giris");
};

  return (
    <Dropdown
      dropdownRender={() => (
        <div style={contentStyle}>
          <Divider style={{ margin: 0 }} />
          <Space style={{ padding: "0.5rem 1rem", cursor: "pointer" }}>
            <DropdownProfile size={40} src={DefaultAvatar} style={{ border: "0.125rem solid var(--primary)" }} />
            <div>
              <DropdownProfileName>{user.fullName}</DropdownProfileName>
              <DropdownProfileTitle>Firma Sahibi</DropdownProfileTitle>
            </div>
          </Space>

          <Divider style={{ margin: 0, marginBottom: "0.375rem" }} />
          <Flex vertical>
            {menuItems.map((item, index) => (
              <Link to={item.link} key={index}>
              <Flex
                key={index}
                style={{ height: "2.5rem", marginBottom: index === menuItems.length - 1 ? "0.75rem" : "0" }}
                onClick={item.title === "Çıkış Yap" ? handleLogOut : () => {}}
              >
                <MenuItem>
                  {item.icon}
                  <p>{item.title}</p>
                  {item.count && <span>{item.count}</span>}
                </MenuItem>
              </Flex>
                </Link>
            ))}
          </Flex>
        </div>
      )}
    >
      <Avatar
        size={40}
        src={DefaultAvatar}
        style={{
          border: "0.125rem solid var(--primary)",
          marginLeft: "1.5rem",
          cursor: "pointer",
        }}
      />
    </Dropdown>
  );
};

export default HeaderProfileMenu;
