import React from 'react'
import {
  Container,
  Content,
  Day,
  DayName,
  Detail,
  DetailCount,
  DetailItem,
  DetailTitle,
  Header,
  Month,
  Time
} from './styled'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import { Divider } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/tr'
import { useSelector } from 'react-redux'

const getFormattedDate = (format: any) => {
  return dayjs().locale('tr').format(format)
}

const DashboardCalendarComponent = () => {
  const themeColor = useSelector((state: any) => state.theme.themeColor);
  return (
    <Container>
      <Header themeColor={themeColor}>
        <div>
          Bügünün Özeti - <b>{getFormattedDate('HH:mm')}</b>
        </div>
        <ArrowRightSLineIcon size={20} />
      </Header>
      <Content>
        <Day>{getFormattedDate('DD')}</Day>
        <Time>
          <Month>{getFormattedDate('MMMM')}</Month>
          <DayName>{getFormattedDate('dddd')}</DayName>
        </Time>
      </Content>

      <Divider dashed style={{ margin: 0 }} />

      <Detail>
        <DetailItem>
          <DetailTitle>Bugünkü Satış</DetailTitle>
          <DetailCount>3.452,12₺</DetailCount>
        </DetailItem>
        <DetailItem>
          <DetailTitle>Bugünkü Tahsilat</DetailTitle>
          <DetailCount>1.452,12₺</DetailCount>
        </DetailItem>
      </Detail>
    </Container>
  )
}

export default DashboardCalendarComponent
