import { createSlice } from "@reduxjs/toolkit";
import { getSubscriptionInfo } from "./api";
import { Subscription } from "../../interfaces/subscription";
export { getSubscriptionInfo }

export interface AuthState {
  subscription: Subscription;
  loading: boolean;
  error: any;
}

const initialState: AuthState = {
  subscription: {} as Subscription,
  loading: true,
  error: "",
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    // setgetSubscription: (state, action) => {
    //   state.subscription = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionInfo.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getSubscriptionInfo.fulfilled, (state, action) => {
      state.subscription = action.payload;
      state.loading = false;
    });
    builder.addCase(getSubscriptionInfo.rejected, (state) => {
      state.loading = false;
      state.error = "Login Error!";
    });
  },
});

export default subscriptionSlice.reducer;
