import React, { useEffect, useState } from 'react'
import {
  ChildContent,
  CloseIcon,
  Container,
  Content,
  Description,
  Footer,
  Header,
  Left,
  ModalMask,
  Right,
  Title
} from './styles'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { Button } from 'antd'
import DeleteBinLineIcon from 'remixicon-react/DeleteBinLineIcon'

interface AdvancedModalProps {
  title: string
  description: string
  children: React.ReactNode
  onOpen: () => void
  onClose: () => void
  onSave?: () => void
  onDelete?: () => void
}

const AdvancedModal = ({ title, description, children, onOpen, onClose, onSave, onDelete }: AdvancedModalProps) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(true)
    onOpen()
  }, [onOpen])

  const handleClose = () => {
    setIsOpen(false)
    onClose()
  }

  const handleSave = () => {
    setIsOpen(false)
    onSave && onSave()
  }

  const handleDelete = () => {
    setIsOpen(false)
    onDelete && onDelete()
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key='modal-mask'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999 }}
        >
          <ModalMask />
        </motion.div>
      )}
      {isOpen && (
        <motion.div
          key='modal-content'
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999 }}
        >
          <Container>
            <Header>
              <Left>
                <Title>{title}</Title>
                <Description>{description}</Description>
              </Left>
              <Right>
                <CloseIcon size='24px' onClick={handleClose} />
              </Right>
            </Header>
            <Content>
              <ChildContent>{children}</ChildContent>
              <Footer>
                {onDelete && (
                  <Button type='primary' danger size='large' onClick={handleDelete}>
                    <DeleteBinLineIcon size={22} />
                  </Button>
                )}
                {onSave && (
                  <Button type='primary' size='large' onClick={handleSave}>
                    Kaydet
                  </Button>
                )}
              </Footer>
            </Content>
          </Container>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default AdvancedModal
