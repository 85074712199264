import React, { useState } from 'react'
import { AntAddBranchesButton, Container, ItemContainer } from './styles'
import { Alert, Col, Row, Space, Table } from 'antd'
import AddCircleLineIcon from 'remixicon-react/AddCircleLineIcon'
import { ColumnsType } from 'antd/es/table'
import { TableActionsLink } from '../../../components/table/styles'
import useWindowWidth from '../../../../../utils/useWindowWidth'
import AdvancedModal from '../../../../../components/modal'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import FormInput from '../../../../../components/input'

import Home4LineIcon from 'remixicon-react/Home4LineIcon'
import HashtagIcon from 'remixicon-react/HashtagIcon'
import Map2LineIcon from 'remixicon-react/Map2LineIcon'

interface DataType {
  id: number
  key: React.Key
  name: string
  address: string
  code: string
}

const CustomersBranchesInformationTab = ({setData}: any) => {
  const width = useWindowWidth()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [branchName, setBranchName] = useState('')
  const [branchCode, setBranchCode] = useState('')
  const [branchAddress, setBranchAddress] = useState('')

  const branchesAdressColumn =
    width >= 768
      ? [
          {
            title: 'Şube Adresi',
            dataIndex: 'address',
            key: 'address'
          }
        ]
      : []

  const branchesCodeColumn =
    width >= 768
      ? [
          {
            title: 'Şube Kodu',
            dataIndex: 'code',
            width: '20%',
            key: 'code'
          }
        ]
      : []

  const actionsColumn = [
    {
      title: 'Aksiyonlar',
      dataIndex: '',
      key: 'x',
      width: width >= 768 ? '10%' : '10%',
      render: (record: any) => (
        <Space size='middle'>
          <TableActionsLink to="id">Düzenle</TableActionsLink>
          <TableActionsLink to="id" style={{ color: '#FF3D00' }} onClick={() => handleRemoveData(record.id)}>
            Sil
          </TableActionsLink>
        </Space>
      )
    }
  ]

  const columns: ColumnsType<DataType> = [
    {
      title: 'Şube Adı',
      dataIndex: 'name',
      key: 'name',
      width: width >= 768 ? '30%' : '90%'
    },
    ...branchesAdressColumn,
    ...branchesCodeColumn,
    ...actionsColumn
  ]
  const [branchData, setBranchData] = useState<DataType[]>([
    {
      id: 1,
      key: '1',
      name: 'Şube 1',
      address: 'Şube 1 Adresi',
      code: '#IST14564'
    },
    {
      id: 2,
      key: '2',
      name: 'Şube 2',
      address: 'Şube 2 Adresi',
      code: '#IST14564'
    },
    {
      id: 3,
      key: '3',
      name: 'Şube 3',
      address: 'Şube 3 Adresi',
      code: '#IST14564'
    }
  ])

  const handleOpenModal = () => {
    console.log('handleOpenModal', isModalOpen)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    console.log('handleCloseModal')
    setIsModalOpen(false)
  }

  const generateBranchCode = (name: any) => {
    const words: any = name.split(' ')
    const generateRandomNumber = () => Math.floor(Math.random() * 1000)
    const code = words.map((word: any) => word.charAt(0).toUpperCase()).join('') + generateRandomNumber()
    return code
  }

  const handleBranchNameChange = (value: any) => {
    setBranchName(value.currentTarget.value)
    setBranchCode(generateBranchCode(value.currentTarget.value))
  }

  const handleBranchAddressChange = (value: any) => {
    setBranchAddress(value.currentTarget.value)
  }
    

  const handleNewData = () => {
    const newData = {
      id: branchData.length + 1,
      key: branchData.length + 1,
      name: branchName,
      address: branchAddress,
      code: branchCode
    };
  
    setBranchData((prevData: any) => [...prevData, newData]);
    setIsModalOpen(false);
  };

  const handleRemoveData = (id: any) => {
    const newData = branchData.filter((item: any) => item.id !== id);
    setBranchData(newData);
  }


  return (
    <Container>
      <ItemContainer>
        <Alert
          message='Bilgilendirme'
          description='Bu müşterinizin birden fazla şubesi varsa, her şube adresini burada ayrı ayrı ekleyebilirsiniz.Satış ekranında dilediğiniz şubeyi seçerek işlem yapabilirsiniz. Şubelere yaptığınız satışların carisi bu müşteriye yansıyacaktır.'
          type='info'
          closable
        />
      </ItemContainer>
      <ItemContainer>
        <AnimatePresence>
          {isModalOpen && (
            <motion.div
              key='modal-content'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999 }}
            >
              <AdvancedModal
                title='Şube Ekle'
                description='Bu pencere altından yeni bir şube ekleyebilirsiniz.'
                onOpen={handleOpenModal}
                onClose={handleCloseModal}
                onSave={handleNewData}
              >
                <Row gutter={[12, 24]}>
                  <Col span={width < 768 ? 24 : 16}>
                    <FormInput
                      label='Şube Adı'
                      icon={<Home4LineIcon size='1.5rem' />}
                      placeholder='Şube adı'
                      onChange={handleBranchNameChange}
                    />
                  </Col>
                  <Col span={width < 768 ? 24 : 8}>
                    <FormInput
                      label='Şube Kodu'
                      icon={<HashtagIcon size='1.5rem' />}
                      placeholder='Şube adını giriniz.'
                      value={branchCode}
                    />
                  </Col>
                  <Col span={24}>
                    <FormInput
                      label='Şube Adresi'
                      icon={<Map2LineIcon size='1.5rem' />}
                      placeholder='Şubenin adresini giriniz.'
                      onChange={handleBranchAddressChange}
                    />
                  </Col>
                </Row>
                
              </AdvancedModal>
            </motion.div>
          )}
        </AnimatePresence>
        <AntAddBranchesButton type='primary' icon={<AddCircleLineIcon />} size='large' onClick={handleOpenModal}>
          Yeni Şube Ekle
        </AntAddBranchesButton>
      </ItemContainer>
      <ItemContainer>
        <Table style={{ width: '100%' }} columns={columns} dataSource={branchData} bordered  />
      </ItemContainer>
    </Container>
  )
}

export default CustomersBranchesInformationTab
