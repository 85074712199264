import { createSlice } from '@reduxjs/toolkit'
import { ToastContent, toast } from 'react-toastify'
import { getCustomers, deleteCustomer, getCustomerById, createCustomer, updateCustomer} from './api'
import { Customers } from '../../interfaces/customers'
export { getCustomers, deleteCustomer }

export interface CustomerState {
  customers: any
  customer: Customers
  /* meta: any;
  skip: number;
  length: number; */
  loading: boolean
  error: any
}

const initialState: CustomerState = {
  customers: [],
  customer: {
      _id: '',
      active: false,
      name: '',
      taxExempt: false,
      customerCurrency: 'try'
    },
  /* meta: null,
  skip: 0,
  length: 0, */
  loading: true,
  error: ''
}

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    /* incrementSkip: (state) => {
      state.skip += 1;
    },
    decrementSkip: (state) => {
      state.skip -= 1;
    },
    resetSkip: (state) => {
      state.skip = 0;
    },
    setSkip: (state, action) => {
      state.skip = action.payload;
    }, */
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomers.pending, (state) => {
        state.loading = true
        state.error = ''
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        if (action.payload) {
          state.customers = action.payload
        }
        state.loading = false
      })
      .addCase(getCustomers.rejected, (state) => {
        state.error = 'Error fetching customers data!'
        toast.error('Get customers failed!')
      })

    builder
      .addCase(getCustomerById.pending, (state) => {
        state.loading = true
        state.error = ''
      })
      .addCase(getCustomerById.fulfilled, (state, action) => {
        if (action.payload) {
          state.customer = action.payload as Customers
        }
        state.loading = false
      })
      .addCase(getCustomerById.rejected, (state) => {
        state.error = 'Error fetching customer data!'
        toast.error('Get customer failed!')
      })

    builder
      .addCase(createCustomer.pending, (state) => {
        state.error = ''
      }
      )
      .addCase(createCustomer.fulfilled, (state, action) => {
        if (action.payload) {
          const payload = action.payload as { error?: boolean; message?: string }

          const status = payload.error ? 'error' : 'success'
          const message = payload.message

          if (message) {
            toast[status](message)
          }
        }
      })
      .addCase(createCustomer.rejected, (state) => {
        state.error = 'Error creating customer!'
        toast.error('Müşteri oluşturulurken bir hata oluştu!')
      })
      
    builder
      .addCase(updateCustomer.pending, (state) => {
        state.error = ''
      }
      )
      .addCase(updateCustomer.fulfilled, (state, action) => {
        if (action.payload) {
          const payload = action.payload as { error?: boolean; message?: string }

          const status = payload.error ? 'error' : 'success'
          const message = payload.message

          if (message) {
            toast[status](message)
          }
        }
      })
      .addCase(updateCustomer.rejected, (state) => {
        state.error = 'Error updating customer!'
        toast.error('Müşteri güncellenirken bir hata oluştu!')
      })

    builder
      .addCase(deleteCustomer.pending, (state) => {
        state.error = ''
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        if (action.payload) {
          const payload = action.payload as { error?: boolean; message?: string }

          const status = payload.error ? 'error' : 'success'
          const message = payload.message

          if (message) {
            toast[status](message)
          }
        }
      })
      .addCase(deleteCustomer.rejected, (state) => {
        state.error = 'Error deleting customer!'
        toast.error('Müşteri silinirken bir hata oluştu!')
      })
  }
})

export default customersSlice.reducer
