import { ExclamationCircleFilled } from '@ant-design/icons'
import { Space, Table } from 'antd'
import React from 'react'
import type { ColumnsType } from 'antd/es/table'
import { TableActionsLink } from '../styles'
import { Modal } from 'antd'
import DataMocks from "../../../../../mocks/customers/musteriler.json"

import useWindowWidth from '../../../../../utils/useWindowWidth'

const { confirm } = Modal

interface DataType {
  key: React.Key
  name: string
  openBalance: number
  checkBillBalance: number,
  description: string
}


const ProdcutsDetailedListTable = ({ setHasItemSelect }: any) => {
  const width = useWindowWidth();

  const data: DataType[] = DataMocks;

  const formatMoney = (money: number) => {
    return money.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }

  const showDeleteModal = () => {
    confirm({
      title: 'Müşterileri Sil',
      icon: <ExclamationCircleFilled />,
      content: 'Seçilen müşteriyi silmek istediğine emin misin?',
      okText: 'Evet, sil',
      cancelText: 'Vazgeç',
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000)
        }).catch(() => console.log('Oops errors!'))
      },
      onCancel() {}
    })
  }

  const openBalanceColumn =
    width >= 768
      ? [
          {
            title: 'Açık Bakiye',
            dataIndex: 'openBalance',
            width: '26%',
            sorter: (a: DataType, b: DataType) => a.openBalance - b.openBalance,
            render: (money: number) => `${formatMoney(money)} ₺`
          }
        ]
      : []

  const checkBillBalanceColumn =
    width >= 768
      ? [
          {
            title: 'Çek/Senet Bakiyesi',
            dataIndex: 'checkBillBalance',
            width: '26%',
            sorter: (a: DataType, b: DataType) => a.checkBillBalance - b.checkBillBalance,
            render: (money: number) => `${formatMoney(money)} ₺`
          }
        ]
      : []

  const actionsColumn =
    width >= 768
      ? [
          {
            title: 'Aksiyonlar',
            dataIndex: '',
            key: 'x',
            render: () => (
              <Space size='middle'>
                <TableActionsLink to="id" style={{ fontWeight: '700', color: 'var(--primary)' }}>Detaylar</TableActionsLink>
                <TableActionsLink to="id">Düzenle</TableActionsLink>
                <TableActionsLink to="id" onClick={showDeleteModal} style={{ color: '#FF3D00' }}>
                  Sil
                </TableActionsLink>
              </Space>
            )
          }
        ]
      : []

  const columns: ColumnsType<DataType> = [
    {
      title: 'Müşteri / Firma Adı',
      dataIndex: 'name',
      width: width >= 768 ? '26%' : '90%',
      render: (text: string) => <a href='/musteri/:id'>{text}</a>
    },
    ...openBalanceColumn,
    ...checkBillBalanceColumn,
    ...actionsColumn
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setHasItemSelect(selectedRows.length > 0 ? true : false)
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === 'Disabled User',
      name: record.name
    })
  }
  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        ...rowSelection
      }}
      columns={columns}
      dataSource={data}
      bordered
      expandable={{
        expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.description}</p>,
        rowExpandable: (record) => record.name !== 'Not Expandable'
      }}
    />
  )
}

export default ProdcutsDetailedListTable
