import React from "react";

function ExchangeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.375rem"
      height="2.375rem"
      fill="none"
      viewBox="0 0 38 38"
      {...props}
    >
      <path
        fill="#3F51B5"
        d="M13.571 27.143c7.496 0 13.572-6.076 13.572-13.572C27.143 6.076 21.067 0 13.57 0 6.076 0 0 6.076 0 13.571c0 7.496 6.076 13.572 13.571 13.572z"
      ></path>
      <path
        fill="#FFF59D"
        d="M15.652 11.762V13.3h-3.438v1.267h3.438v1.538h-3.438c0 .543.09 1.085.272 1.447.18.362.361.724.633.905.271.272.633.362.995.543.362.09.814.181 1.267.181.362 0 .633 0 .995-.09.362-.091.633-.091.905-.272l.362 2.443c-.362.09-.815.18-1.267.18-.452.091-.905.091-1.357.091-.814 0-1.629-.09-2.352-.362-.724-.18-1.358-.542-1.81-.995-.543-.452-.905-.995-1.267-1.719-.271-.633-.452-1.448-.452-2.352h-1.72v-1.538h1.72V13.3h-1.72v-1.538h1.72c.09-.905.271-1.629.543-2.352.362-.634.724-1.267 1.267-1.72.542-.452 1.176-.814 1.9-.995.723-.271 1.538-.362 2.352-.362.362 0 .814 0 1.176.09.362.091.814.091 1.176.272l-.362 2.443c-.271-.09-.543-.18-.904-.271-.362-.09-.634-.09-.996-.09-.452 0-.904.09-1.266.18s-.724.272-.905.543a3.251 3.251 0 00-.633.905c-.181.362-.272.814-.272 1.357h3.438z"
      ></path>
      <path
        fill="#4CAF50"
        d="M24.429 38C31.924 38 38 31.924 38 24.429c0-7.496-6.076-13.572-13.571-13.572-7.496 0-13.572 6.076-13.572 13.572C10.857 31.924 16.933 38 24.43 38z"
      ></path>
      <path
        fill="#fff"
        d="M22.981 21.714c.09.181.18.362.362.543.18.181.362.362.633.452.272.181.634.272.995.453.634.271 1.267.543 1.81.814a5.27 5.27 0 011.357.995c.362.362.724.815.905 1.267.18.453.362 1.086.362 1.72 0 .633-.09 1.175-.272 1.628-.18.452-.452.905-.814 1.266a3.796 3.796 0 01-1.267.815c-.543.18-1.085.362-1.628.452v1.99h-1.629v-1.99c-.543-.09-1.086-.18-1.628-.362-.543-.18-.996-.452-1.358-.905-.452-.361-.723-.904-.995-1.447-.271-.543-.362-1.267-.362-2.081h2.986c0 .452.09.905.181 1.176.09.362.271.543.543.814.18.181.452.362.724.453.271.09.543.09.814.09.362 0 .633 0 .814-.09.272-.09.453-.181.634-.362.18-.181.271-.362.361-.543.091-.181.091-.452.091-.724 0-.271 0-.543-.09-.724-.09-.18-.181-.452-.362-.633s-.362-.362-.634-.452c-.271-.181-.633-.272-.995-.453a35.317 35.317 0 01-1.81-.814 5.27 5.27 0 01-1.357-.995c-.361-.362-.723-.815-.904-1.267-.181-.452-.362-1.086-.362-1.719 0-.543.09-1.086.271-1.538.181-.453.453-.905.814-1.267.362-.362.815-.633 1.267-.905a8.569 8.569 0 011.629-.452v-2.171h1.628v2.171c.543.09 1.086.271 1.629.543.452.271.905.543 1.176.995.362.362.633.905.814 1.448.181.543.272 1.176.272 1.81H26.6c0-.815-.18-1.448-.543-1.81-.362-.362-.814-.634-1.357-.634-.271 0-.543.09-.814.181-.181.09-.362.181-.543.362-.181.181-.272.362-.272.543-.09.181-.09.453-.09.724-.09.18 0 .452 0 .633z"
      ></path>
    </svg>
  );
}

export default ExchangeIcon;
