import React from "react";

function ExchangeLineTLIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 23 22"
      {...props}
    >
      <path
        fill="#fff"
        d="M15.7 11.706a4.6 4.6 0 01-.632 2.359 4.686 4.686 0 01-1.714 1.715 4.6 4.6 0 01-2.36.631H9.925a.208.208 0 01-.154-.06.208.208 0 01-.06-.154v-4.084l-1.437.441a.201.201 0 01-.06.007.21.21 0 01-.214-.214v-.855c0-.103.051-.172.154-.208l1.557-.474v-.622l-1.437.441a.201.201 0 01-.06.007.21.21 0 01-.214-.214v-.855c0-.103.051-.172.154-.208l1.557-.474V7.214c0-.063.02-.114.06-.154.04-.04.092-.06.154-.06h1.07c.062 0 .113.02.153.06.04.04.06.092.06.154v1.21l2.507-.776a.191.191 0 01.187.034.21.21 0 01.087.174v.855c0 .103-.051.172-.154.207l-2.627.81v.62l2.507-.775a.191.191 0 01.187.034.21.21 0 01.087.174v.855c0 .103-.051.172-.154.207l-2.627.81v3.254a3.12 3.12 0 002.126-1.01c.58-.614.869-1.345.869-2.191 0-.063.02-.114.06-.154.04-.04.091-.06.154-.06h1.07c.062 0 .113.02.153.06.04.04.06.091.06.154z"
      ></path>
      <path
        fill="#fff"
        d="M19.614 14.413A8.8 8.8 0 007.137 3.359L6.046 1.45c3.284-1.872 7.446-2 10.956.026 4.94 2.852 6.83 8.956 4.529 14.044l1.476.853-4.581 2.435-.182-5.185 1.37.79zM3.393 7.587a8.8 8.8 0 0012.476 11.054l1.091 1.91a10.993 10.993 0 01-10.956-.026C1.065 17.673-.826 11.57 1.476 6.48L0 5.629l4.58-2.436.182 5.185-1.37-.79z"
      ></path>
    </svg>
  );
}

export default ExchangeLineTLIcon;
