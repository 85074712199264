import React, { useEffect, useState } from 'react'
import Layout from '../../../components/layout'
import { Container, PageTitle, TabIcon } from './styles'
import { Tabs } from 'antd'

import User2LineIcon from 'remixicon-react/User2LineIcon'
import MoneyDollarCircleLineIcon from 'remixicon-react/MoneyDollarCircleLineIcon'
import Image2LineIcon from 'remixicon-react/Image2LineIcon'
import SettingsLineIcon from 'remixicon-react/SettingsLineIcon'
import GitMergeLineIcon from 'remixicon-react/GitMergeLineIcon'
import LinkMIcon from 'remixicon-react/LinkMIcon'
import ProductsIdInformationTab from './components/id'
import FormStepButtons from '../../../components/form/stepButtons'

import useWindowWidth from '../../../utils/useWindowWidth'
import ProductsVariantsTab from './components/variant'
import ProductPricingTab from './components/pricing'
import ProductOtherInformationTab from './components/other'
import ProductsImagesTab from './components/images'
import { Products } from '../../../interfaces/products'
import { useAppDispatch } from '../../../services'
import { createProduct, updateProduct } from '../../../services/productsSlice'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {
  pageState: 'save' | 'edit'
}

const ProductCreatePage = () => {
  const dispatch = useAppDispatch()
  const width = useWindowWidth()
  const navigate = useNavigate()
  const location = useLocation() as { state: { data: Products } }
  const [pageState, setPageState] = useState<Props['pageState']>('save')
  const [activeTab, setActiveTab] = useState<string>('1')
  const [productData, _setProductData] = useState<Products>({
    _id: '',
    active: true,
    name: '',
    isVatIncludedSales: false,
    isVatIncludedPurchase: false,
    descriptionShowInvoice: true
  })

  const setData = (e: any) => {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      _setProductData({ ...productData, [name]: checked })
      return
    } else {
      _setProductData({ ...productData, [name]: value })
    }
  }

  useEffect(() => {
    if (location?.state?.data) {
      const {
        _id,
        active,
        name,
        salesUnit,
        productType,
        salesPrice,
        salesCurrency,
        salesVatRate,
        isVatIncludedSales,
        OIVRate,
        OTVType,
        OTVCode,
        salesOTVRate,
        purchasePrice,
        purchaseCurrency,
        purchaseVatRate,
        isVatIncludedPurchase,
        purchaseDiscount,
        purchaseOTVRate,
        category,
        brand,
        productCode,
        gtipCode,
        countryCode,
        invoiceTitle,
        barcode,
        shelfLocation,
        stockTracking,
        invoceShowSerialNo,
        criticalStockTracking,
        invoiceDescription,
        descriptionShowInvoice,
        tags,
        explanation
      } = location?.state?.data

      _setProductData({
        _id,
        active,
        name,
        salesUnit,
        productType,
        salesPrice,
        salesCurrency,
        salesVatRate,
        isVatIncludedSales,
        OIVRate,
        OTVType,
        OTVCode,
        salesOTVRate,
        purchasePrice,
        purchaseCurrency,
        purchaseVatRate,
        isVatIncludedPurchase,
        purchaseDiscount,
        purchaseOTVRate,
        category,
        brand,
        productCode,
        gtipCode,
        countryCode,
        invoiceTitle,
        barcode,
        shelfLocation,
        stockTracking,
        invoceShowSerialNo,
        criticalStockTracking,
        invoiceDescription,
        descriptionShowInvoice,
        tags,
        explanation
      })
      setPageState('edit')
    }
  }, [location?.state?.data])

  const handleSaveProduct = async () => {
    const {
      active,
      name,
      salesUnit,
      productType,
      salesPrice,
      salesCurrency,
      salesVatRate,
      isVatIncludedSales,
      OIVRate,
      OTVType,
      OTVCode,
      salesOTVRate,
      purchasePrice,
      purchaseCurrency,
      purchaseVatRate,
      isVatIncludedPurchase,
      purchaseDiscount,
      purchaseOTVRate,
      category,
      brand,
      productCode,
      gtipCode,
      countryCode,
      invoiceTitle,
      barcode,
      shelfLocation,
      stockTracking,
      invoceShowSerialNo,
      criticalStockTracking,
      invoiceDescription,
      descriptionShowInvoice,
      tags,
      explanation
    } = productData

    const body = {
      active,
      name,
      salesUnit,
      productType,
      salesPrice,
      salesCurrency,
      salesVatRate,
      isVatIncludedSales,
      OIVRate,
      OTVType,
      OTVCode,
      salesOTVRate,
      purchasePrice,
      purchaseCurrency,
      purchaseVatRate,
      isVatIncludedPurchase,
      purchaseDiscount,
      purchaseOTVRate,
      category,
      brand,
      productCode,
      gtipCode,
      countryCode,
      invoiceTitle,
      barcode,
      shelfLocation,
      stockTracking,
      invoceShowSerialNo,
      criticalStockTracking,
      invoiceDescription,
      descriptionShowInvoice,
      tags,
      explanation
    } as Products

    if (pageState === 'save') {
      await dispatch(createProduct(body))
      navigate('/urunler')
    } else if (pageState === 'edit') {
      await dispatch(updateProduct({ productId: location.state.data._id, product: body }))
      navigate('/urunler')
    }
  }
  const tabItems = [
    {
      key: '1',
      label: 'Kimlik Bilgileri',
      children: <ProductsIdInformationTab data={productData} setData={setData} />,
      icon: (
        <TabIcon>
          <User2LineIcon size={width < 768 ? 16 : 20} />
        </TabIcon>
      )
    },
    {
      key: '2',
      label: 'Fiyatlandırma',
      children: <ProductPricingTab data={productData} setData={setData} />,
      icon: (
        <TabIcon>
          <MoneyDollarCircleLineIcon size={width < 768 ? 16 : 20} />
        </TabIcon>
      )
    },
    {
      key: '3',
      label: 'Diğer Bilgiler',
      children: <ProductOtherInformationTab data={productData} setData={setData} />,
      icon: (
        <TabIcon>
          <SettingsLineIcon size={width < 768 ? 16 : 20} />
        </TabIcon>
      )
    },
    {
      key: '4',
      label: 'Resimler',
      children: <ProductsImagesTab data={productData} setData={setData} />,
      icon: (
        <TabIcon>
          <Image2LineIcon size={width < 768 ? 16 : 20} />
        </TabIcon>
      )
    },
    {
      key: '5',
      label: 'Varyantlar',
      children: <ProductsVariantsTab data={productData} setData={setData} />,
      icon: (
        <TabIcon>
          <GitMergeLineIcon size={width < 768 ? 16 : 20} />
        </TabIcon>
      )
    },
    {
      key: '6',
      label: 'Bağlı Ürünler',
      children: <ProductsVariantsTab data={productData} setData={setData} />,
      icon: (
        <TabIcon>
          <LinkMIcon size={width < 768 ? 16 : 20} />
        </TabIcon>
      )
    }
  ]

  const handleTabChange = (key: string) => {
    setActiveTab(key)
  }

  return (
    <Layout>
      <Container>
        <PageTitle>{pageState === 'save' ? 'Yeni Ürün Ekle' : 'Ürünü Düzenle'}</PageTitle>
        <Tabs
          defaultActiveKey='1'
          type='card'
          size='large'
          items={tabItems}
          tabBarGutter={0}
          activeKey={activeTab}
          onChange={handleTabChange}
        />
        <FormStepButtons activeTab={activeTab} setActiveTab={setActiveTab} handleSave={handleSaveProduct} totalStep={tabItems.length} />
      </Container>
    </Layout>
  )
}

export default ProductCreatePage
