import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--white);
  border-radius: 0.75rem;
`

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  font-size: 18px;
  font-weight: 700;
  color: #383853;
  border: 1px solid rgba(var(--black-rgb), 0.07);
  padding: 1rem;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  span {
    font-size: 24px;
  }

  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    color: var(--primary);
    background-color: rgba(var(--primary-rgb), 0.15);
    padding: 8px 14px;
    border-radius: 6px;
    transition: all 0.3s ease;
    &:hover {
      background-color: rgba(var(--primary-rgb), 0.25);
      transform: translateX(2px);
    }
  }

  @media (max-width: 768px) {
    span {
      font-size: 16px;
    }
    div {
      font-size: 10px;
      padding: 6px 10px;
    }
  }
`

export const Content = styled.div`
  padding: 1.5rem 1rem;
  border: 1px solid rgba(var(--black-rgb), 0.07);
  border-top: none;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`
