import styled from 'styled-components'

export const Link = styled.a`
  color: var(--primary);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const Left = styled.div`
  position: sticky;
  width: 34%;
  top: 0.75rem;
  height: max-content;
  @media (max-width: 48rem) {
    position: relative;
    width: 100%;
  }
`

export const StickyLeft = styled.div`
  background-color: var(--white);
  border-radius: 0.75rem;
`

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 18px;
  font-weight: 700;
  color: #383853;
  border: 1px solid rgba(var(--black-rgb), 0.07);
  padding: 1rem;
  margin-top: 1rem;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  span {
    font-size: 24px;
    transition: 0.3s ease;
    &:hover {
      scale: 1.3;
      user-select: none;
      cursor: pointer;
    }
  }
`

export const SummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
  padding: 1rem;
  border: 1px solid rgba(var(--black-rgb), 0.07);
  border-top: none;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;

  @media (max-width: 1324px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
