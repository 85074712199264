import React from "react";

function BoxFillIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 24}
      height={props.size || 24}
      fill="currentColor"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={props.color || 'var(--white)'}
        
        d="M18.489 5.055L9.757 0 1.025 5.055l8.732 5.056 8.732-5.056zM0 6.835v10.111L8.73 22V11.889L0 6.836zM10.784 22l8.73-5.054V6.835l-8.73 5.054v10.11z"
      ></path>
    </svg>
  );
}

export default BoxFillIcon;
