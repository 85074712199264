import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.75rem;
  width: 100%;
  position: relative;
  margin-bottom: 1.75rem;

  @media only screen and (max-width: 90rem) {
    align-items: start;
    gap: 0;
  }
`

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 46%;
  max-width: 28.75rem;
  position: -webkit-sticky;
  position: sticky;
  top: 2.75rem;
  padding-left: 4rem;
  padding-right: 4rem;

  @media only screen and (max-width: 80rem) {
    top: 0rem;
    align-items: center;
    width: 92%;
    padding: 0;
  }
`

export const RegisterText = styled.p`
  font-weight: 400;
  font-size: 1.125rem;
  color: rgba(56, 56, 83, 0.85);
  margin-top: 1.5rem;
  text-align: center;

  a {
    color: var(--primary);
    font-weight: 700;
  }
`

export const Logo = styled.div`
  width: 16.25rem;
  margin-bottom: 2.875rem;

  @media (max-width: 48rem) {
    margin-top: 2.875rem;
  }
`

export const WelcomeText = styled.div`
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: 0.75rem;
`
export const WelcomeDescription = styled.div`
  font-size: 1rem;
  color: var(--icon);
`

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3.75rem;
`
export const LoginFormItem = styled.div`
  margin-bottom: 1.375rem;
`
export const LoginFormBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`
export const ForgotPassword = styled.p`
  font-size: 0.875rem;
  color: var(--primary);
  font-weight: 600;
  cursor: pointer;
  transition: color 150ms ease-in-out;

  &:hover {
    color: #8487ed;
  }
`

export const InputLabel = styled.label`
  font-size: 1rem;
  color: #383853;
  margin-bottom: 0.5rem;
  font-weight: 500;
`

export const SliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin-top: 1.75rem;
  position: relative;

  @media (max-width: 80rem) {
    display: none;
  }
`

export const ImageWrapper = styled.div`
  min-width: 46rem;
  height: 60.3125rem;
  border-radius: 1.5rem;
  img {
    border-radius: 1.5rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const CommentWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 46rem;
  backdrop-filter: blur(1.1563rem);
  border-top: 0.0625rem solid rgba(204, 202, 199, 0.25);
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  color: var(--white);
`
export const Comment = styled.div`
  font-size: 2.25rem;
  padding: 1.5rem;
  font-weight: 700;
  line-height: 2.5rem;
`
export const CommentBottom = styled.div`
  display: flex;
  padding: 1.5rem;
  justify-content: space-between;
`
export const Author = styled.div`
  padding-bottom: 0.75rem;
  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.625rem;
  }
  p {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.625rem;
  }
  span {
    font-size: 1rem;
    font-weight: 400;
  }
`
export const AuthorRight = styled.div``

export const Stars = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
`
