import { Container, AntdButton } from './styles'

const DetailFooter = ({ data }: any) => {
  return (
    <Container>
      {data?.map((item: any, index: any) => (
        <AntdButton key={index} type='primary' style={item.style}>
          {item.icon} {item.label}
        </AntdButton>
      ))}
    </Container>
  )
}

export default DetailFooter
