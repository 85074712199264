import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background: var(--bg-primary);
  position: relative;
`;

export const SidebarWrapper = styled.div``;

export const ContentWrapper = styled.div`
  width: calc(100% - 6.25rem);
`;

export const HeaderWrapper = styled.div`
  background: var(--header);
  height: 3.75rem;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const Content = styled.div`
  padding: 0.75rem 1.5rem;
`;
