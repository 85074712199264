import { useEffect, useState } from 'react'
import ProdcutsDetailedListTable from './detailed-list'
import ProdcutsTableFilter from './filter'
import ProdcutsListTable from './list'
import ProdcutsSelectedItemActions from './filter/components/selected-actions'
import { AnimatePresence, motion } from 'framer-motion'
import { useAppDispatch, useAppSelector } from '../../../../services'
import { getProducts } from '../../../../services/productsSlice/api';
import { deleteProduct } from '../../../../services/productsSlice'

const ProdcutsTable = ({products}: any) => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector((state) => state.products.loading)
  const [page, setPage] = useState(1)

  const [tableViewType, setTableViewType] = useState('list')
  const [hasItemSelect, setHasItemSelect] = useState(false)

  useEffect(() => {
    dispatch(getProducts({ page: page }))
  }, [page, dispatch])

  // tablolar arası geçişte sayfa numarasını sıfırlıyoruz
  useEffect(() => {setPage(1)}, [tableViewType])

  // ürünü silerken bekliyoruz sonra tekrardan çekiyoruz listeden kaybolması için
  const deleteProductHandler = async (productId: string) => {
    await dispatch(deleteProduct(productId))
    dispatch(getProducts({ page: page }))
  }

  return (
    <>
      <AnimatePresence>
        <div style={{ position: 'relative' }}>
          {hasItemSelect ? (
            <motion.div
              initial={{ opacity: 0, position: 'absolute', left: -24 }}
              key='ProductsSelectedItemActions'
              animate={{ opacity: 1, position: 'relative', left: 0 }}
              exit={{ opacity: 0, position: 'absolute', left: -24 }}
              transition={{ duration: 0.3 }}
            >
              <ProdcutsSelectedItemActions />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0, position: 'absolute', right: -24 }}
              key='ProductsTableFilter'
              animate={{ opacity: 1, position: 'relative', right: 0 }}
              exit={{ opacity: 0, position: 'absolute', right: -24 }}
            >
              <ProdcutsTableFilter tableViewType={tableViewType} setTableViewType={setTableViewType} />
            </motion.div>
          )}
        </div>
      </AnimatePresence>

      {tableViewType === 'list' && (
        <ProdcutsListTable
          setHasItemSelect={setHasItemSelect}
          products={products}
          setPage={setPage}
          loading={loading}
          deleteProductHandler={deleteProductHandler}
        />
      )}
      {tableViewType === 'detailed' && <ProdcutsDetailedListTable setHasItemSelect={setHasItemSelect} />}
    </>
  )
}

export default ProdcutsTable
