import type { ColumnsType } from 'antd/es/table'
import {
  AntdButton,
  AntdTable,
  AntdTag,
  ExpandeTable,
  ExpandedButtons,
  ExpandedCell,
  ExpandedContent,
  ExpandedHeader,
  ExpandedRow
} from './styles'

import ExternalLinkFillIcon from 'remixicon-react/ExternalLinkFillIcon'
import PrinterLineIcon from 'remixicon-react/PrinterLineIcon'

import useWindowWidth from '../../../../../../../../utils/useWindowWidth'

interface DataType {
  key: React.Key
  id: string
  date: string
  status: string
  amount: string
  sales: any
}

const CustomerPreviousSales = () => {
  const width = useWindowWidth()

  const processIdColumn =
    width >= 768
      ? [
          {
            title: 'İşlem No (#ID)',
            dataIndex: 'id',
            key: 'id'
          }
        ]
      : []

  const stateColumn =
    width >= 768
      ? [
          {
            title: 'Durum',
            dataIndex: 'status',
            key: 'status',
            render: (text: any, record: any) => (
              <AntdTag color={record.status === 'Faturalaşmış' ? 'green' : 'red'}>{text}</AntdTag>
            )
          }
        ]
      : []

  const columns: ColumnsType<DataType> = [
    ...processIdColumn,
    { title: 'İşlem Tarihi', dataIndex: 'date', key: 'date' },
    ...stateColumn,
    { title: 'Tutar', dataIndex: 'amount', key: 'amount' }
  ]

  const data: DataType[] = [
    {
      key: 1,
      id: '#6919',
      date: '17.08.2023',
      status: 'Faturalaşmış',
      amount: '1.600,00 ₺',
      sales: [
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        }
      ]
    },
    {
      key: 2,
      id: '#6919',
      date: '17.08.2023',
      status: 'Faturalaşmamış',
      amount: '1.600,00 ₺',
      sales: [
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        }
      ]
    },
    {
      key: 3,
      id: '#6919',
      date: '17.08.2023',
      status: 'Faturalaşmış',
      amount: '1.600,00 ₺',
      sales: [
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        }
      ]
    },
    {
      key: 4,
      id: '#6924',
      date: '17.08.2023',
      status: 'Faturalaşmış',
      amount: '1.600,00 ₺',
      sales: [
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        }
      ]
    },
    {
      key: 5,
      id: '#6924',
      date: '17.08.2023',
      status: 'Faturalaşmamış',
      amount: '1.600,00 ₺',
      sales: [
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        }
      ]
    },
    {
      key: 6,
      id: '#6924',
      date: '17.08.2023',
      status: 'Faturalaşmamış',
      amount: '1.600,00 ₺',
      sales: [
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        }
      ]
    },
    {
      key: 7,
      id: '#6924',
      date: '17.08.2023',
      status: 'Faturalaşmış',
      amount: '1.600,00 ₺',
      sales: [
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        }
      ]
    },
    {
      key: 8,
      id: '#6924',
      date: '17.08.2023',
      status: 'Faturalaşmamış',
      amount: '1.600,00 ₺',
      sales: [
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        },
        {
          name: 'E-GÜVEN EİMZA 3 YILLIK',
          count: 3,
          unitPrices: '169,49 ₺',
          totalPrices: '600,00 ₺'
        }
      ]
    }
  ]

  return (
    <AntdTable
      columns={columns}
      expandable={{
        expandedRowRender: (record) => {
          return (
            <>
              <ExpandedContent>
                <ExpandeTable>
                  <ExpandedHeader>
                    <ExpandedCell>Ürün / Hizmet</ExpandedCell>
                    <ExpandedCell>Adet</ExpandedCell>
                    {width > 768 && <ExpandedCell>Fiyat</ExpandedCell>}
                    {width > 768 && <ExpandedCell>Tutar (KDV Dahil)</ExpandedCell>}
                  </ExpandedHeader>
                  {record.sales.map((sale: any, index: any) => (
                    <ExpandedRow key={index}>
                      <ExpandedCell>{sale.name}</ExpandedCell>
                      <ExpandedCell>{sale.count}</ExpandedCell>
                      {width > 768 && <ExpandedCell>{sale.unitPrices}</ExpandedCell>}
                      {width > 768 && <ExpandedCell>{sale.totalPrices}</ExpandedCell>}
                    </ExpandedRow>
                  ))}
                </ExpandeTable>
              </ExpandedContent>

              <ExpandedButtons>
                <AntdButton style={{ background: 'rgba(86, 90, 233, 0.15)', color: '#565AE9' }}>
                  <ExternalLinkFillIcon size={width < 768 ? 16 : 20} />
                  Satış Ekranına Git
                </AntdButton>
                <AntdButton style={{ background: 'rgba(255, 61, 0, 0.15)', color: '#FF3D00' }}>
                  <PrinterLineIcon size={width < 768 ? 16 : 20} />
                  Yazdır
                </AntdButton>
              </ExpandedButtons>
            </>
          )
        },
        rowExpandable: (record) => record.id !== 'Not Expandable'
      }}
      dataSource={data}
    />
  )
}

export default CustomerPreviousSales
