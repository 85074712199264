import React from 'react'
import { Container, Content, Icon, Row, Table } from './styles'

const DetailLeft = ({ data }: any) => {
  return (
    <Container>
      <Table>
        {data.map((item: any, index: any) => (
          <Row key={index}>
            <Icon>
              {item.icon}
              <span>{item.label}</span>
            </Icon>
            <Content>{item.content}</Content>
          </Row>
        ))}
      </Table>
    </Container>
  )
}

export default DetailLeft
