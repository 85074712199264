import React from 'react'
import { Container, NextButton, PreviousButton, SaveButton } from './styles'

import ArrowRightLineIcon from "remixicon-react/ArrowRightLineIcon"
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon"
import SaveLineIcon from "remixicon-react/SaveLineIcon"

const FormStepButtons = ({activeTab, setActiveTab, handleSave, totalStep}: any) => {
    const handleNext = () => {
        setActiveTab((prev: any) => (parseInt(prev) + 1).toString())
    }

    const handlePrevious = () => {
        setActiveTab((prev: any) => (parseInt(prev) - 1).toString())
    }
  return (
    <Container>
        {<SaveButton onClick={handleSave} style={{background:'#4CAF50'}} type="primary" size='large'><SaveLineIcon size="1.25rem" /> Kaydet</SaveButton>}
        {activeTab !== '1' && <PreviousButton  onClick={handlePrevious} style={{background:'var(--primary)'}} type="primary" disabled={activeTab === '1'} size='large'> <ArrowLeftLineIcon size="1.25rem" /> Geri</PreviousButton> }
        {activeTab !== (totalStep).toString() && <NextButton onClick={handleNext} style={{background:'var(--primary)'}} type="primary" disabled={activeTab === (totalStep).toString()} size='large'>İleri <ArrowRightLineIcon size="1.25rem" /></NextButton>}
    </Container>
  )
}

export default FormStepButtons