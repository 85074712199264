import React, { useEffect, useState } from 'react'
import Layout from '../../../components/layout'
import { Container, PageTitle, TabIcon } from './styles'
import { Tabs } from 'antd'

import User2LineIcon from 'remixicon-react/User2LineIcon'
import ContactsBook2LineIcon from 'remixicon-react/ContactsBook2LineIcon'
import BankLineIcon from 'remixicon-react/BankLineIcon'
import SettingsLineIcon from 'remixicon-react/SettingsLineIcon'
import Home3LineIcon from 'remixicon-react/Home3LineIcon'
import CustomersIdInformationTab from './components/id'
import FormStepButtons from '../../../components/form/stepButtons'

import useWindowWidth from '../../../utils/useWindowWidth'
import CustomersContactInformationTab from './components/contact'
import CustomersCurrentInformationTab from './components/current'
import CustomersOtherInformationTab from './components/other'
import CustomersBranchesInformationTab from './components/branches'
import { Customers } from '../../../interfaces/customers'
import { useAppDispatch } from '../../../services'
import { createCustomer, updateCustomer } from '../../../services/customersSlice/api'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {
  pageState: 'save' | 'edit'
}

const CustomersCreatePage = () => {
  const dispatch = useAppDispatch()
  const width = useWindowWidth()
  const navigate = useNavigate()
  const location = useLocation() as { state: { data: Customers } };
  const [pageState, setPageState] = useState<Props['pageState']>('save')
  const [activeTab, setActiveTab] = useState<string>('1')
  const [customerData, _setCustomerData] = useState<Customers>({
    _id: '',
    name: '',
    active: true,
    taxExempt: false,
    customerCurrency: 'try'
  })

  const setData = (e: any) => {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      _setCustomerData({ ...customerData, [name]: checked })
      return
    } else {
      _setCustomerData({ ...customerData, [name]: value })
    }
  }

  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([])
  const [mails, setMails] = useState<string>('')

  useEffect(() => {
    setData({ target: { name: 'phone', value: Object.values(phoneNumbers) } })
  }, [phoneNumbers])

  useEffect(() => {
    setData({ target: { name: 'mail', value: Object.values(mails) } })
  }, [mails])

useEffect(() => {
    if(location?.state?.data) {
      const {
        _id,
        active,
        name,
        profileImg,
        authPerson,
        mail,
        phone,
        address,
        shippingAddress,
        otherContactInfo,
        taxAdministration,
        trIdNumber,
        taxExempt,
        bankInfo,
        customerCurrency,
        openAccountRiskLimit,
        due,
        fixedDiscount,
        specialPriceList,
        openingBalance,
        classificationId,
        accountingCode,
        notes,
        branch
      } = location?.state?.data

      _setCustomerData({
        _id,
        active,
        name,
        profileImg,
        authPerson,
        mail,
        phone,
        address,
        shippingAddress,
        otherContactInfo,
        taxAdministration,
        trIdNumber,
        taxExempt,
        bankInfo,
        customerCurrency,
        openAccountRiskLimit,
        due,
        fixedDiscount,
        specialPriceList,
        openingBalance,
        classificationId,
        accountingCode,
        notes,
        branch
      })
      setPageState('edit')
    }
  }
  , [location?.state?.data])

  const handleSaveCustomer = async () => {
    const { 
      active, 
      name,
      profileImg,
      authPerson,
      mail,
      phone,
      address,
      shippingAddress,
      otherContactInfo,
      taxAdministration,
      trIdNumber,
      taxExempt,
      bankInfo,
      customerCurrency,
      openAccountRiskLimit,
      due,
      fixedDiscount,
      specialPriceList,
      openingBalance,
      classificationId,
      accountingCode,
      notes,
      branch
    } = customerData

    const body = {
      active, 
      name,
      profileImg,
      authPerson,
      mail,
      phone,
      address,
      shippingAddress,
      otherContactInfo,
      taxAdministration,
      trIdNumber,
      taxExempt,
      bankInfo,
      customerCurrency,
      openAccountRiskLimit,
      due,
      fixedDiscount,
      specialPriceList,
      openingBalance,
      classificationId,
      accountingCode,
      notes,
      branch
    } as Customers

    if(pageState === 'save') {
      await dispatch(createCustomer(body))
      navigate('/musteriler')
    }else if(pageState === 'edit') {
      await dispatch(updateCustomer({ customerId: location.state.data._id, customer: body }))
       navigate('/musteriler')
    }
  }
  const tabItems = [
    {
      key: '1',
      label: 'Kimlik Bilgileri',
      children: <CustomersIdInformationTab data={customerData} setData={setData} />,
      icon: (
        <TabIcon>
          <User2LineIcon size={width < 768 ? 16 : 20} />
        </TabIcon>
      )
    },
    {
      key: '2',
      label: 'İletişim Bilgileri',
      children: (
        <CustomersContactInformationTab
          data={customerData}
          setData={setData}
          phoneNumbers={phoneNumbers}
          setPhoneNumbers={setPhoneNumbers}
          mails={mails}
          setMails={setMails}
          pageState={pageState}
        />
      ),
      icon: (
        <TabIcon>
          <ContactsBook2LineIcon size={width < 768 ? 16 : 20} />
        </TabIcon>
      )
    },
    {
      key: '3',
      label: 'Cari Bilgiler',
      children: <CustomersCurrentInformationTab setData={setData} />,
      icon: (
        <TabIcon>
          <BankLineIcon size={width < 768 ? 16 : 20} />
        </TabIcon>
      )
    },
    {
      key: '4',
      label: 'Diğer Bilgiler',
      children: <CustomersOtherInformationTab setData={setData} />,
      icon: (
        <TabIcon>
          <SettingsLineIcon size={width < 768 ? 16 : 20} />
        </TabIcon>
      )
    },
    {
      key: '5',
      label: 'Şubeler',
      children: <CustomersBranchesInformationTab setData={setData} />,
      icon: (
        <TabIcon>
          <Home3LineIcon size={width < 768 ? 16 : 20} />
        </TabIcon>
      )
    }
  ]

  const handleTabChange = (key: string) => {
    setActiveTab(key)
  }

  return (
    <Layout>
      <Container>
        <PageTitle>{pageState === 'save' ? 'Yeni Müşteri Ekle' : 'Müşteriyi Düzenle'}</PageTitle>
        <Tabs
          defaultActiveKey='1'
          type='card'
          size='large'
          items={tabItems}
          tabBarGutter={0}
          activeKey={activeTab}
          onChange={handleTabChange}
        />
        <FormStepButtons
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          handleSave={handleSaveCustomer}
          totalStep={tabItems.length}
        />
      </Container>
    </Layout>
  )
}

export default CustomersCreatePage
