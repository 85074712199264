import { useEffect, useState } from 'react'
import { AdressButton, Container } from './styles'
import { Col, Row } from 'antd'
import FormInput from '../../../../../components/input'

import UserStarLineIcon from 'remixicon-react/UserStarLineIcon'
import MailLineIcon from 'remixicon-react/MailLineIcon'
import PhoneLineIcon from 'remixicon-react/PhoneLineIcon'
import MapPin5LineIcon from 'remixicon-react/MapPin5LineIcon'
import TruckLineIcon from 'remixicon-react/TruckLineIcon'

const CustomersContactInformationTab = ({ data, setData, pageState, phoneNumbers, setPhoneNumbers, mails, setMails }: any) => {
  const [showShippingAddress, setShowShippingAddress] = useState<boolean>(false)

  const handlePhones = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    console.log(`name: ${name}, value: ${value} `)
    setPhoneNumbers((prevPhone: any) => ({ ...prevPhone, [name]: value }))
  }

  const handleRemovePhone = (key: any) => {
    delete phoneNumbers[key]
    setPhoneNumbers({ ...phoneNumbers })
  }

  const handleMails = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setMails((prevMail: any) => ({ ...prevMail, [name]: value }))
  }

  const handleRemoveMail = (key: any) => {
    delete mails[key]
    setMails({ ...mails })
  }

  useEffect(() => {
    if (pageState === 'edit') {
     const phones = () => {
        const phone = data && data?.phone
        const phoneObj: any = {}
        
        phone.map((item: any, index: number) => {
          phoneObj[`${index === 0 ? 'phone' : `phone_${index}`}`] = item
        }
        )
        return phoneObj
      }
      setPhoneNumbers(phones)
    }
  }, [])

  return (
    <Container>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <FormInput
            label='Yetkili Kişi'
            icon={<UserStarLineIcon size='1.5rem' />}
            placeholder='Adı Soyad'
            tooltip='Firma Yetklisi, CEO, Yönetici veya ilgili kişi.'
            tooltipPosition='input'
            name='authPerson'
            onChange={setData}
            value={data?.authPerson}
          />
        </Col>
        <Col span={24}>
          <FormInput
            label='Cep Telefonu'
            icon={<PhoneLineIcon size='1.5rem' />}
            placeholder='0 (555) 456 78 90'
            tooltip='Firma iletişim telefon numarası.'
            tooltipPosition='title'
            multipleLine={true}
            name='phone'
            onChange={handlePhones}
            onRemove={handleRemovePhone}
            value={data?.phone}
            pageState={pageState}
          />
        </Col>
        <Col span={24}>
          <FormInput
            label='Diğer İletişim Bilgileri'
            placeholder='Sabit Telefon, faks vb.'
            textArea={true}
            name='otherContactInfo'
            onChange={setData}
          />
        </Col>
      </Row>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <FormInput
            label='Eposta'
            icon={<MailLineIcon size='1.5rem' />}
            placeholder='ornek@muhasebia.com'
            tooltip='Firma iletişim mail adresi.'
            tooltipPosition='title'
            multipleLine={true}
            name='mail'
            onChange={handleMails}
            onRemove={handleRemoveMail}
            value={data?.mail}
            pageState={pageState}
          />
        </Col>
        <Col span={24}>
          <FormInput
            label='Adres'
            icon={<MapPin5LineIcon size='1.5rem' />}
            placeholder='Firma adres bilgisi'
            tooltip='Firma adres bilgisi.'
            tooltipPosition='title'
            name='address'
            onChange={setData}
          />
          <AdressButton onClick={() => setShowShippingAddress((prev) => !prev)}>
            {showShippingAddress ? 'Sevkiyat Adresi Yukarıdaki İle Aynı Olsu' : 'Sevkiyat Adresi Farklı Olsun'}{' '}
            <svg
              style={{ marginTop: '0.1rem' }}
              width={16}
              height={16}
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
            >
              <path
                d='M13.9999 4.99989L5.00001 4.99976L4.99998 6.99976L11.9999 6.99986L12 14.5859H6.5858L13 21.0001L19.4142 14.5859L14 14.5859L13.9999 4.99989Z'
                fill='currentColor'
              ></path>
            </svg>
          </AdressButton>

          {showShippingAddress && (
            <FormInput
              label='Sevkiyat Adresi'
              icon={<TruckLineIcon size='1.5rem' />}
              placeholder='Sevkiyat adres bilgisi'
              tooltip='Sevkiyat adres bilgisi.'
              tooltipPosition='title'
              name='shippingAddress'
              onChange={setData}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default CustomersContactInformationTab
