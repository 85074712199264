import { Button, Flex, Select } from 'antd'
import Search from 'antd/es/input/Search'
import { RadioChangeEvent } from 'antd/lib'
import FilterFillIcon from 'remixicon-react/FilterFillIcon'
import ListCheckIcon from 'remixicon-react/ListCheckIcon'
import DetailedListIcon from 'remixicon-react/ListCheck2Icon'
import GridIcon from 'remixicon-react/LayoutGridFillIcon'
import { Container, ListViewOptions } from './styles'
import useWindowWidth from '../../../../../utils/useWindowWidth'

const SuppliersTableFilter = ({ tableViewType, setTableViewType }: any) => {
  const width = useWindowWidth()

  const optionsWithDisabled = [
    {
      label: <ListCheckIcon size={20} color={tableViewType === 'list' ? '#fff' : 'rgba(var(--black-rgb), 0.7)'} />,
      value: 'list'
    },
    {
      label: <DetailedListIcon size={20} color={tableViewType === 'detailed' ? '#fff' : 'rgba(var(--black-rgb), 0.7)'} />,
      value: 'detailed'
    },
    { label: <GridIcon size={20} color={tableViewType === 'grid' ? '#fff' : 'rgba(var(--black-rgb), 0.7)'} />, value: 'grid' }
  ]

  const handleChangeTableView = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio4 checked', value)
    setTableViewType(value)
  }

  return (
    <Container>
      <Flex style={width < 768 ? {} : { maxWidth: '16.5rem' }}>
        <Search placeholder='Müşteri ara...' size='large' style={{ width: '100%' }} />
      </Flex>
      <Flex style={width < 768 ? { maxWidth: 'none' } : { maxWidth: '12.5rem' }}>
        <Select
          style={{ width: '100%', minWidth: '12.5rem' }}
          size='large'
          placeholder='Müşterileri Sırala'
          options={[
            { value: 'alphabet', label: "A'dan Z'ye" },
            { value: 'date', label: 'Tarihe Göre' },
            { value: 'usage', label: 'Sık Kullanılana Göre' }
          ]}
        />
      </Flex>

      <Flex justify='space-between' gap={12}>
        <Button type='default' size='large' icon={<FilterFillIcon color='rgba(var(--black-rgb), 0.7)' />} />
        <ListViewOptions
          options={optionsWithDisabled}
          onChange={handleChangeTableView}
          value={tableViewType}
          optionType='button'
          size='large'
          buttonStyle='solid'
        />
      </Flex>
    </Container>
  )
}

export default SuppliersTableFilter
