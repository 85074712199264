import React from "react";

function TodoIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.5625rem"
      height="2.375rem"
      fill="none"
      viewBox="0 0 41 38"
        {...props}
    >
      <path
        fill="#3F51B5"
        d="M13.873 13.069l-7.44 7.338-4.221-4.121L0 18.497l6.434 6.434 9.65-9.65-2.211-2.212zm0-13.069l-7.44 7.339-4.221-4.122L0 5.429l6.434 6.433 9.65-9.65L13.874 0zm0 26.138l-7.44 7.338-4.221-4.121L0 31.566 6.434 38l9.65-9.65-2.211-2.212z"
      ></path>
      <path
        fill="#90CAF9"
        d="M20.106 16.99H40.21v4.02H20.107v-4.02zm0-13.07H40.21v4.022H20.107V3.92zm0 26.138H40.21v4.021H20.107v-4.02z"
      ></path>
    </svg>
  );
}

export default TodoIcon;
