import React from "react";

function ReminderIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.4375rem"
      height="2.375rem"
      fill="none"
      viewBox="0 0 39 38"
        {...props}
    >
      <path
        fill="#37474F"
        d="M31.57 37.676l-3.457-3.457 1.814-1.814 3.457 3.456a1.253 1.253 0 010 1.815c-.432.432-1.296.432-1.815 0zm-25.06 0l3.456-3.457-1.815-1.814-3.456 3.456a1.253 1.253 0 000 1.815c.432.432 1.296.432 1.814 0z"
      ></path>
      <path
        fill="#C62828"
        d="M19.04 37.157c9.544 0 17.282-7.737 17.282-17.282S28.584 2.592 19.039 2.592c-9.545 0-17.282 7.738-17.282 17.283 0 9.545 7.737 17.282 17.282 17.282z"
      ></path>
      <path
        fill="#EEE"
        d="M19.04 33.701c7.635 0 13.825-6.19 13.825-13.826S26.675 6.049 19.04 6.049 5.213 12.239 5.213 19.875 11.403 33.7 19.04 33.7z"
      ></path>
      <path
        fill="#E53935"
        d="M11.345 28.067l-.49-.49 7.943-7.941.489.488-7.942 7.943z"
      ></path>
      <path fill="#000" d="M18.175 8.641h1.728v11.234h-1.728V8.64z"></path>
      <path
        fill="#000"
        d="M25.334 24.76l-1.404 1.406-5.62-5.62 1.405-1.406 5.62 5.62z"
      ></path>
      <path
        fill="#000"
        d="M19.039 21.603a1.728 1.728 0 100-3.456 1.728 1.728 0 000 3.456z"
      ></path>
      <path
        fill="#C62828"
        d="M19.04 20.74a.864.864 0 100-1.73.864.864 0 000 1.73z"
      ></path>
      <path
        fill="#37474F"
        d="M17.31 0h3.457v2.592h-3.456V0zm19.357 13.135c2.16-3.025 1.815-7.259-.864-9.938C33.124.518 28.89.173 25.866 2.333l10.801 10.802zm-35.256 0c-2.16-3.025-1.815-7.259.864-9.938C4.954.518 9.188.173 12.213 2.333L1.41 13.135z"
      ></path>
    </svg>
  );
}

export default ReminderIcon;
