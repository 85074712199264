import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 100%;

  .avatar-uploader {
    width: max-content;
  }
  .ant-upload.ant-upload-select {
    width: 7.8125rem !important;
    height: 7.8125rem !important;
    font-size: 0.875rem;
  }
  
  @media (max-width: 48rem) {
    flex-direction: column;
  }
`
