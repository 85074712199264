import React from 'react'
import { Container } from './styles'
import Skeleton from 'react-loading-skeleton'


const DetailTitle = ({name}: any) => {
  return (
    <Container>
      {name || <Skeleton width='100%' baseColor='#464ac4' />}
    </Container>
  )
}

export default DetailTitle
