import { useEffect, useRef, useState } from 'react'
import { Col, Row, Tooltip, Tag, Modal, Input, Select, InputRef, Flex } from 'antd'
import { AddLabelButton, AntSelect, Container, ItemContainer } from './styles'
import { InformationIcon, Title } from '../../../../../components/input/styles'

import ListCheck2Icon from 'remixicon-react/ListCheck2Icon'
import ArchiveDrawerLineIcon from 'remixicon-react/ArchiveDrawerLineIcon'
import BarcodeLineIcon from 'remixicon-react/BarcodeLineIcon'
import BillLineIcon from 'remixicon-react/BillLineIcon'
import FormInput from '../../../../../components/input'
import AddCircleLineIcon from 'remixicon-react/AddCircleLineIcon'
import HashtagIcon from 'remixicon-react/HashtagIcon'
import AlarmWarningLineIcon from 'remixicon-react/AlarmWarningLineIcon'
import { AntInputNumber } from '../pricing/styles'
import { PlusOutlined } from '@ant-design/icons'

const ProductOtherInformationTab = ({ data, setData }: any) => {
  const [modals, setModals] = useState({
    category: false,
    brand: false,
    shelf: false
  })
  const [showInvoiceSerialNo, setShowInvoiceSerialNo] = useState(data.stockTracking === 'serino' || false)

  const showModal = (modalName: any) => {
    setModals((prevState) => ({
      ...prevState,
      [modalName]: true
    }))
  }

  const [tags, setTags] = useState<string[]>(data.tags || [])
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<InputRef>(null)

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue])
    }
    setInputVisible(false)
    setInputValue('')
  }
  useEffect(() => {
    setData({ target: { name: 'tags', value: tags } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags])

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag)
    setTags(newTags)
  }

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus()
    }
  }, [inputVisible])

  const forMap = (tag: string) => (
    <span key={tag} style={{ display: 'inline-block' }}>
      <Tag
        closable
        style={{ height: '3.125rem', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 8 }}
        onClose={(e) => {
          e.preventDefault()
          handleClose(tag)
        }}
      >
        {tag}
      </Tag>
    </span>
  )
  const tagChild = tags.map(forMap)

  const tagPlusStyle: React.CSSProperties = {
    background: '#fafafa',
    borderStyle: 'dashed',
    height: '3.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    gap: 8,
    fontWeight: 500,
    fontSize: 16,
    color: 'var(--primary)',
    border: '1px dashed var(--primary)'
  }

  return (
    <Container>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Kategori Seç
              <Tooltip title='Bu alan isteğe bağlıdır. Satış raporunda gruplayabilmek için isterseniz ürünlerinizi kategorilere göre ayırabilirsiniz.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <AntSelect
              style={{ width: '100%' }}
              options={[
                { value: '7,5', label: '% 7,5' },
                { value: '10', label: '% 10' },
                { value: '25', label: '% 25' }
              ]}
              size='large'
              onChange={(value) => setData({ target: { name: 'category', value } })}
            />
            <ListCheck2Icon color='var(--icon)' style={{ position: 'absolute', left: 22, bottom: 12 }} />
            <AddLabelButton
              type='primary'
              size='large'
              style={{ position: 'absolute', right: 12, bottom: 8 }}
              onClick={() => showModal('category')}
            >
              <AddCircleLineIcon size={20} />
            </AddLabelButton>
            <Modal
              title='Yeni Kategori Ekle'
              open={modals.category}
              okText='Kaydet'
              cancelText='İptal'
              maskClosable={false}
              onCancel={() => setModals({ ...modals, category: false })}
            >
              <Row
                style={{ marginTop: '1rem', marginBottom: '1.2rem' }}
                align='middle'
                justify='space-between'
                gutter={[16, 0]}
              >
                <Col span={24}>
                  <Input placeholder='Kategori adı' size='large' />
                </Col>
              </Row>
            </Modal>
          </ItemContainer>
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Marka Seç
              <Tooltip title='Bu alan isteğe bağlıdır. Satış raporunda gruplayabilmek için isterseniz ürünlerinizi markalara göre ayırabilirsiniz.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <AntSelect
              style={{ width: '100%' }}
              options={[
                { value: '7,5', label: '% 7,5' },
                { value: '10', label: '% 10' },
                { value: '25', label: '% 25' }
              ]}
              size='large'
              onChange={(value) => setData({ target: { name: 'brand', value } })}
            />
            <ArchiveDrawerLineIcon color='var(--icon)' style={{ position: 'absolute', left: 22, bottom: 12 }} />
            <AddLabelButton
              type='primary'
              size='large'
              style={{ position: 'absolute', right: 12, bottom: 8 }}
              onClick={() => showModal('brand')}
            >
              <AddCircleLineIcon size={20} />
            </AddLabelButton>
            <Modal
              title='Yeni Marka Ekle'
              open={modals.brand}
              okText='Kaydet'
              cancelText='İptal'
              maskClosable={false}
              onCancel={() => setModals({ ...modals, brand: false })}
            >
              <Row
                style={{ marginTop: '1rem', marginBottom: '1.2rem' }}
                align='middle'
                justify='space-between'
                gutter={[16, 0]}
              >
                <Col span={24}>
                  <Input placeholder='Marka adı' size='large' />
                </Col>
              </Row>
            </Modal>
          </ItemContainer>
        </Col>
        <Col span={24}>
          <FormInput
            label='Ürün Kodu'
            icon={<HashtagIcon size='1.5rem' />}
            placeholder='Varsa Muhasebe & Barkod kodu'
            tooltip='Ürün kodu, ürününüzü tanımlamak için kullanacağınız bir kod olmalıdır.'
            tooltipPosition='input'
            name='productCode'
            value={data.productCode}
            onChange={setData}
          />
        </Col>
        <Col span={24}>
          <FormInput
            label='GTIP'
            icon={<HashtagIcon size='1.5rem' />}
            placeholder='Varsa Muhasebe & Barkod kodu'
            tooltip='GTIP kodu, ürününüzün ithalat ve ihracat işlemlerinde kullanılacak bir kod olmalıdır.'
            tooltipPosition='input'
            name='gtipCode'
            value={data.gtipCode}
            onChange={setData}
          />
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>Ülke Kodu</Title>
            <Select
              showSearch
              labelInValue
              defaultValue={{ value: '0', label: 'Ülke Seçin' }}
              style={{ width: '100%', height: '3.125rem' }}
              size='large'
              options={[{ value: 'germany', label: 'Almanya' }]}
              onChange={(value) => setData({ target: { name: 'countryCode', value } })}
            />
          </ItemContainer>
        </Col>
        <Col span={24}>
          <FormInput
            label='Fatura Başlığı'
            icon={<BillLineIcon size='1.5rem' />}
            placeholder='Varsa Muhasebe & Barkod kodu'
            tooltip='Dilerseniz fatura çıktısında farklı bir ürün adı kullanabilirsiniz. Burayı boş bıraktığınızda ürün adı kullanılacaktır'
            tooltipPosition='input'
            name='invoiceTitle'
            value={data.invoiceTitle}
            onChange={setData}
          />
        </Col>
        <Col span={24}>
          <FormInput
            label='Açıklama'
            icon={<HashtagIcon size='1.5rem' />}
            placeholder='isteğe bağlı not girebilirsiniz. buraya yazdığınız açıklamayı katalog sayfalarınızda da gösteriyoruz.'
            textArea={true}
            name='invoiceDescription'
            value={data.invoiceDescription}
            onChange={setData}
          />
        </Col>
      </Row>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <FormInput
            label='Barkodu'
            icon={<BarcodeLineIcon size='1.5rem' />}
            placeholder='Varsa Muhasebe & Barkod kodu'
            tooltip='Barkod okuyucu kullanıyorsanız satış ekranında ürün seçimini çok daha hızlı yapabilirsiniz.'
            tooltipPosition='input'
            name='barcode'
            value={data.barcode}
            onChange={setData}
          />
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Raf Yeri
              <Tooltip title='Stoklarınızı raflarda takip ediyorsanız raf numarasını kaydedebilirsiniz'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
              </Title>
            <AntSelect
              style={{ width: '100%' }}
              options={[
                { value: '7,5', label: '% 7,5' },
                { value: '10', label: '% 10' },
                { value: '25', label: '% 25' }
              ]}
              size='large'
              onChange={(value) => setData({ target: { name: 'shelfLocation', value } })}
            />
            <ArchiveDrawerLineIcon color='var(--icon)' style={{ position: 'absolute', left: 22, bottom: 12 }} />
            <AddLabelButton
              type='primary'
              size='large'
              style={{ position: 'absolute', right: 12, bottom: 8 }}
              onClick={() => showModal('shelf')}
            >
              <AddCircleLineIcon size={20} />
            </AddLabelButton>
            <Modal
              title='Yeni Raf Yeri Ekle'
              open={modals.shelf}
              okText='Kaydet'
              cancelText='İptal'
              onCancel={() => setModals({ ...modals, shelf: false })}
              maskClosable={false}
            >
              <Row
                style={{ marginTop: '1rem', marginBottom: '1.2rem' }}
                align='middle'
                justify='space-between'
                gutter={[16, 0]}
              >
                <Col span={24}>
                  <Input placeholder='Raf Yeri adı' size='large' />
                </Col>
              </Row>
            </Modal>
          </ItemContainer>
        </Col>

        <Col span={24}>
          <ItemContainer>
            <Title>
              Stok Takibi
              <Tooltip title='Eğer cep telefonları gibi seri numarasından takip ettiğiniz ürünler varsa &quot;Seri no ile Takip&quot; seçin.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
              </Title>
            <Select
              showSearch
              labelInValue
              defaultValue={{ value: 'normal', label: 'Normal Stok Takibi' }}
              style={{ width: '100%', height: '3.125rem' }}
              size='large'
              value={data.stockTracking}
              options={[
                { value: 'normal', label: 'Normal Stok Takibi' },
                { value: 'serino', label: 'Seri no ile Stok Takibi' }
              ]}
              onChange={(value) => {
                setData({ target: { name: 'stockTracking', value: value.value } })
                if (value.value === 'serino') {
                  setShowInvoiceSerialNo(true)
                } else {
                  setShowInvoiceSerialNo(false)
                }
              }}
            />
          </ItemContainer>
        </Col>
        {showInvoiceSerialNo && (
          <Col span={24}>
            <ItemContainer>
              <Title>Fatura Yazdırma</Title>
              <Select
                showSearch
                labelInValue
                defaultValue={data.invoceShowSerialNo || false}
                style={{ width: '100%', height: '3.125rem' }}
                size='large'
                options={[
                  { value: false, label: 'Satış faturasında seri no gözükmesin' },
                  { value: true, label: 'Satış faturasında seri no gözüksün' }
                ]}
                onChange={(value) => setData({ target: { name: 'invoceShowSerialNo', value: value.value } })}
              />
            </ItemContainer>
          </Col>
        )}
        <Col span={24}>
          <ItemContainer>
            <Title>
              Kritik Stok Miktarı
              <Tooltip title='Bir depoda ürününüz burada yazdığınız miktarın altına düşerse sizi uyarırız.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
              </Title>
            <AntInputNumber
              size='large'
              addonBefore={<AlarmWarningLineIcon size='1.5rem' color='rgba(var(--black-rgb), 0.6)' />}
              addonAfter={'Adet'}
              placeholder='1000'
              //   formatter={(value) => `%${value}`}
              //   parser={(value: string | undefined) => (value ? value.replace('%', '') : '')}
              min={0}
              name='criticalStockTracking'
              value={data.criticalStockTracking}
              onChange={(value) => setData({ target: { name: 'criticalStockTracking', value } })}
            />
          </ItemContainer>
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>Etiketler</Title>
            <div style={tags.length ? { marginBottom: 16 } : { display: 'none' }}>
              <Flex gap={8} style={tags.length ? { marginBottom: 8 } : {}}>
                {tagChild}
              </Flex>
            </div>
            {inputVisible ? (
              <Input
                ref={inputRef}
                type='text'
                size='large'
                placeholder='Etiket'
                style={{ height: '3.125rem' }}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            ) : (
              <Tag onClick={showInput} style={tagPlusStyle}>
                <PlusOutlined /> Etiket Ekle
              </Tag>
            )}
          </ItemContainer>
        </Col>
      </Row>
    </Container>
  )
}

export default ProductOtherInformationTab
