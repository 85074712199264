import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`

export const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--header-item);
  border-radius: 0.375rem;
  cursor: pointer;
  width: 2.75rem;
  height: 2.75rem;
  transition: all 0.25s ease-in-out;
  &:hover {
    background-color: var(--primary);

    svg {
      transition: all 0.25s ease-in-out;
      fill: #fff;
    }
  }
`
