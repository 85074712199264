import styled from 'styled-components'

export const Container = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 0.0625rem dashed rgba(var(--black-rgb), 0.07);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  @media (max-width: 48rem) {
    display: none;
  }
`
export const Table = styled.table`
  width: 92%;
  border-collapse: collapse;
`

export const Row = styled.tr`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 0.25rem 0.5rem;
`
export const Icon = styled.td`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary);
  font-size: 0.875rem;
  font-weight: 500;
  min-width: 38%;
  span {
    color: rgba(var(--black-rgb), 0.4);
  }
`
export const Content = styled.td`
  color: var(--text-second);
  font-size: 0.875rem;
  font-weight: 500;
  width: 62%;
`
export const Link = styled.a`
  color: var(--primary);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
