import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../services'
import { useAuth } from '../../../utils/useAuth'
import { setToken } from '../../../services/authSlice'
import {
  BottomWrapper,
  Container,
  FooterNavContainer,
  FooterNavItem,
  Logo,
  NavContainer,
  NavItem,
  NavSubItem,
  NavSubItems,
  TopWrapper,
} from './styles'

import {
  Dashboard2LineIcon,
  Dashboard2FillIcon,
  User5LineIcon,
  User5FillIcon,
  Building3LineIcon,
  Building3FillIcon,
  BoxLineIcon,
  BoxFillIcon,
  ShoppingCartLineIcon,
  ShoppingCartFillIcon,
  TruckLineIcon,
  TruckFillIcon,
  FolderOpenLineIcon,
  FolderOpenFillIcon,
  ExchangeLineTLIcon,
  ExchangeFillTLIcon,
  Settings4LineIcon,
  Settings4FillIcon,
  FolderChartLineIcon,
  FolderChartFillIcon,
  LoginCircleFillIcon,
  QuestionFillIcon
} from '../icons'
import { motion, AnimatePresence } from 'framer-motion'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import LogoDark from '../../../assets/LogoDark'
import SidebarSubscriptionBox from './components/subscriptionBox'
// import LogoIcon from '../../../assets/LogoIcon'

const navItems = [
  {
    id: 1,
    icon: <Dashboard2LineIcon />,
    activeIcon: <Dashboard2FillIcon />,
    title: 'Kontrol Panelim',
    link: '/kontrol-paneli'
  },
  {
    id: 2,
    icon: <User5LineIcon />,
    activeIcon: <User5FillIcon />,
    title: 'Müşterilerim',
    link: '/musteriler',
    subLinks: ['/musteri']
  },
  {
    id: 3,
    icon: <Building3LineIcon />,
    activeIcon: <Building3FillIcon />,
    title: 'Tedarikçilerim',
    link: '/tedarikciler',
    subLinks: ['/tedarikci']
  },
  {
    id: 4,
    icon: <BoxLineIcon />,
    activeIcon: <BoxFillIcon />,
    title: 'Ürünlerim',
    link: '/urunler',
    subLinks: ['/urun', '/urun-varyantlari'],
    subItems:[
      {
        id: 4.1,
        title: 'Depolar',
        link: '/depolar'
      },
      {
        id: 4.2,
        title: 'Üretim',
        link: '/uretim'
      },
      {
        id: 4.3,
        title: 'Özel Fiyat Listeleri',
        link: '/fiyat-listeleri',
        new: true
      },
      {
        id: 4.4,
        title: 'Kataloglarınız',
        link: '/kataloglariniz'
      },
      {
        id: 4.5,
        title: 'Ürün Varyantları',
        link: '/urun-varyantlari'
      }
    ]
  },
  {
    id: 5,
    icon: <ShoppingCartLineIcon />,
    activeIcon: <ShoppingCartFillIcon />,
    title: 'Satışlarım',
    link: '/satislar'
  },
  {
    id: 6,
    icon: <TruckLineIcon />,
    activeIcon: <TruckFillIcon />,
    title: 'Alışlarım',
    link: '/alislar'
  },
  {
    id: 7,
    icon: <FolderOpenLineIcon />,
    activeIcon: <FolderOpenFillIcon />,
    title: 'Tekliflerim',
    link: '/teklifler'
  },
  {
    id: 8,
    icon: <ExchangeLineTLIcon />,
    activeIcon: <ExchangeFillTLIcon />,
    title: 'Nakit Yönetimim',
    link: '/nakit-yonetimi',
    subItems:[
      {
        id: 8.1,
        title: 'Hesaplarım',
        link: '/hesaplarim'
      },
      {
        id: 8.2,
        title: 'Çalışanlar',
        link: '/calisanlar'
      },
      {
        id: 8.3,
        title: 'Masraflar',
        link: '/masraflar'
      },
      {
        id: 8.4,
        title: 'Gelen E-Faturalar',
        link: '/gelen-e-faturalar'
      },
      {
        id: 8.5,
        title: 'Krediler',
        link: '/krediler'
      },
      {
        id: 8.6,
        title: 'Demirbaşlar',
        link: '/demirbaslar'
      },
      {
        id: 8.7,
        title: 'Projeler',
        link: '/projeler'
      },
      {
        id: 8.8,
        title: 'Demirbaşlar',
        link: '/demirbaslar'
      },
      {
        id: 8.9,
        title: 'Çek Portföyü',
        link: '/cek-portfoy'
      },
      {
        id: 8.10,
        title: 'Senet Portföyü',
        link: '/senet-portfoy'
      }
    ]
  },
  {
    id: 9,
    icon: <Settings4LineIcon />,
    activeIcon: <Settings4FillIcon />,
    title: 'Ayarlarım',
    link: '/ayarlar'
  },
  {
    id: 10,
    icon: <FolderChartLineIcon />,
    activeIcon: <FolderChartFillIcon />,
    title: 'Raporlarım',
    link: '/raporlar'
  }
]

const Sidebar = ({ menuState }: any) => {
  const location = useLocation()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const themeColor = useSelector((state: any) => state.theme.themeColor)
  // eslint-disable-next-line
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  // eslint-disable-next-line
  const { token } = useAuth() as any;


  const extractSectionFromUrl = (url: any) => {
    const parts = url.split('/')
    return parts[1]
  }

  const [activeMenu, setActiveMenu] = useState(() => {
    const section = extractSectionFromUrl(location.pathname);
    const activeItem = navItems.find((item) => {
      if (item.link === `/${section}`) {
        return true;
      } else if (item.subLinks && item.subLinks.includes(`/${section}`)) {
        return true;
      }
      return false;
    });
  
    return activeItem ? activeItem.id : 1;
  });

  const [activeSubmenu, setActiveSubmenu] = useState<number | null>(null);
  useEffect(() => {
    const section = extractSectionFromUrl(location.pathname)
    const activeItem: any = navItems.find((item) => item.subItems && item.subItems.find((subItem) => subItem.link === `/${section}`))
    if (activeItem) {
      setActiveSubmenu(activeItem?.subItems.find((subItem: any) => subItem.link === `/${section}`)?.id || null)
    }
  }, [location.pathname])

  
  const handleLogOut = () => {
      setAnchorElUser(null);
      localStorage.removeItem("token");
      dispatch(setToken(""));
      navigate("/kullanici-giris");
  };

  return (
    <Container menuState={menuState}>
      <TopWrapper>
        <Logo>
          <LogoDark menuState={menuState} />
        </Logo>

        <NavContainer>
          {navItems.map((item, index) => (
            <>
            <Link to={item.link} key={index}>
              <NavItem
                key={item.id}
                $is_active={activeMenu === item.id ? 'true' : 'false'}
                themeColor={themeColor}
                onClick={() => setActiveMenu(item.id)}
                menuState={menuState}
              >
                {activeMenu === item.id ? item.activeIcon : item.icon}
                <AnimatePresence>
                  {!menuState && (
                    <motion.p initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                      {item.title}
                    </motion.p>
                  )}
                </AnimatePresence>
              </NavItem>
            </Link>
               {
                item.subItems && activeMenu === item.id && (
                  <NavSubItems menuState={menuState}>
                    {item.subItems.map((subItem, index) => (
                      <Link to={subItem.link} key={index}>
                        <NavSubItem
                          key={subItem.id}
                          $is_active={activeSubmenu === subItem.id ? 'true' : 'false'}
                          themeColor={themeColor}
                          onClick={() => setActiveSubmenu(subItem.id)}
                          menuState={menuState}
                        >
                          <AnimatePresence>
                            {!menuState && (
                              <motion.p initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                                {subItem.title}
                              </motion.p>
                            )}
                          </AnimatePresence>
                        </NavSubItem>
                      </Link>
                    ))}
                  </NavSubItems>
                )
               }
            </>
          ))}
        </NavContainer>
      </TopWrapper>

      <BottomWrapper>
       <SidebarSubscriptionBox menuState={menuState} />

        <FooterNavContainer>
          <FooterNavItem menuState={menuState}>
            <QuestionFillIcon />
            {!menuState && 'Yardım & Destek'}
          </FooterNavItem>
          <FooterNavItem menuState={menuState} onClick={handleLogOut}>
            <LoginCircleFillIcon />
            {!menuState && ' Çıkış Yap'}
          </FooterNavItem>
        </FooterNavContainer>
      </BottomWrapper>
    </Container>
  )
}

export default Sidebar
