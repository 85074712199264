import React from "react";

function CalculatorIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.9375rem"
      height="2.375rem"
      fill="none"
      viewBox="0 0 31 38"
      {...props}
    >
      <path
        fill="#616161"
        d="M30.4 11.4H0v22.8C0 36.29 1.71 38 3.8 38h22.8c2.09 0 3.8-1.71 3.8-3.8V11.4z"
      ></path>
      <path
        fill="#424242"
        d="M26.6 0H3.8C1.71 0 0 1.71 0 3.8v8.55h30.4V3.8c0-2.09-1.71-3.8-3.8-3.8z"
      ></path>
      <path
        fill="#9CCC65"
        d="M26.6 9.5H3.8c-.57 0-.95-.38-.95-.95V3.8c0-.57.38-.95.95-.95h22.8c.57 0 .95.38.95.95v4.75c0 .57-.38.95-.95.95z"
      ></path>
      <path
        fill="#33691E"
        d="M23.75 5.7h1.9v1.9h-1.9V5.7zm-3.8 0h1.9v1.9h-1.9V5.7z"
      ></path>
      <path
        fill="#FF5252"
        d="M26.6 18.05h-2.85c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95z"
      ></path>
      <path
        fill="#E0E0E0"
        d="M6.65 18.05H3.8c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95zm6.65 0h-2.85c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95zm6.65 0H17.1c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95zm-13.3 5.7H3.8c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95zm6.65 0h-2.85c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95zm6.65 0H17.1c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95zm-13.3 5.7H3.8c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95zm6.65 0h-2.85c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95zm6.65 0H17.1c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95zm-13.3 5.7H3.8c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95zm6.65 0h-2.85c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95zm6.65 0H17.1c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95z"
      ></path>
      <path
        fill="#BDBDBD"
        d="M26.6 23.75h-2.85c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95zm0 5.7h-2.85c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95zm0 5.7h-2.85c-.57 0-.95-.38-.95-.95v-1.9c0-.57.38-.95.95-.95h2.85c.57 0 .95.38.95.95v1.9c0 .57-.38.95-.95.95z"
      ></path>
    </svg>
  );
}

export default CalculatorIcon;
