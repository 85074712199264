import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  justify-content: center;
  border-radius: 0.75rem;
  background: var(--white);
  box-shadow: 0rem 0.125rem 0.125rem 0rem rgba(0, 0, 0, 0.06);
  padding: 1.5rem 1.25rem 1.125rem 1.25rem;
`

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Image = styled.img`
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
  cursor: pointer;
  object-fit: cover;
  transition: all 0.25s ease-in-out;
  &:hover {
    transform: translateY(-0.375rem);
    scale: 1.06;
    box-shadow: 0rem 0.375rem 0.375rem 0rem rgba(0, 0, 0, 0.06);
  }
`

export const Name = styled.div`
  color: var(--text-second);
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.375rem;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  &:hover {
    color: var(--primary);
  }

  @media (max-width: 48rem) {
    font-size: 1rem;
  }
`

export const Id = styled.div`
  color: rgba(var(--black-rgb), 0.3);
  font-size: 0.875rem;
  cursor: pointer;
  @media (max-width: 48rem) {
    font-size: 0.75rem;
    
  }
`

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
  width: 100%;
  gap: 0.375rem;

  hr {
    border: none;
    border-right: 0.0625rem dashed rgba(var(--black-rgb), 0.1);
    width: 0.0625rem;
    height: 2.5rem;
    margin: 0;
    @media (max-width: 48rem) {
      height: 1.875rem;
    }
  }
`

export const StatsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
`

export const StatsCount = styled.div`
  color: var(--text-second);
  font-size: 1rem;
  font-weight: 700;
  width: max-content;
  @media (max-width: 48rem) {
    font-size: 0.875rem;
  }
`

export const StatsName = styled.div`
  color: rgba(var(--black-rgb), 0.3);
  font-size: 0.625rem;
  font-weight: 500;
  width: max-content;
  @media (max-width: 48rem) {
    font-size: 0.5rem;
  }
`
