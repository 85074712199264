import React from 'react'

import AddCircleLineIcon from "remixicon-react/AddCircleLineIcon"
import { BlankContainer } from './styles'

const CompanyCardBlank = () => {
  return (
    <BlankContainer>
        <AddCircleLineIcon size={120} />
    </BlankContainer>
  )
}

export default CompanyCardBlank