import { Container, ItemContainer } from './styles'

import { Alert, Col, Row } from 'antd'

const ProductsVariantsTab = ({ data, setData }: any) => {

  return (
    <Container>
      <Row gutter={[12, 24]}>
        <Col span={24}>
        <ItemContainer>
        <Alert
          message='Bilgilendirme'
          description='Muhasebia üzerinde "renk", "ebat"... gibi dilediğiniz şekilde ürün varyantı tanımlayabilir ve ürünlerinizde bunları seçebilirsiniz. Ürün varyantlarınızı tanımlamak için "Ürünler">"Varyantlar" sayfasını kullanabilirsiniz.'
          type='info'
          closable
        />
      </ItemContainer>
        </Col>
      </Row>
    </Container>
  )
}

export default ProductsVariantsTab
