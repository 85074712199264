import { Pie, measureTextWidth } from '@ant-design/plots'

import useWindowWidth from '../../../../utils/useWindowWidth'

const DashboardAsetsComponent = () => {
  const windowWidth = useWindowWidth()

  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(text, style)
    const R = containerWidth / 2

    let scale = 1

    if (containerWidth < textWidth) {
      scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1)
    }

    const textStyleStr = `width:${containerWidth}px;`
    return `<div style="${textStyleStr};font-size:${0.75}em;line-height:${scale < 1 ? 1 : 0.8};color:var(--text-primary);">${text}</div>`
  }

  const data = [
    {
      type: 'KASA',
      value: 27.222
    },
    {
      type: 'POS',
      value: 113.224
    },
    {
      type: 'BANKA',
      value: 67.222
    },
    {
      type: 'STOK',
      value: 11.222
    },
    {
      type: 'AÇIK HESAP',
      value: 42.222
    }
  ]

  const config = {
    appendPadding: 10,
    height: 270,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `${v} ₺`
      }
    },
    legend: {
      position: windowWidth < 768 ? 'bottom' : 'right',
      marker: {
        symbol: 'circle'
      }
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center'
      },
      content: ''
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect()
          const d = Math.sqrt(Math.pow(width / 2, 3) + Math.pow(height / 2, 2))
          const text = datum ? datum.type : 'Toplam Varlıklar'
          return renderStatistic(d, text, {
            fontSize: 12
          })
        }
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: data.map((d) => d.value).reduce((a, b) => a + b, 0) > 100.0 ? '1.25rem' : '2rem'
        },
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect()
          const text = datum ? `${datum.value} ₺` : `${data.reduce((r, d) => r + d.value, 0).toFixed(3)} ₺`
          return renderStatistic(width, text, {
            fontSize: 32
          })
        }
      }
    },
    interactions: [
      {
        type: 'element-selected'
      },
      {
        type: 'element-active'
      },
      {
        type: 'pie-statistic-active'
      }
    ]
  }
  return (
    <div>
      <Pie {...config} />
    </div>
  )
}

export default DashboardAsetsComponent
