import { Button } from 'antd'
import styled from 'styled-components'


export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  @media (max-width: 48rem) {
    flex-direction: column;
    align-items: stretch;
  }
  `


export const AntdButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.375rem;

  @media (max-width: 48rem) {
    gap: 0.25rem;
    font-size: 0.75rem;
    width: 100%;
    justify-content: center;
  }
`