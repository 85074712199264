import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;

  @media (max-width: 48rem) {
    grid-template-columns: 1fr;
  }
`
export const AdressButton = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: var(--primary);
  font-size: 0.85rem;
  margin-top: 0.3rem;
  gap: 0.2rem;
  opacity: 0.8;
  transition: 0.2s;
  margin-bottom: 1rem;
  user-select: none;
  width: max-content;
  float: right;

  &:hover {
    opacity: 1;
    transform: translateX(-0.125rem);
  }
`
