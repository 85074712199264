import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpRequest, RequestType } from '../../plugins/httpRequest'
import { Products } from '../../interfaces/products'
import { PaginationReponse } from '../../interfaces/general'

export const getProducts = createAsyncThunk(
  'getProducts',
  async ({ page, pageSize = 10 }: { page: number; pageSize?: number }) => {
    const response = await HttpRequest<null, PaginationReponse<Products>>({
      url: 'products',
      method: RequestType.GET,
      params: {
        page: page,
        pageSize: pageSize
      }
    })
    return response
  }
)

export const getProductById = createAsyncThunk('getProductById', async function (productId: string) {
  const response = await HttpRequest({
    url: `products/${productId}`,
    method: RequestType.GET
  })
  return response
})

export const createProduct = createAsyncThunk('createProduct', async function (product: Products) {
  const response = await HttpRequest({
    url: 'products',
    method: RequestType.POST,
    body: product
  })
  return response
})

export const updateProduct = createAsyncThunk('updateProduct', async function ({ productId, product }: { productId: string; product: Products }) {
  const response = await HttpRequest({
    url: `products/${productId}`,
    method: RequestType.PATCH,
    body: product
  })
  return response
})

export const deleteProduct = createAsyncThunk('deleteProduct', async function (productId: string) {
  const response = await HttpRequest({
    url: `products/${productId}`,
    method: RequestType.DELETE
  })
  return response
})
