import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 0px 0px 24px 24px;
  background: var(--text-second);
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 24px 32px;
  @media (max-width: 768px) {
    flex-direction: column;
    border-radius: 0px;
  }
`

export const Icon = styled.div`
  width: 64px;
  height: 65px;
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    img {
      width: 40px;
      height: 40px;
    }
  }
`

export const Texts = styled.div``

export const Title = styled.div`
  color: var(--White, #fff);
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 20px;
    text-align: center;
  }
`

export const SubTitle = styled.div`
  color: rgba(255, 255, 255, 0.76);
  font-size: 14px;
  font-weight: 400;
  margin-top: 6px;

  a {
    color: var(--white);
    font-weight: 700;
    text-decoration-line: underline;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
  }
`
