import React from "react";

function EnParaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.375rem"
      height="1.375rem"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#F99D1C"
        d="M.188 11A11.057 11.057 0 014.27 4.27 10.969 10.969 0 0110.983.188c-.632-.12-1.298-.17-1.964-.17-2.528 0-4.851.853-6.713 2.305A10.977 10.977 0 000 9.053c.017.65.068 1.315.188 1.947z"
      ></path>
      <path
        fill="#00AEEF"
        d="M10.983 21.812a10.969 10.969 0 01-6.712-4.082A10.96 10.96 0 01.188 11c-.119.632-.17 1.298-.17 1.964 0 2.528.854 4.868 2.306 6.73A10.965 10.965 0 009.036 22a14.447 14.447 0 001.948-.188z"
      ></path>
      <path
        fill="#C7EAFB"
        d="M4.27 4.27A10.962 10.962 0 00.188 11a11.057 11.057 0 004.082 6.73A11.017 11.017 0 011.964 11c0-2.528.872-4.868 2.306-6.73z"
      ></path>
      <path
        fill="#AA55A1"
        d="M10.983.188a10.97 10.97 0 016.713 4.082A10.962 10.962 0 0121.778 11c.12-.632.171-1.298.171-1.964 0-2.528-.854-4.868-2.306-6.73A10.965 10.965 0 0012.93 0c-.649.017-1.315.068-1.947.188z"
      ></path>
      <path
        fill="#FFCE7B"
        d="M4.27 4.27a10.966 10.966 0 016.713-2.306c2.528 0 4.868.854 6.713 2.306A10.969 10.969 0 0010.983.188 10.97 10.97 0 004.27 4.27z"
      ></path>
      <path
        fill="#9ACA3C"
        d="M21.778 11c-.478 2.716-1.981 5.09-4.065 6.712A10.97 10.97 0 0111 21.795c.632.12 1.298.17 1.964.17 2.528 0 4.868-.853 6.713-2.305a10.977 10.977 0 002.306-6.73 12.112 12.112 0 00-.205-1.93z"
      ></path>
      <path
        fill="#C6DE89"
        d="M4.27 17.712a10.968 10.968 0 006.713 4.083 10.969 10.969 0 006.713-4.083 10.822 10.822 0 01-6.713 2.306 10.86 10.86 0 01-6.713-2.306z"
      ></path>
      <path
        fill="#D3B6D7"
        d="M17.713 17.712C19.797 16.09 21.3 13.716 21.778 11a11.057 11.057 0 00-4.082-6.73A10.978 10.978 0 0120.002 11c0 2.528-.854 4.868-2.289 6.713z"
      ></path>
      <path
        fill="#AA55A1"
        d="M11.684 7.379h1.127c.256 0 .341.102.341.359l.035.359c.41-.342 1.161-.82 2.34-.82 1.35 0 2.118.82 2.118 1.964v5.073c0 .256-.103.376-.36.376h-1.177c-.257 0-.376-.12-.376-.377V9.736c0-.53-.154-.786-.786-.769-.734 0-1.4.427-1.69.666l-.001 4.698c0 .256-.086.376-.342.376h-1.212c-.274 0-.359-.12-.359-.377V7.771c-.017-.29.068-.393.342-.393zm-1.691 2.05v1.69c0 .24-.086.342-.325.342H5.91v1.076c0 .513.274.803 1.145.803s1.145-.29 1.145-.803v-.068c0-.256.102-.359.358-.359h1.025c.256 0 .359.12.359.36v.153c0 1.315-.769 2.169-2.921 2.169-2.152 0-3.006-.854-3.006-2.17v-3.21c0-1.298.871-2.153 3.023-2.153 2.17.017 2.955.855 2.955 2.17zM5.91 9.53v.65H8.2v-.65c0-.512-.324-.803-1.145-.803-.836-.017-1.144.29-1.144.803z"
      ></path>
    </svg>
  );
}

export default EnParaIcon;
