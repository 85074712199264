import React from "react";

function NoteIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.375rem"
      height="2.375rem"
      fill="none"
      viewBox="0 0 38 38"
        {...props}
    >
      <path
        fill="#FF9800"
        d="M33.778 38H4.222A4.235 4.235 0 010 33.778V4.222C0 1.9 1.9 0 4.222 0h29.556C36.1 0 38 1.9 38 4.222v29.556C38 36.1 36.1 38 33.778 38z"
      ></path>
      <path
        fill="#8A5100"
        d="M24.805 10.556h-11.61c-.845 0-1.584-.74-1.584-1.584 0-.844.739-1.583 1.583-1.583h11.611c.845 0 1.584.739 1.584 1.583 0 .845-.739 1.584-1.584 1.584z"
      ></path>
    </svg>
  );
}

export default NoteIcon;
