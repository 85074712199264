import styled from 'styled-components'

export const Container = styled.div`
  background: var(--white);
  border-radius: 0.75rem;
  box-shadow: 0rem 0.125rem 0.125rem 0rem rgba(0, 0, 0, 0.06);
  padding: 1.25rem 1rem;
  font-family: 'Roboto', sans-serif;
  position: relative;
  max-height: 180.6188rem;

  @media (max-width: 75rem) {
   margin-top: 1.5rem;
  }
`

export const Title = styled.div`
  display: flex;
  gap: 0.375rem;
  align-items: center;
`

export const Icon = styled.div`
  border-radius: 50%;
  background: var(--primary);
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CurrencyTitle = styled.div`
  color: var(--text-second);
  font-size: 0.875rem;
  font-weight: 700;
`

export const Content = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 2rem;
`

export const CurrencyText = styled.div`
  color: var(--text-second);
  font-size: 0.875rem;
  font-weight: 400;
`

export const Amount = styled.div`
  display: flex;
  align-items: center;
  gap: 0.125rem;
`

export const CurrencyAmount = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 0.375rem;
`

export const CurrencyState = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  font-size: 0.6875rem;
  font-weight: 600;
  display: flex;

  align-items: center;

  svg {
    transform: ${(props) => (props.color === '#28BF94' ? 'rotate(0deg)' : 'rotate(180deg)')};
  }

  span {
    margin-left: -0.25rem;
  }
`

export const Graphic = styled.div`
  position: absolute;
  bottom: 0.25rem;
  left: -0.3125rem;
  right: -0.3125rem;
  opacity: 0.7;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  clip-path: inset(0 7.6% 0 7.6% round 0.75rem);
  scale: 1.13;
`;

