import styled from 'styled-components'

export const DropdownTitle = styled.p`
  font-size: 1.125rem;
  color: var(--text-second);
  font-weight: 700;
`
export const ToolsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 0.0625rem solid rgba(var(--black-rgb), 0.1);
  padding: 1.25rem 0.625rem 0.625rem 0.625rem;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  flex: 1;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(var(--black-rgb), 0.1);
  }
`
export const ToolsTitle = styled.div`
  color: var(--icon);
  font-size: 0.75rem;
  margin-top: 0.625rem;
  width: max-content;
`
