import styled from 'styled-components'

import TransactionsBg from '../../../../../../assets/icons/customers/transactions-bg.svg'
import { Button } from 'antd'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  background-image: url(${TransactionsBg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom left;
  background-position-x: -0.25rem;

  border-radius: 0.75rem;
  border: 0.0625rem solid rgba(0, 0, 0, 0.07);
  background-color: rgba(var(--white-rgb), 0.79);
  padding: 0.5rem 1.25rem;
  font-family: 'Roboto', sans-serif;

  @media (max-width: 48rem) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    padding: 1rem 1rem;
    background-position-x: -4rem;
    background-size: cover;
  }
`

export const Title = styled.div`
  color: var(--primary);
  font-size: 1.125rem;
  font-weight: 900;
  line-height: 1.5rem;

  @media (max-width: 48rem) {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  justify-content: flex-end;
`

export const AntdButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  gap: 0.25rem;

  color: var(--white);
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 500;
  padding: 0.375rem 0.5rem;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.125rem;
  }

  @media (max-width: 48rem) {
    width: 100%;
    font-size: 0.9rem;
    padding: 1.1rem 0.8rem;
    gap: 0.5rem;
    justify-content: flex-start;
  }
`
