import React from 'react'
import { Buttons, Container, Details, AntdButton } from './styles'

import AddIcon from 'remixicon-react/AddBoxLineIcon'
import ExcelIcon from 'remixicon-react/FileExcel2LineIcon'

import UserIcon from 'remixicon-react/UserLineIcon'

import useWindowWidth from '../../../../utils/useWindowWidth'
import { Link } from 'react-router-dom'

const SuppliersNavigation = ({totalSuppliers}: any) => {
  const width = useWindowWidth()
  return (
    <Container>
      <Buttons>
        <Link to='/tedarikciler/yeni'>
          <AntdButton type='primary' style={{ background: '#28bf94' }}>
            <AddIcon size={20} /> {width < 768 ? 'Tedarikçi Ekle' : 'Yeni Tedarikçi Ekle'}
          </AntdButton>
        </Link>
        <AntdButton type='primary' style={{ background: '#FF876C' }}>
          <ExcelIcon size={20} /> {width < 768 ? 'Tedarikçi Aktar' : 'Excelden Tedarikçi Aktar'}
        </AntdButton>
      </Buttons>
      <Details>
        <UserIcon size={20} /> <b>{totalSuppliers}</b> {width > 825 && 'Toplam Tedarikçi'}
      </Details>
    </Container>
  )
}

export default SuppliersNavigation
