import styled from 'styled-components'

import WelcomeBackground from '../../../../assets/images/dashboard/dashboard-welconme-bg.jpg'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  position: relative;
  padding: 0.25rem;
  border-radius: 0.75rem;
  background-image: url(${WelcomeBackground});
  min-height: 20.8125rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export const Texts = styled.div`
  color: #fff;
  padding: 1.875rem 2.1875rem;
  font-family: 'Lato', sans-serif;
`
export const Muhasebia = styled.h1`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
`
export const WelcomeText = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  b {
    font-weight: 900;
  }
`
export const WelcomeDescription = styled.p`
  color: rgba(255, 255, 255, 0.5);

  font-size: 0.875rem;
  font-weight: 500;
  u {
    color: rgba(255, 255, 255, 0.75);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
`

export const Buttons = styled.div`
  color: #fff;
  padding: 0.625rem 2.1875rem;
  font-family: 'Lato', sans-serif;
  margin-top: px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.75rem;
`
export const ButtonIcon = styled.div`
  border-radius: 0.5rem;
  border: 0.0625rem solid #fff;
  background: rgba(255, 255, 255, 0.25);
  padding: 0.5rem;
  transition: all 0.25s ease-in-out;
`

export const Button = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: max-content;
  border-radius: 0.5rem;
  outline: 0.0625rem solid #fff;
  background: rgba(255, 255, 255, 0.25);
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  min-width: 16.25rem;

  &:hover {
    background: #fff;
    color: var(--primary);
  }

  &:hover ${ButtonIcon} {
    color: #fff !important;
    background: var(--primary) !important;
  }
`

export const ButtonText = styled.div`
  font-size: 1rem;
  font-weight: 500;
  padding: 0 0.625rem;
`
