import { ExclamationCircleFilled } from '@ant-design/icons'
import { Space, Table } from 'antd'
import React, { useState } from 'react'
import type { ColumnsType } from 'antd/es/table'
import { TableActions, TableActionsLink } from '../styles'
import { Modal } from 'antd'

import useWindowWidth from '../../../../../utils/useWindowWidth'
import { Link, useNavigate } from 'react-router-dom'

const { confirm } = Modal

interface DataType {
  _id: number
  name: string
  openBalance: number
  checkBillBalance: number
}

const CustomersListTable = ({ setHasItemSelect, customers, setPage, loading, deleteCustomerHandler }: any) => {
  const width = useWindowWidth()
  const navigate = useNavigate()
  let data = customers.data
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>(null)

  const formatMoney = (money: number) => {
    return money.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }

  const handleDeleteButton = (key: any) => {
    setSelectedRowKeys(key)
    showDeleteModal(key)
    data = data.filter((item: any) => item._id.toString() === key)
  }

  const showDeleteModal = (key: any) => {
    confirm({
      title: 'Müşterileri Sil',
      icon: <ExclamationCircleFilled />,
      content: 'Seçilen müşteriyi silmek istediğine emin misin?',
      okText: 'Evet, sil',
      cancelText: 'Vazgeç',

      // remove selectedRowKeys for promise deleteCustomerLoading
      onOk() {
        return new Promise((resolve, reject) => {
          deleteCustomerHandler(key)
          setTimeout(resolve, 500)
        })
          .then(() => {
            setSelectedRowKeys(null)
            setHasItemSelect(false)
          })
          .catch(() => console.log('Silme işlemi başarısız oldu'))
      },
      onCancel() {
        setSelectedRowKeys(null)
      }
    })
  }

  const openBalanceColumn =
    width >= 768
      ? [
          {
            title: 'Açık Bakiye',
            dataIndex: 'openBalance',
            width: '26%',
            sorter: (a: DataType, b: DataType) => a.openBalance - b.openBalance,
            render: (money: number) => (money ? `${formatMoney(money)} ₺` : '-')
          }
        ]
      : []

  const checkBillBalanceColumn =
    width >= 768
      ? [
          {
            title: 'Çek/Senet Bakiyesi',
            dataIndex: 'checkBillBalance',
            width: '26%',
            sorter: (a: DataType, b: DataType) => a.checkBillBalance - b.checkBillBalance,
            render: (money: number) => (money ? `${formatMoney(money)} ₺` : '-')
          }
        ]
      : []

  const actionsColumn =
    width >= 768
      ? [
          {
            title: 'Aksiyonlar',
            dataIndex: '',
            key: 'x',
            render: (row: any) => (
              <Space size='middle'>
                <TableActionsLink to={`/musteri/${row._id}`} style={{ fontWeight: '700', color: 'var(--primary)' }}>Detaylar</TableActionsLink>
                <TableActions onClick={() =>
                navigate("/musteriler/yeni", {
                  state: {
                    data: row,
                  },
                })
              } >Düzenle</TableActions>
                <TableActionsLink to="#" onClick={() => handleDeleteButton(row._id)} style={{ color: '#FF3D00' }}>
                  Sil
                </TableActionsLink>
              </Space>
            )
          }
        ]
      : []

  const columns: ColumnsType<DataType> = [
    {
      title: 'Müşteri / Firma Adı',
      dataIndex: 'name',
      width: width >= 768 ? '26%' : '90%',
      render: (text: string, row: any) => <Link to={`/musteri/${row._id}`}>{text}</Link>
    },
    ...openBalanceColumn,
    ...checkBillBalanceColumn,
    ...actionsColumn
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setHasItemSelect(selectedRows.length > 0 ? true : false)
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === 'Disabled User',
      name: record.name
    })
  }
  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        ...rowSelection
      }}
      columns={columns}
      rowKey={(record) => record._id}
      dataSource={data}
      loading={loading}
      pagination={{
        total: customers.length,
        pageSize: 10,
        onChange: (page: number) => {
          setPage(page)
        }
      }}
      bordered
    />
  )
}

export default CustomersListTable
