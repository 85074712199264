import React, { useEffect, useState } from 'react'
import { Actions, Company, CompanyDetail, Detail, DetailItem, DetailTitle, EditButton, ProfileImage, SubscriptionDetail, SubscriptionDetailItem } from './styles'

import SirketLogo from '../../../../../assets/images/customers/sirket-logo.png'

import PhoneLineIcon from "remixicon-react/PhoneLineIcon"
import RoadMapLineIcon from "remixicon-react/RoadMapLineIcon"

import TimeLineIcon from "remixicon-react/TimeLineIcon"
import TimerFlashLineIcon from "remixicon-react/TimerFlashLineIcon"

import PencilLineIcon from "remixicon-react/PencilLineIcon"

import { Company as CompanyInterface } from '../../../../../interfaces/company'
import { getCompanyById } from '../../../../../services/companiesSlice/api'
import { useAppDispatch } from '../../../../../services'
import {changeCompany} from '../../../../../services/authSlice/api'
import { toast } from 'react-toastify'
import { useNavigate } from "react-router-dom";

const CompanyCard = ({companyId}: any) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [company, setCompany] = useState<CompanyInterface | null>(null)

  useEffect(() => {
    dispatch(getCompanyById(companyId)).then((data: any) => {
      setCompany(data.payload)
    })
  }, [companyId, dispatch])

  const handleChangeCompany = () => {
    dispatch(changeCompany(companyId)).then(() => {
      toast.success('Şirket başarıyla değiştirildi')
      return navigate('/kontrol-paneli')
    }
    )
  }

  
  return (
    <Company onClick={handleChangeCompany}>
    <CompanyDetail>
      <ProfileImage src={SirketLogo} />
      <Detail>
        <DetailTitle>{company?.name}</DetailTitle>
        <DetailItem><PhoneLineIcon size={16} /> <span>{company?.phone || '+90 555 555 55 55'}</span></DetailItem>
        <DetailItem><RoadMapLineIcon size={16} /> <span>{company?.address || 'Adres Gelecek, adres ekleencek, bu kısıma adres gelecek'}</span></DetailItem>
      </Detail>
    </CompanyDetail>
    <SubscriptionDetail>
      <SubscriptionDetailItem><TimeLineIcon size={16} /> Üyelik tarihi: 04.01.2024</SubscriptionDetailItem>
      <SubscriptionDetailItem><TimerFlashLineIcon size={17} /> Üyelik bitiş tarihi: 04.01.2024</SubscriptionDetailItem>
    </SubscriptionDetail>
    <Actions>
        <EditButton> <PencilLineIcon size={16} /> Düzenle</EditButton>
    </Actions>
  </Company>
  )
}

export default CompanyCard