import React from "react";

function BoxLineIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 19 22"
      {...props}
    >
      <path
        fill="#fff"
        d="M9.5 0L19 5.5v11L9.5 22 0 16.5v-11L9.5 0zM2.994 6.078L9.5 9.844l6.506-3.766L9.5 2.31 2.994 6.078zM2 7.813v7.534l6.5 3.763v-7.534L2 7.813zm8.5 11.297l6.5-3.763V7.813l-6.5 3.763v7.534z"
      ></path>
    </svg>
  );
}

export default BoxLineIcon;
