import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  themeColor: '#565ae9',
  themeRgbColor: '86, 90, 233',
  themeMode: 'light',
  themeFontSize: 16
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    chageThemeColor: (state, action) => {
      state.themeColor = action.payload
    },
    changeThemeRgbColor: (state, action) => {
      state.themeRgbColor = action.payload
    },
    changeThemeMode: (state, action) => {
      state.themeMode = action.payload
    },
    changeThemeFontSize: (state, action) => {
      state.themeFontSize = action.payload
    }
  }
})

export const { chageThemeColor, changeThemeRgbColor, changeThemeFontSize, changeThemeMode } = themeSlice.actions

export default themeSlice.reducer
