import { Container, ItemContainer } from './styles'
import FormInput from '../../../../../components/input'

import { Col, Row } from 'antd'
import { InformationIcon, Title } from '../../../../../components/input/styles'
import { Upload } from 'antd'
import type { UploadFile, UploadProps } from 'antd'
import ImgCrop from 'antd-img-crop'
import { useState } from 'react'

const ProductsImagesTab = ({ data, setData }: any) => {
  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
    }
  ])

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj as any)
        reader.onload = () => resolve(reader.result as string)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow?.document.write(image.outerHTML)
  }

  return (
    <Container>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <ImgCrop rotationSlider>
            <Upload
              action='https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload'
              listType='picture-card'
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              itemRender={(originNode, file, currentFileList) => {
                return (
                  <div style={{ position: 'relative', height: '100%' }}>
                    {originNode}
                    <span
                      style={{
                        position: 'absolute',
                        top: -6,
                        right: -2,
                        backgroundColor: currentFileList.indexOf(file) === 0 ? 'var(--primary)' : 'var(--white)',
                        color: currentFileList.indexOf(file) === 0 ? 'var(--white)' : 'var(--primary)',
                        padding: '2px 4px',
                        borderRadius: 4,
                        fontSize: 12,
                        zIndex: 1,
                        cursor: currentFileList.indexOf(file) === 0 ? 'default' : 'pointer',
                        border: currentFileList.indexOf(file) === 0 ? 'none' : '1px dashed var(--primary)'
                      }}
                    >
                      {currentFileList.indexOf(file) === 0 ? 'Kapak' : 'Kapak Yap'}
                    </span>
                  </div>
                )
              }}
              maxCount={5}
            >
              {fileList.length < 5 && '+ Upload'}
            </Upload>
          </ImgCrop>
        </Col>
      </Row>
    </Container>
  )
}

export default ProductsImagesTab
