import styled from 'styled-components'

export const Container = styled.div`
  padding: 32px;
`
export const Header = styled.div`
  text-align: center;
`
export const Title = styled.div`
  color: var(--text-second);
  font-size: 26px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`
export const SubTitle = styled.div`
  color: var(--icon);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
  span {
    color: var(--primary);
    cursor: pointer;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    margin-top: 16px;
  }
`
export const Companies = styled.div`
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 20px;
  margin-top: 38px;
  margin-bottom: 30px;
  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`
