import Layout from '../../components/layout'
import { useAppSelector } from '../../services'
import CustomersNavigation from './components/navigation'
import CustomersTable from './components/table'

const CostomersPage = () => {
  const customers = useAppSelector((state) => state.customers.customers)
  return (
    <Layout>
      <CustomersNavigation totalCustomer={customers.length} />
      <CustomersTable customers={customers} />
    </Layout>
  )
}

export default CostomersPage
