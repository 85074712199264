import React from "react";

function GoogleGIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 25 25"
      {...props}
    >
      <path
        fill="#FFC107"
        d="M24.757 10.052H23.75V10H12.5v5h7.064c-1.03 2.91-3.8 5-7.064 5a7.5 7.5 0 010-15 7.46 7.46 0 014.976 1.9l3.535-3.536A12.442 12.442 0 0012.5 0C5.597 0 0 5.597 0 12.5S5.597 25 12.5 25 25 19.403 25 12.5c0-.838-.086-1.656-.243-2.448z"
      ></path>
      <path
        fill="#FF3D00"
        d="M1.441 6.682l4.107 3.012A7.497 7.497 0 0112.5 5a7.46 7.46 0 014.976 1.9l3.535-3.536A12.442 12.442 0 0012.5 0C7.699 0 3.535 2.71 1.44 6.682z"
      ></path>
      <path
        fill="#4CAF50"
        d="M12.5 25c3.229 0 6.163-1.236 8.38-3.245l-3.868-3.274A7.444 7.444 0 0112.5 20c-3.251 0-6.012-2.073-7.052-4.966l-4.076 3.14C3.441 22.223 7.642 25 12.5 25z"
      ></path>
      <path
        fill="#1976D2"
        d="M24.757 10.052H23.75V10H12.5v5h7.064a7.526 7.526 0 01-2.554 3.482l.002-.001 3.869 3.273C20.607 22.004 25 18.75 25 12.5c0-.838-.086-1.656-.243-2.448z"
      ></path>
    </svg>
  );
}

export default GoogleGIcon;
