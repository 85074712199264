import { Col, Row, Skeleton, Spin } from 'antd'
import { Bottom, Id, Image, Item, Name, StatsCount, StatsItem, StatsName, Top } from './styles'
import { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'

interface DataType {
  _id: number
  name: string
  openBalance: number
  checkBillBalance: number
  totalSales: number
  isArchive: boolean
  avatar: string
  description: string
}

const formatMoney = (money: number) => {
  const suffixes = ['', 'B', 'M', 'Mi', 'T', 'Q', 'S']
  let idx = 0

  while (money >= 1000 && idx < suffixes.length - 1) {
    money /= 1000
    idx++
  }

  return money % 1 !== 0 ? money?.toFixed(1) + suffixes[idx] : money + suffixes[idx]
}

const CustomersGridTable = ({customers, setPage, loading}: any) => {
  const [data, setData] = useState<DataType[]>([])
  const itemsPerPage = 8
  const [visibleItems, setVisibleItems] = useState(itemsPerPage)
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    if (customers && customers.data) {
      setData((prevData) => [...prevData, ...customers.data]);
    } 
  }, [customers]);
  

  const handleScroll = () => {
    const scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop
    const windowHeight = window.innerHeight || document.documentElement.clientHeight

    if (scrollY + windowHeight >= document.body.scrollHeight - 200 && !loading && hasMore) {
      setTimeout(() => {
        if (loading) {
          setHasMore(false)
        }
        setPage((prevPage: number) => prevPage + 1)
      }, 1000)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleItems, loading, hasMore])


  return (
    <>
      <Row gutter={[22, 20]}>
        {data?.map((customer: DataType) => {
          return (
            <Col span={24} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }} xxl={{ span: 4 }} key={customer?._id}>
              <Item to={`/musteri/${customer?._id}`}>
                <Top>
                  {customer?.avatar ? (
                    <Image src={null || customer.avatar} alt={customer?.name} />
                  ) : (
                    <Skeleton.Avatar active shape='square' size={70} style={{ marginBottom: 16, borderRadius: 6 }} />
                  )}
                  <Name>{customer?.name}</Name>
                  <Id>@{customer?._id}</Id>
                </Top>
                <Bottom>
                  <StatsItem>
                    <StatsCount>{12 ||formatMoney(customer?.totalSales)}</StatsCount>
                    <StatsName>Toplam Satış</StatsName>
                  </StatsItem>
                  <hr />
                  <StatsItem>
                    <StatsCount>{12 ||formatMoney(customer?.openBalance)}₺</StatsCount>
                    <StatsName>Açık Bakiye</StatsName>
                  </StatsItem>
                  <hr />
                  <StatsItem>
                    <StatsCount>{12 ||formatMoney(customer?.checkBillBalance)}₺</StatsCount>
                    <StatsName>Çek Senet B.</StatsName>
                  </StatsItem>
                </Bottom>
              </Item>
            </Col>
          )
        })}
      </Row>
      {loading && (
        <Spin
          style={{ display: 'flex', justifyContent: 'center', marginTop: 32 }}
          size='large'
          indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />}
        ></Spin>
      )}
    </>
  )
}

export default CustomersGridTable
