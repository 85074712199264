import styled from 'styled-components'

interface ContainerProps {
  menuState: boolean
  onClick?: any
}

interface NavItemProps {
  menuState: boolean
  $is_active: string
  themeColor: string
}

export const Container = styled.div<ContainerProps>`
  background: var(--sidebarBg);
  color: var(--white);
  width: ${(props) => (props.menuState ? '6.25rem' : '16.875rem')};
  max-width: ${(props) => (props.menuState ? '6.25rem' : '16.875rem')};
  font-size: 0.875rem;
  padding-top: 2.5rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 300ms ease-in-out;
  position: sticky;
  top: 0rem;
`

export const TopWrapper = styled.div``

export const BottomWrapper = styled.div``

export const Logo = styled.div`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  text-align: center;
  margin-bottom: 2.5rem;
  height: 2.6875rem;
  scale: 0.95;
  flex-shrink: 0;
  border-radius: 62.4375rem;
`

export const NavContainer = styled.ul`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const NavItem = styled.li<NavItemProps>`
  background: ${(props) => (props.$is_active === 'true' ? props.themeColor : 'transparent')};
  display: flex;
  align-items: center;
  border-radius: 0.75rem;
  max-width: ${(props) => (props.menuState ? '3.375rem' : '12.375rem')};
  gap: 1.5rem;
  padding: 0.875rem;
  cursor: pointer;
  transition: all 150ms ease-in;
  font-weight: 700;

  svg {
    min-width: 1.625rem;
    min-height: 1.625rem;
    fill: #fff;
  }

  p {
    font-family: 'Lato', sans-serif;
    font-size: 0.875rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #fff;
  }

  &:hover {
    background: ${(props) => props.themeColor}; 
  }
`

export const NavSubItems = styled.div<{ menuState: boolean }>`
display: ${(props) => (props.menuState ? 'none' : 'block')};
`

export const NavSubItem = styled.div<{ themeColor: string, menuState: boolean, $is_active: string }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.menuState ? 'center' : 'start')};
  gap: 1.5rem;
  padding: 0.875rem;
  margin-left: ${(props) => (props.menuState ? '0' : '3.2rem')};
  cursor: pointer;
  transition: all 150ms ease-in;
  font-weight: 700;
  color: ${(props) => (props.$is_active === 'true' ? '#FFFFFF' : '#B7B7C0')};
  position: relative;


  p {
    display: ${(props) => (props.menuState ? 'none' : 'block')};
    font-family: 'Lato', sans-serif;
    font-size: 0.875rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: all 150ms ease-in;
  }

  &:before {
    content: '';
    position: absolute;
    left: -12px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid ${(props) => props.$is_active === 'true' ? props.themeColor : '#B7B7C0'};
    background: ${(props) => props.$is_active === 'true' ? props.themeColor : 'transparent'};
    transition: all 150ms ease-in;
  }

  &:hover {
    color: #fff;

    &:before {
      background: ${(props) => props.themeColor}; 
      border-color: ${(props) => props.themeColor};
    }
  }

`

export const FooterNavContainer = styled.ul`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #959595;
  margin-top: 1.5rem;
  padding-bottom: 3.125rem;
`

export const FooterNavItem = styled.ul<ContainerProps>`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;
  transition: all 150ms ease-in;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  font-weight: 700;
  justify-content: ${(props) => (props.menuState ? 'center' : 'start')};
  align-items: ${(props) => (props.menuState ? 'center' : 'center')};

  svg {
    min-width: ${(props) => (props.menuState ? '1.625rem' : '1.375rem')};
    min-height: ${(props) => (props.menuState ? '1.625rem' : '1.375rem')};
  }

  &:hover {
    transform: translateX(0.625rem);
    color: #fff;
  }
`
