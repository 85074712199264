import styled from 'styled-components'

import { Radio } from 'antd'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.75rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  @media (max-width: 48rem) {
    flex-direction: column;
    align-items: stretch;
  }
`

export const ListViewOptions = styled(Radio.Group)`
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    svg {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
