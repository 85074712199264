import React from "react";

function CalendarIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.375rem"
      height="2.375rem"
      fill="none"
      viewBox="0 0 38 38"
      {...props}
    >
      <path
        fill="#CFD8DC"
        d="M0 34.103V10.718h37.026v23.385A3.909 3.909 0 0133.128 38H3.898A3.909 3.909 0 010 34.103z"
      ></path>
      <path
        fill="#F44336"
        d="M37.026 6.82v5.847H0V6.82a3.909 3.909 0 013.897-3.898h29.231a3.909 3.909 0 013.898 3.897z"
      ></path>
      <path
        fill="#B71C1C"
        d="M27.282 9.744a2.923 2.923 0 100-5.847 2.923 2.923 0 000 5.847zM9.744 9.744a2.923 2.923 0 100-5.847 2.923 2.923 0 000 5.847z"
      ></path>
      <path
        fill="#B0BEC5"
        d="M27.282 0a1.954 1.954 0 00-1.949 1.949V6.82c0 1.071.877 1.948 1.95 1.948a1.954 1.954 0 001.948-1.948V1.949A1.954 1.954 0 0027.282 0zM9.744 0a1.954 1.954 0 00-1.95 1.949V6.82c0 1.071.878 1.948 1.95 1.948a1.955 1.955 0 001.948-1.948V1.949A1.955 1.955 0 009.744 0z"
      ></path>
      <path
        fill="#90A4AE"
        d="M7.795 16.564h3.897v3.898H7.795v-3.898zm5.846 0h3.897v3.898h-3.897v-3.898zm5.846 0h3.898v3.898h-3.898v-3.898zm5.846 0h3.898v3.898h-3.898v-3.898zM7.795 22.41h3.897v3.898H7.795V22.41zm5.846 0h3.897v3.898h-3.897V22.41zm5.846 0h3.898v3.898h-3.898V22.41zm5.846 0h3.898v3.898h-3.898V22.41zM7.795 28.256h3.897v3.898H7.795v-3.898zm5.846 0h3.897v3.898h-3.897v-3.898zm5.846 0h3.898v3.898h-3.898v-3.898zm5.846 0h3.898v3.898h-3.898v-3.898z"
      ></path>
    </svg>
  );
}

export default CalendarIcon;
