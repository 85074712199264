import styled from 'styled-components'

export const Container = styled.div<{ color: string; background: string }>`
  font-family: 'Roboto', sans-serif;
  border-radius: 0.75rem;
  border: 0.0625rem solid ${({ color }) => `rgba(${color}, 0.07)`};
  background: ${({ color }) => `rgba(${color}, 0.1)`};
  background-image: ${({ background }) => `url(${background})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  padding: 1.125rem 1.25rem;
  flex: 1;
  color: ${({ color }) => `rgba(${color}, 1)`};

  height: 6.25rem;
`

export const Title = styled.h3`
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  width: max-content;
`
export const Value = styled.h3`
  font-size: 1.375rem;
  font-weight: 700;
  margin-top: 0.75rem;
  width: max-content;
`
export const StatusDebtor = styled.h3`
  color: var(--white);
  border-radius: 62.4375rem;
  background: #ff3d00;
  box-shadow: 0rem 0.125rem 0.125rem 0rem rgba(255, 61, 0, 0.25);
  padding: 0.375rem 0.875rem;
  width: max-content;
  color: var(--white);
  text-align: center;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  margin-top: 0.875rem;

  @media (max-width: 48rem) {
    display: none;
  }
`
export const StatusCreditor = styled.h3`
  color: #fff;
  border-radius: 62.4375rem;
  background: #ff3d00;
  box-shadow: 0rem 0.125rem 0.125rem 0rem rgba(255, 61, 0, 0.25);
  padding: 0.375rem 0.875rem;
  width: max-content;
  color: var(--white);
  text-align: center;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  margin-top: 0.875rem;
  @media (max-width: 48rem) {
    display: none;
  }
`
