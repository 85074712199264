import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LoginPage from './pages/Auth/Login'
import RegisterPage from './pages/Auth/Register'
import DashboardPage from './pages/Dashboard'
import CustomersPage from './pages/Customers'
import SuppliersPage from './pages/Suppliers'
import CustomersCreatePage from './pages/Customers/create'
import CustomersDetailPage from './pages/Customers/detail'
import { ProtectedLayout } from './components/layout/ProtectedLayout'
import ChangeCompanyPage from './pages/Auth/ChangeCompany'
import ProfilePage from './pages/Profile'
import SuppliersCreatePage from './pages/Suppliers/create'
import SuppliersDetailPage from './pages/Suppliers/detail'
import ProductsPage from './pages/Products'
import ProductCreatePage from './pages/Products/create'
import ProductsDetailPage from './pages/Products/detail'
import ProductVariantsPage from './pages/ProductVariants'


const App = () => {
  return (
    <Routes>
      {/* <Route path='/' element={<HomePage />} /> */}

      {/* Auth */}
      <Route path='/kullanici-girisi' element={<LoginPage />} />
      <Route path='/kullanici-kaydi' element={<RegisterPage />} />

      <Route element={<ProtectedLayout />}>

      {/* Company */}
      <Route path='/sirket-sec' element={<ChangeCompanyPage />} />

      {/* Dashboard */}
      <Route index path='kontrol-paneli' element={<DashboardPage />} />
      <Route path='' element={<DashboardPage />} />

      {/* Customers */}
      <Route path='musteriler' element={<CustomersPage />} />
      <Route path='musteriler/yeni' element={<CustomersCreatePage />} />
      <Route path='musteri/:id' element={<CustomersDetailPage />} />

      {/* Suppliers */}
      <Route path='tedarikciler' element={<SuppliersPage />} />
      <Route path='tedarikciler/yeni' element={<SuppliersCreatePage />} />
      <Route path='tedarikci/:id' element={<SuppliersDetailPage />} />

      {/* Products */}
      <Route path='urunler' element={<ProductsPage />} />
      <Route path='urunler/yeni' element={<ProductCreatePage />} />
      <Route path='urun/:id' element={<ProductsDetailPage />} />

      {/* Product Variants */}
      <Route path='urun-varyantlari' element={<ProductVariantsPage />} />
      

      {/* Profile */}
      <Route path='profilim' element={<ProfilePage />} />
      </Route>
    </Routes>
  )
}

export default App
