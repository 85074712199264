import React, { useEffect, useState } from 'react';
import { HeaderItem } from '../styles';
import SearchIcon from 'remixicon-react/Search2LineIcon';
import {
  AddFilterItem,
  FilterItem,
  FilterList,
  FilterTitle,
  ItemContent,
  LastSearchItem,
  LastSearchItems,
  Modal,
  ModalMask,
  SearchButton,
  SearchButtonArea,
  SearchItemIcons,
  SearchItemTitle,
} from './styles';
import Search from 'antd/es/input/Search';
import { AudioOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';

import UserFillIcon from 'remixicon-react/User5FillIcon';
import BuildingFillIcon from 'remixicon-react/Building3FillIcon';
import { BoxFillIcon } from '../../../icons';
import ShoppingCartFillIcon from 'remixicon-react/ShoppingCartFillIcon';
import TruckFillIcon from 'remixicon-react/TruckFillIcon';
import FolderOpenFillIcon from 'remixicon-react/FolderOpenFillIcon';
import CloseFillIcon from 'remixicon-react/CloseFillIcon';
import AddFillIcon from 'remixicon-react/AddFillIcon';
import Eye2LineIcon from 'remixicon-react/Eye2LineIcon';
import { AnimatePresence, motion } from 'framer-motion';

const filterItems = [
  { icon: <UserFillIcon size="1.25rem" />, label: 'Müşteriler' },
  { icon: <BuildingFillIcon size="1.25rem" />, label: 'Tedarikçiler' },
  { icon: <BoxFillIcon size="1.25rem" />, label: 'Ürünler' },
  { icon: <ShoppingCartFillIcon size="1.25rem" />, label: 'Satışlar' },
  { icon: <TruckFillIcon size="1.25rem" />, label: 'Alışlar' },
  { icon: <FolderOpenFillIcon size="1.25rem" />, label: 'Teklifler' },
];

const searchItems = [
  { title: '<span>muhasebia_2023_fatura.pdf</span> <b>dosyasını</b> aradınız' },
  { title: '<span>Azat ESER</span> adındaki <b>kullanıcıyı</b>  aradınız' },
  { title: 'Ürün Ekle <b>sayfasını</b>  aradınız' },
  { title: '“<b>Özel arama</b> kelimesi” aradınız' },
];

const HeaderSearchButton = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    document.body.style.overflow = showModal ? 'hidden' : 'unset';
  }, [showModal]);

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: 'var(--primary)',
      }}
    />
  );

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <motion.div
            style={{ zIndex: 999 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <ModalMask onClick={handleCloseModal} />

            <Modal>
              <Flex>
                <Search placeholder='Bir şey ara...' enterButton size='large' suffix={suffix} />
              </Flex>
              <FilterTitle>Nerede arayalım...</FilterTitle>

              <FilterList>
                {filterItems.map((item, index) => (
                  <FilterItem key={index}>
                    <ItemContent>
                      {item.icon}
                      {item.label}
                    </ItemContent>
                    <CloseFillIcon size="1.25rem" />
                  </FilterItem>
                ))}
                <AddFilterItem>
                  <AddFillIcon size="1.25rem" />
                  Yeni Filtre Ekle
                </AddFilterItem>
              </FilterList>

              <FilterTitle style={{ marginTop: '2.5rem' }}>Son Aramalar</FilterTitle>

              <LastSearchItems>
                {searchItems.map((searchItem, index) => (
                  <LastSearchItem key={index}>
                    <SearchItemTitle dangerouslySetInnerHTML={{ __html: searchItem.title }} />

                    <SearchItemIcons>
                      {/* <SearchItemIcon>
                        <Eye2LineIcon size="1.25rem" />
                      </SearchItemIcon> */}
                      <CloseFillIcon size="1.25rem" />
                    </SearchItemIcons>
                  </LastSearchItem>
                ))}
              </LastSearchItems>

              <SearchButtonArea>
                <SearchButton>Ara</SearchButton>
                <Button style={{borderRadius: "0.25rem"}} type='primary' size='large'>Aramaları Temizle</Button>
              </SearchButtonArea>
            </Modal>
          </motion.div>
        )}
      </AnimatePresence>
      <HeaderItem onClick={handleOpenModal}>
        <SearchIcon size="1.25rem" />
      </HeaderItem>
    </>
  );
};

export default HeaderSearchButton;
