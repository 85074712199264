import React from 'react'
import { Action, BottomButton, Container, Content, Header, Title } from './styles'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import { Link } from 'react-router-dom'

const DashboardBox = ({ children, title, link, bottomButton, linkText }: any) => {
  return (
    <Container>
      {title && (
        <Header>
          <Title $title_lenght={title.length}>
            <svg xmlns='http://www.w3.org/2000/svg' width='6' height='34' viewBox='0 0 6 34' fill='none'>
              <rect width='6' height='34' rx='3' fill='#565AE9' />
              <rect width='6' height='19' rx='3' fill='#FF876C' />
            </svg>
            {title}
          </Title>
          {link && !bottomButton && (
            <Link to={link}>
              <Action>
                {linkText || 'Tümünü Gör'} <ArrowRightSLineIcon size={16} />
              </Action>
            </Link>
          )}
        </Header>
      )}
      <Content>{children}</Content>
      {bottomButton && (
        <BottomButton>
          <Link to={link}>{linkText || 'Tümünü Gör'}</Link>
        </BottomButton>
      )}
    </Container>
  )
}

export default DashboardBox
