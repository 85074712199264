import MenuCloseIcon from "remixicon-react/Menu2FillIcon";
import MenuOpenIcon from "remixicon-react/MenuFillIcon";

import { MenuButton } from "./styles";

const HeaderLeft = ({ menuState, setMenuState }: any) => {
  const hanldeMenuStateChange = () => {
    setMenuState(!menuState);
    localStorage.setItem(
      "userConfig",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("userConfig") || "{}"),
        menuState: !menuState,
      })
    );
  };
  return (
    <MenuButton onClick={hanldeMenuStateChange}>
      {menuState ? <MenuOpenIcon /> : <MenuCloseIcon />}
    </MenuButton>
  );
};

export default HeaderLeft;
