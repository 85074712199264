import React from "react";

function ExchangeFillTLIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 22 22"
      {...props}
    >
      <path
        fill="#fff"
        d="M3.71 2.762A10.958 10.958 0 0111 0c6.075 0 11 4.925 11 11a10.949 10.949 0 01-1.972 6.287L17.05 11h2.75A8.8 8.8 0 004.701 4.854l-.99-2.092zM18.29 19.238A10.958 10.958 0 0111 21.998c-6.075 0-11-4.923-11-11 0-2.335.73-4.502 1.972-6.284L4.95 11H2.2a8.8 8.8 0 0015.098 6.146l.99 2.092z"
      ></path>
      <path
        fill="#fff"
        d="M15.4 11.306a4.6 4.6 0 01-.632 2.359 4.686 4.686 0 01-1.714 1.715 4.6 4.6 0 01-2.36.631H9.625a.208.208 0 01-.154-.06.208.208 0 01-.06-.154v-4.084l-1.437.441a.201.201 0 01-.06.007.21.21 0 01-.214-.214v-.855c0-.103.051-.172.154-.208l1.557-.474v-.622l-1.437.441a.201.201 0 01-.06.007.21.21 0 01-.214-.214v-.855c0-.103.051-.172.154-.208l1.557-.474V6.814c0-.062.02-.114.06-.154.04-.04.092-.06.154-.06h1.07c.062 0 .113.02.153.06.04.04.06.092.06.154v1.21l2.507-.776a.191.191 0 01.187.034.21.21 0 01.087.174v.855c0 .103-.051.172-.154.207l-2.627.81v.62l2.507-.775a.191.191 0 01.187.034.21.21 0 01.087.174v.855c0 .103-.051.172-.154.207l-2.627.81v3.254a3.12 3.12 0 002.126-1.01c.58-.614.869-1.345.869-2.191 0-.063.02-.114.06-.154.04-.04.091-.06.154-.06h1.07c.062 0 .113.02.153.06.04.04.06.091.06.154z"
      ></path>
    </svg>
  );
}

export default ExchangeFillTLIcon;
