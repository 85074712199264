import { Button } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  border-bottom: 0.0625rem solid rgba(var(--black-rgb), 0.07);
  border-left: 0.0625rem solid rgba(var(--black-rgb), 0.07);
  border-right: 0.0625rem solid rgba(var(--black-rgb), 0.07);
  padding: 0.75rem 0rem;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;

  @media (max-width: 48rem) {
    flex-direction: column;
    gap: 0.75rem;
  }
`

export const AntdButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 1.125rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  @media (max-width: 48rem) {
    width: 88%;
  }
`
