import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 1.25rem;

  @media (max-width: 48rem) {
    flex-direction: column;
  }
`
export const StickyLeft = styled.div`
  position: sticky;
  width: 34%;
  background-color: var(--white);
  border-radius: 0.75rem;
  height: max-content;
  top: 0.75rem;
  @media (max-width: 48rem) {
    position: relative;
    width: 100%;
  }
`
export const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 66%;

  @media (max-width: 48rem) {
    width: 100%;
  }
`