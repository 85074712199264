import React from 'react'
import DashobardList from '../../../../components/dashboard/list'

import Data from '../../../../mocks/dashboard/yaklasan-kredi-odemeleri.json'


const DashobardUpcomingLoanPaymentsComponent = () => {
  return (
    <DashobardList data={Data} bottomName />
  )
}

export default DashobardUpcomingLoanPaymentsComponent