import { createSlice } from '@reduxjs/toolkit'
import { ToastContent, toast } from 'react-toastify'
import { getProducts, deleteProduct, getProductById, createProduct, updateProduct} from './api'
import { Products } from '../../interfaces/products'
export { getProducts, deleteProduct, getProductById, createProduct, updateProduct }

export interface ProductState {
  products: any
  product: Products
  /* meta: any;
  skip: number;
  length: number; */
  loading: boolean
  error: any
}

const initialState: ProductState = {
  products: [],
  product: {
      _id: '',
      active: false,
      name: '',
    },
  /* meta: null,
  skip: 0,
  length: 0, */
  loading: true,
  error: ''
}

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    /* incrementSkip: (state) => {
      state.skip += 1;
    },
    decrementSkip: (state) => {
      state.skip -= 1;
    },
    resetSkip: (state) => {
      state.skip = 0;
    },
    setSkip: (state, action) => {
      state.skip = action.payload;
    }, */
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = true
        state.error = ''
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        if (action.payload) {
          state.products = action.payload
        }
        state.loading = false
      })
      .addCase(getProducts.rejected, (state) => {
        state.error = 'Error fetching products data!'
        toast.error('Get products failed!')
      })

    builder
      .addCase(getProductById.pending, (state) => {
        state.loading = true
        state.error = ''
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        if (action.payload) {
          state.product = action.payload as Products
        }
        state.loading = false
      })
      .addCase(getProductById.rejected, (state) => {
        state.error = 'Error fetching product data!'
        toast.error('Get product failed!')
      })

    builder
      .addCase(createProduct.pending, (state) => {
        state.error = ''
      }
      )
      .addCase(createProduct.fulfilled, (state, action) => {
        if (action.payload) {
          const payload = action.payload as { error?: boolean; message?: string }

          const status = payload.error ? 'error' : 'success'
          const message = payload.message

          if (message) {
            toast[status](message)
          }
        }
      })
      .addCase(createProduct.rejected, (state) => {
        state.error = 'Error creating product!'
        toast.error('Ürün oluşturulurken bir hata oluştu!')
      })
      
    builder
      .addCase(updateProduct.pending, (state) => {
        state.error = ''
      }
      )
      .addCase(updateProduct.fulfilled, (state, action) => {
        if (action.payload) {
          const payload = action.payload as { error?: boolean; message?: string }

          const status = payload.error ? 'error' : 'success'
          const message = payload.message

          if (message) {
            toast[status](message)
          }
        }
      })
      .addCase(updateProduct.rejected, (state) => {
        state.error = 'Error updating product!'
        toast.error('Ürün güncellenirken bir hata oluştu!')
      })

    builder
      .addCase(deleteProduct.pending, (state) => {
        state.error = ''
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        if (action.payload) {
          const payload = action.payload as { error?: boolean; message?: string }

          const status = payload.error ? 'error' : 'success'
          const message = payload.message

          if (message) {
            toast[status](message)
          }
        }
      })
      .addCase(deleteProduct.rejected, (state) => {
        state.error = 'Error deleting product!'
        toast.error('Ürün silinirken bir hata oluştu!')
      })
  }
})

export default productsSlice.reducer