import React from 'react'
// import CustomersButton from './components/CustomersButton'
import { AntdButton, Buttons, Container, Title } from './styles'

import PencilLineIcon from "remixicon-react/PencilLineIcon"
import AppsLineIcon from "remixicon-react/AppsLineIcon"
import Store2LineIcon from "remixicon-react/Store2LineIcon"
import ArrowDownSFillIcon from "remixicon-react/ArrowDownSFillIcon"

const ProductsTransactions = () => {
  return (
    <Container>
        <Title>Hızlı İşlemler</Title>
        <Buttons>
        <AntdButton type="primary" style={{background: "#565AE9", borderColor: "#4A4ED1"}}><PencilLineIcon size={18} /> Ürünü Düzenle </AntdButton>
        <AntdButton type="primary" style={{background: "#E6AA00", borderColor: "#CA9600"}}><Store2LineIcon size={18} /> <span>Stok İşlemleri <ArrowDownSFillIcon size={16} /></span></AntdButton>
        <AntdButton type="primary" style={{background: "#8A5100", borderColor: "#794700"}}><AppsLineIcon size={18} /> <span>Diğer İşlemler <ArrowDownSFillIcon size={16} /></span></AntdButton>
        </Buttons>
      
    </Container>
  )
}

export default ProductsTransactions
