import styled from 'styled-components'
import CloseFillIcon from 'remixicon-react/CloseFillIcon'

export const Settings = styled.div``
export const Modal = styled.div`
  background-color: var(--white);
  box-shadow: -6px 0px 6px 0px rgba(0, 0, 0, 0.06);
  min-width: 345px;
  min-height: 100vh;
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
`

export const ModalMask = styled.div`
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  overflow: hidden;
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(var(--black-rgb), 0.1);
  padding: 20px 20px 12px 20px;
`

export const SettingsTitle = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-size: 26px;
  color: var(--text-second);
  font-weight: 600;
  margin: 16px 0;
`

export const CloseIcon = styled(CloseFillIcon)`
  color: var(--icon);
  width: 32px;
  height: 32px;
  transition: all 0.2s ease-in-out;
  font-size: 32px;
  cursor: pointer;
  &:hover {
    color: var(--text-second);
  }
`



export const SettingsItemTitle = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: var(--text-second);
  font-weight: 500;
`

export const SettingsItemDescription = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: var(--icon);
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 10px;
`

export const SettingsColor = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  padding: 0px 20px 20px 20px;
  border-bottom: 1px solid rgba(var(--black-rgb), 0.1);
`


export const SettingsItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ThemeColorItems = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 8px;
`

export const ColorItem = styled.div<{ color: string; isSelected: boolean }>`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: ${(props) => props.color + '9A'};
  border: 1px solid ${(props) => props.color + '5A'};
  position: relative;

  ${(props) =>
    props.isSelected &&
    `
  border: 1px solid ${props.color};
  background-color: ${props.color};
  transform: scale(1.05);
`}

  &:after {
    content: '';
    display: ${(props) => (props.isSelected ? 'block' : 'none')};
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    transform: scale(1.05);
    background-color: ${(props) => props.color};
    border: 1px solid ${(props) => props.color};
  }
`

export const ThemeItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ThemeImage = styled.img`
  object-fit: cover;
  transition: all 0.2s ease-in-out;
  border-radius: 8px;
`
export const ThemeName = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: var(--text-second);
  opacity: 0.75;
  margin-top: 8px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
`

export const SettingsTheme = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
  border-bottom: 1px solid rgba(var(--black-rgb), 0.1);
`
export const ThemeItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  cursor: pointer;

  &:hover ${ThemeImage} {
    scale: 1.05;
  }

  &:hover ${ThemeName} {
    opacity: 1;
  }

  ${(props) =>
    props.isSelected &&
    `
    ${ThemeImage} {
      outline: 3px solid var(--black);
    }
    ${ThemeName} {
      opacity: 1;
    }
    &:hover {
      ${ThemeImage} {
       scale: 1;
      }
    }
  `}
`

export const SettingsFont = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 0px 20px 0px 20px;
`


