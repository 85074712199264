import React from 'react';

function useNumberFormatter(number) {
    const formatNumber = (number) => {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + "M";
        } else if (number >= 10000) {
            return (number / 1000).toFixed(0) + "B";
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + "B";
        } else {
            return number.toString();
        }
    };

    const formattedNumber = formatNumber(number);

    return formattedNumber;
}

export default useNumberFormatter;
