import React, { useState } from 'react'
import { AntInputNumber, Container, ItemContainer } from './styles'
import { Col, Row, Select, Tooltip } from 'antd'
import { InformationIcon, Title } from '../../../../../components/input/styles'

import PercentLineIcon from 'remixicon-react/PercentLineIcon'
import CopperDiamondLineIcon from 'remixicon-react/CopperDiamondLineIcon'
import ScissorsCutLineIcon from 'remixicon-react/ScissorsCutLineIcon'
import AlignTopIcon from 'remixicon-react/AlignTopIcon'
import AlignBottomIcon from 'remixicon-react/AlignBottomIcon'
import currencyList from '../../../../../mocks/enums/currencyList'

const { Option } = Select

const ProductPricingTab = ({ data, setData }: any) => {
  const [salesCurrency, setSalesCurrency] = useState(data?.salesCurrency || 'TRY')
  const [purchaseCurrency, setPurchaseCurrency] = useState(data?.purchaseCurrency || 'TRY')
  const [otvRateType, setOtvRateType] = useState(data?.OTVType || 'yok')
  const [showOTVCode, setShowOTVCode] = useState(otvRateType !== 'yok')

  const handleSalesCurrencyChange = (value: string) => {
    setSalesCurrency(value)
    setData({ target: { name: 'salesCurrency', value } })
  }

  const handlePurchaseCurrencyChange = (value: string) => {
    setPurchaseCurrency(value)
    setData({ target: { name: 'purchaseCurrency', value } })
  }

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const selectAfterCurrency = (onChange: any, type: string) => (
    <Select defaultValue={type === 'salesCurrency' ? salesCurrency : purchaseCurrency} onChange={onChange}>
      {currencyList.map((currency) => (
        <Option key={currency.value} value={currency.value}>
          {currency.label}
        </Option>
      ))}
    </Select>
  )

  return (
    <Container>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Satış Fiyatı
              <Tooltip title='Buraya yazdığınız fiyat satış ekranında otomatik gelir, isterseniz satış anında değiştirebilirsiniz.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <AntInputNumber
              size='large'
              addonBefore={<AlignTopIcon size='1.5rem' color='rgba(var(--black-rgb), 0.6)' />}
              addonAfter={selectAfterCurrency(handleSalesCurrencyChange, 'salesCurrency')}
              placeholder='1.125.000'
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
              name='salesPrice'
              value={data.salesPrice}
              onChange={(value) => setData({ target: { name: 'salesPrice', value } })}
            />
          </ItemContainer>
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Satış KDV Oranı
              <Tooltip title='Müşterinize uyguladığınız belli bir iskonto varsa satış ekranında otomatik hesaplarız. İsterseniz satış yaparken iskontoyu değiştirebilirsiniz. Belli bir iskonto ile satış yapmıyorsanız boş bırakabilirsiniz.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <AntInputNumber
              size='large'
              addonBefore={<PercentLineIcon size='1.5rem' color='rgba(var(--black-rgb), 0.6)' />}
              addonAfter={'%'}
              placeholder='24'
              //   formatter={(value) => `%${value}`}
              //   parser={(value: string | undefined) => (value ? value.replace('%', '') : '')}
              min={0}
              max={27}
              name='fixedDiscount'
              value={data.salesVatRate}
              onChange={(value) => setData({ target: { name: 'salesVatRate', value } })}
            />
          </ItemContainer>
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Satış Fiyatına KDV Dahil mi?
              <Tooltip title='Satış fiyatınızı KDV dahil mi yoksa hariç mi gireceğinizi seçin. KDV dahil seçerseniz satış fiyatınızın KDV oranı kadar artırılır.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <Select
              showSearch
              labelInValue
              defaultValue={{ value: false, label: 'KDV Hariç' }}
              style={{ width: '100%', height: '3.125rem' }}
              size='large'
              options={[
                { value: false, label: 'KDV Hariç' },
                { value: true, label: 'KDV Dahil' }
              ]}
              value={data.isVatIncludedSales}
              onChange={(value) => setData({ target: { name: 'isVatIncludedSales', value: value.value } })}
            />
          </ItemContainer>
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Ö.İ.V. Oranı
              <Tooltip title='Özel İletişim Vergisi (ÖİV) oranını girin. ÖİV oranı, satış fiyatına KDV dahilse KDV+ÖİV oranı kadar hesaplanır. ÖİV oranı, satış fiyatına KDV dahil değilse sadece ÖİV oranı kadar hesaplanır.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <Select
              showSearch
              labelInValue
              defaultValue={{ value: 'yok', label: 'Ö.İ.V. YOK' }}
              style={{ width: '100%', height: '3.125rem' }}
              size='large'
              options={[
                { value: '7,5', label: '% 7,5' },
                { value: '10', label: '% 10' },
                { value: '25', label: '% 25' }
              ]}
              value={data.OIVRate}
              filterOption={filterOption}
              onChange={(value) => setData({ target: { name: 'OIVRate', value: value.value } })}
            />
          </ItemContainer>
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Ö.T.V Oranı
              <Tooltip title='Özel Tüketim Vergisi (ÖTV) oranını girin. ÖTV oranı, satış fiyatına KDV dahilse KDV+ÖTV oranı kadar hesaplanır. ÖTV oranı, satış fiyatına KDV dahil değilse sadece ÖTV oranı kadar hesaplanır.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <AntInputNumber
              size='large'
              addonBefore={<CopperDiamondLineIcon size='1.5rem' color='rgba(var(--black-rgb), 0.6)' />}
              addonAfter={
                <Select
                  defaultValue={data.OTVType || 'yok'}
                  value={data.OTVType}
                  onChange={(value) => {
                    setData({ target: { name: 'OTVType', value } })
                    setOtvRateType(value)
                    setShowOTVCode(value !== 'yok')
                  }}
                >
                  <Option value='yok'>Ö.T.V. YOK</Option>
                  <Option value='yuzde'>Yüzdesel Ö.T.V. (%)</Option>
                  <Option value='tutar'>Maktu Ö.T.V.</Option>
                </Select>
              }
              placeholder={otvRateType === 'yok' ? '-' : otvRateType === 'yuzde' ? '24' : '1.000.000'}
              disabled={otvRateType === 'yok'}
              name='salesOTVRate'
              value={data.salesOTVRate}
              onChange={(value) => setData({ target: { name: 'salesOTVRate', value } })}
            />
          </ItemContainer>
        </Col>

        {showOTVCode && (
          <Col span={24}>
            <ItemContainer>
              <Title>
                Ö.T.V. Kodu
                <Tooltip title='Özel Tüketim Vergisi (ÖTV) Kodu. ÖTV oranınızı seçtikten sonra buradan hangi ÖTV listesine ait olduğunu seçebilirsiniz.'>
                  <InformationIcon size='1.35rem' color='var(--icon)' />
                </Tooltip>
              </Title>
              <Select
                showSearch
                labelInValue
                defaultValue={{ value: '0', label: 'Ö.T.V. Kodunu Seç' }}
                style={{ width: '100%', height: '3.125rem' }}
                size='large'
                options={[
                  { value: '1', label: 'Petrol ve doğalgaz ürünleri (ÖTV 1. Liste)' },
                  { value: '2', label: 'Motorlu taşıt araçları (tescile tabi olanlar) (ÖTV 2. Liste)' },
                  { value: '3', label: 'Kolalı gazoz, alkollü içeçekler ve tütün mamülleri (ÖTV 3. Liste)' },
                  { value: '4', label: 'Dayanıklı tüketim ve diğer mallar (ÖTV 4. Liste)' },
                  { value: '3a', label: 'Alkollü içeçekler (ÖTV 3A Liste)' },
                  { value: '3b', label: 'Tütün mamülleri (ÖTV 3B Liste)' },
                  { value: '3c', label: 'Kolalı gazozlar (ÖTV 3C Liste)' }
                ]}
                value={data.OTVCode}
                filterOption={filterOption}
                onChange={(value) => setData({ target: { name: 'OTVCode', value: value.value } })}
              />
            </ItemContainer>
          </Col>
        )}
      </Row>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <ItemContainer>
            <Title>Alış Fiyatı</Title>
            <AntInputNumber
              size='large'
              addonBefore={<AlignBottomIcon size='1.5rem' color='rgba(var(--black-rgb), 0.6)' />}
              addonAfter={selectAfterCurrency(handlePurchaseCurrencyChange, 'purchaseCurrency')}
              placeholder='1.125.000'
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: string | undefined) => (value ? value.replace(/\D/g, '') : '')}
              name='purchasePrice'
              value={data.purchasePrice}
              onChange={(value) => setData({ target: { name: 'purchasePrice', value } })}
            />
          </ItemContainer>
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>Alış KDV Oranı</Title>
            <AntInputNumber
              size='large'
              addonBefore={<PercentLineIcon size='1.5rem' color='rgba(var(--black-rgb), 0.6)' />}
              addonAfter={'%'}
              placeholder='24'
              //   formatter={(value) => `%${value}`}
              //   parser={(value: string | undefined) => (value ? value.replace('%', '') : '')}
              min={0}
              max={27}
              name='purchaseVatRate'
              value={data.purchaseVatRate}
              onChange={(value) => setData({ target: { name: 'purchaseVatRate', value } })}
            />
          </ItemContainer>
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Alış Fiyatına KDV Dahil mi?
              <Tooltip title='Satış fiyatınızı KDV dahil mi yoksa hariç mi gireceğinizi seçin. KDV dahil seçerseniz satış fiyatınızın KDV oranı kadar artırılır.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <Select
              showSearch
              labelInValue
              defaultValue={{ value: false, label: 'KDV Hariç' }}
              style={{ width: '100%', height: '3.125rem' }}
              size='large'
              options={[
                { value: false, label: 'KDV Hariç' },
                { value: true, label: 'KDV Dahil' }
              ]}
              value={data.isVatIncludedPurchase}
              onChange={(value) => setData({ target: { name: 'isVatIncludedPurchase', value: value.value } })}
            />
          </ItemContainer>
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Alış İskonto Oranı
              <Tooltip title='Müşterinize uyguladığınız belli bir iskonto varsa satış ekranında otomatik hesaplarız. İsterseniz satış yaparken iskontoyu değiştirebilirsiniz. Belli bir iskonto ile satış yapmıyorsanız boş bırakabilirsiniz.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <AntInputNumber
              size='large'
              addonBefore={<ScissorsCutLineIcon size='1.5rem' color='rgba(var(--black-rgb), 0.6)' />}
              addonAfter={'%'}
              placeholder='24'
              name='purchaseDiscount'
              value={data.purchaseDiscount}
              onChange={(value) => setData({ target: { name: 'purchaseDiscount', value } })}
            />
          </ItemContainer>
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Alış Ö.T.V Oranı
              <Tooltip title='Özel Tüketim Vergisi (ÖTV) oranını girin. ÖTV oranı, satış fiyatına KDV dahilse KDV+ÖTV oranı kadar hesaplanır. ÖTV oranı, satış fiyatına KDV dahil değilse sadece ÖTV oranı kadar hesaplanır.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <AntInputNumber
              size='large'
              addonBefore={<CopperDiamondLineIcon size='1.5rem' color='rgba(var(--black-rgb), 0.6)' />}
              addonAfter={'%'}
              placeholder='24'
              name='purchaseOTVRate'
              value={data.purchaseOTVRate}
              onChange={(value) => setData({ target: { name: 'purchaseOTVRate', value } })}
            />
          </ItemContainer>
        </Col>
      </Row>
    </Container>
  )
}

export default ProductPricingTab
