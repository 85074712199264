import styled from 'styled-components'

import { Button } from 'antd'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.75rem;
  border: 0.0625rem solid rgba(var(--black-rgb), 0.07);
  background: rgba(var(--white-rgb), 0.79);
  padding: 0.875rem 1.125rem;
  font-family: 'Roboto', sans-serif;

  @media (max-width: 48rem) {
    padding: 0.625rem 0.625rem;
    flex-direction: column;
    align-items: stretch;
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  @media (max-width: 48rem) {
    gap: 0.625rem;
    flex-direction: column;

    a{
      width: 100%;
    }
  }
`

export const AntdButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  font-size: 1rem;
  font-weight: bold;
  padding: 1.25rem 1.25rem;

  @media (max-width: 48rem) {
    gap: 0.25rem;
    font-size: 0.75rem;
    width: 100%;
    justify-content: center;
  }
`

export const Details = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  color: rgba(var(--black-rgb), 0.7);

  font-weight: 300;

  b {
    font-weight: 700;
  }

  @media (max-width: 48rem) {
    display: none;
  }
`
