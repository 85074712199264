import React, { useEffect, useState } from 'react'
import { AntInput, Container, InformationIcon, Title, AddLineButton, AntTextArea, InputRemoveIcon } from './styles'
import { Button, Tooltip } from 'antd'

import ArrowLeftDownFillIcon from 'remixicon-react/ArrowLeftDownFillIcon'

interface Props {
  label: string
  icon?: React.ReactNode
  placeholder?: string
  tooltip?: string
  tooltipPosition?: 'title' | 'input'
  style?: React.CSSProperties
  multipleLine?: boolean
  textArea?: boolean
  type?: 'text' | 'password' | 'email' | 'number'
  onChange?: any
  value?: any
  name?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onRemove?: any
  pageState?: 'save' | 'edit'
}

const FormInput = ({
  label,
  icon,
  placeholder,
  tooltip,
  tooltipPosition = 'input',
  style,
  multipleLine,
  textArea,
  type = 'text',
  onChange,
  value,
  name,
  onBlur,
  onRemove,
  pageState
}: Props) => {
  const [inputs, setInputs] = useState<React.ReactNode[]>([])
  const [inputCount, setInputCount] = useState<number>(1)

  const createNewInput = (itemValue?: any) => {
    const newInput = (
      <div key={`key_${inputCount}`} style={{ display: 'flex' }}>
        <AntInput
          onChange={onChange}
          name={`${name}_${inputCount}`}
          size='large'
          placeholder={placeholder}
          prefix={icon}
          style={style}
          onBlur={onBlur}
          value={itemValue ? itemValue : null}
        />
        <InputRemoveIcon onClick={() => removeInput(newInput, `key_${inputCount}`)} color='#d9363e' />
      </div>
    )
    setInputs((prevInputs) => [...prevInputs, newInput])
    setInputCount((prevCount) => prevCount + 1)
  }

  const removeInput = (inputToRemove: React.ReactNode, key: any) => {
    onRemove && onRemove(key)
    setInputs((prevInputs) => prevInputs.filter((input) => input !== inputToRemove))
    setInputCount((prevCount) => prevCount - 1)
  }

  useEffect(() => {
    if (pageState === 'edit' && value && value.length > 0) {
      value.slice(1).map((value: any) => {
        createNewInput(value)
      })
    }
  }, [])

  return (
    <Container>
      <Title>
        <label htmlFor={label}> {label} </label>
        {tooltip && tooltipPosition === 'title' && (
          <Tooltip title={tooltip}>
            <InformationIcon color='var(--icon)' size='1.250rem' />
          </Tooltip>
        )}
      </Title>
      {!textArea ? (
        <AntInput
          size='large'
          placeholder={placeholder}
          prefix={icon}
          id={label}
          type={type}
          name={`${name}`}
          suffix={
            <>
              {tooltip && tooltipPosition === 'input' && !multipleLine && (
                <Tooltip title={tooltip}>
                  <InformationIcon size='1.35rem' />
                </Tooltip>
              )}
              {multipleLine && (
                <AddLineButton onClick={() => createNewInput(null)} type='primary' size='large'>
                  <ArrowLeftDownFillIcon size={20} />
                </AddLineButton>
              )}
            </>
          }
          style={style}
          value={Array.isArray(value) && value.length > 0 ? value[0] : '' || value}
          onChange={onChange}
        />
      ) : (
        <AntTextArea name={name} id={label} placeholder={placeholder} rows={5} maxLength={250} showCount onChange={onChange} value={value} />
      )}
      {multipleLine && inputs.map((input, index) => <React.Fragment key={index}>{input}</React.Fragment>)}
    </Container>
  )
}

export default FormInput
