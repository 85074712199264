import React from 'react'
// import CustomersButton from './components/CustomersButton'
import { AntdButton, Buttons, Container, Title } from './styles'

import ShoppingCartFillIcon from "remixicon-react/ShoppingCartFillIcon"
import ListCheck2Icon from "remixicon-react/ListCheck2Icon"
import SecurePaymentLineIcon from "remixicon-react/SecurePaymentLineIcon"
import RefundLineIcon from "remixicon-react/RefundLineIcon"
import PriceTag2LineIcon from "remixicon-react/PriceTag2LineIcon"
import FileList2LineIcon from "remixicon-react/FileList2LineIcon"
import Message3LineIcon from "remixicon-react/Message3LineIcon"
import AppsLineIcon from "remixicon-react/AppsLineIcon"
import ArrowDownSFillIcon from "remixicon-react/ArrowDownSFillIcon"
import useWindowWidth from '../../../../../../utils/useWindowWidth'

const CustomersTransactions = () => {
  const width = useWindowWidth()
  return (
    <Container>
        <Title>Hızlı {width > 768 ? <br /> : null } İşlemler</Title>
        <Buttons>
        <AntdButton type="primary" style={{background: "#565AE9", borderColor: "#4A4ED1"}}><ShoppingCartFillIcon size={18} /> Müşteri Ekle </AntdButton>
        <AntdButton type="primary" style={{background: "#E6AA00", borderColor: "#CA9600"}}><ListCheck2Icon size={18} /> Teklif Hazırla </AntdButton>
        <AntdButton type="primary" style={{background: "#4CAF50", borderColor: "#439F46"}}><SecurePaymentLineIcon size={18} /> <span>Tahsilat Ödeme <ArrowDownSFillIcon size={16} /></span></AntdButton>
        <AntdButton type="primary" style={{background: "#1890FF", borderColor: "#1583E8"}}><RefundLineIcon size={18} /> Tahsilat İste </AntdButton>
        <AntdButton type="primary" style={{background: "#0065B1", borderColor: "#005696"}}><PriceTag2LineIcon size={18} /> Alış Yap </AntdButton>
        <AntdButton type="primary" style={{background: "#FF5252", borderColor: "#E64A4A"}}><FileList2LineIcon size={18} /> <span>Hesap Ekstresi <ArrowDownSFillIcon size={16} /></span></AntdButton>
        <AntdButton type="primary" style={{background: "#CF80A7", borderColor: "#CF80A7"}}><Message3LineIcon size={18} /> Sms Gönder </AntdButton>
        <AntdButton type="primary" style={{background: "#8A5100", borderColor: "#794700"}}><AppsLineIcon size={18} /> <span>Diğer İşlemler <ArrowDownSFillIcon size={16} /></span></AntdButton>
        </Buttons>
      
    </Container>
  )
}

export default CustomersTransactions
