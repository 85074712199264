import styled from 'styled-components'

export const Container = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  background-color: var(--primary);
  font-size: 1.2rem;
  color: var(--white);
  font-weight: 500;
`
