import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.125rem;
  background-color: var(--white);
  border-top: 0.0625rem solid rgba(var(--black-rgb), 0.1);
  font-size: 0.75rem;
  width: 100%;
  position: sticky;
  top: calc(100vh - 3.125rem);
  color: var(--text-second);
  a {
    color: var(--text-primary);
  }
`

export const Copyright = styled.div`
  padding-left: 1.25rem;
`

export const Version = styled.div`
  padding-right: 1.25rem;

  @media (max-width: 48rem) {
    display: none;
  }
`
