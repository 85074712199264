import styled from 'styled-components'

export const Container = styled.div<{ color: string }>`
  background: ${(props) => `${props.color}1A`};
  border: 1px solid ${(props) => `${props.color}12`};
  color: ${(props) => props.color};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
`

export const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
`

export const Count = styled.div`
  font-size: 32px;
  font-weight: 900;
`

export const Name = styled.div`
  font-size: 12px;
  font-weight: 700;
`
