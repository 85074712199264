import React, { useState } from 'react'
import { useAppDispatch } from '../../../services'
import { login } from '../../../services/authSlice'
import { getCurrentUser } from '../../../services/authSlice/api'
import { useNavigate } from 'react-router-dom'

import { motion } from 'framer-motion'

import {
  Author,
  AuthorRight,
  Comment,
  CommentBottom,
  CommentWrapper,
  Container,
  ImageWrapper,
  LoginForm,
  LoginWrapper,
  SliderWrapper,
  Stars,
  WelcomeDescription,
  WelcomeText,
  InputLabel,
  LoginFormItem,
  LoginFormBottom,
  ForgotPassword,
  RegisterText,
  Logo
} from './styles'

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

import SliderImageOne from '../../../assets/images/login/slider-img-1.jpg'

import StarLineIcon from 'remixicon-react/StarLineIcon'
import StarFillIcon from 'remixicon-react/StarFillIcon'
import { Button, Checkbox, Input } from 'antd'
import GoogleGIcon from '../../../assets/icons/auth/GoogleGIcon'
import { Link } from 'react-router-dom'
import LogoLight from '../../../assets/LogoLight'
import { toast } from 'react-toastify'

const LoginPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = async () => {
    if (!email && !password) {
      return toast.error('🔒️ Lütfen giriş bilgilerinizi girin.')
    }
    if (!email) {
      return toast.error('📧 Lütfen E-posta adresinizi girin.')
    }
    if (!password) {
      return toast.error('🔑 Lütfen Şifrenizi girin.')
    }

    const loginPromise = new Promise(async (resolve, reject) => {
      try {
        const res = await dispatch(login({ email, password }))

        if (res.payload.accessToken) {
          localStorage.setItem('token', JSON.stringify(res.payload.accessToken))
          dispatch(getCurrentUser())
          navigate('/')
          resolve('Giriş başarılı 🎉')
        } else {
          reject('Kullanıcı adı veya şifre hatalı 🤯')
        }
      } catch (error) {
        reject('Kullanıcı adı veya şifre hatalı 🤯')
      }
    })

    toast.promise(loginPromise, {
      pending: 'Giriş yapılıyor...',
      success: 'Giriş başarılı 🎉',
      error: 'Kullanıcı adı veya şifre hatalı 🤯'
    })
  }
  return (
    <Container>
      <LoginWrapper>
        <Logo>
          <LogoLight />
        </Logo>
        <WelcomeText>Hoşgeldiniz</WelcomeText>
        <WelcomeDescription>Muhasebia’ya hoşgeldin. Hemen oturum açın.</WelcomeDescription>

        <LoginForm>
          <LoginFormItem>
            <InputLabel htmlFor='mail'>Eposta Adresi</InputLabel>
            <Input
              size='large'
              type='mail'
              id='mail'
              style={{ marginTop: '8px', height: '46px' }}
              placeholder='ornek@gmail.com'
              onChange={(e) => setEmail(e.target.value)}
              onPressEnter={handleLogin}
            />
          </LoginFormItem>

          <LoginFormItem>
            <InputLabel htmlFor='password'>Şifre</InputLabel>

            <Input.Password
              placeholder='Şifrenizi giriniz'
              size='large'
              style={{ marginTop: '8px', height: '46px' }}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              onChange={(e) => setPassword(e.target.value)}
              onPressEnter={handleLogin}
            />
          </LoginFormItem>
          <LoginFormBottom>
            <Checkbox>Beni 30 gün hatırla</Checkbox>
            <ForgotPassword>Şifreni mi unuttun?</ForgotPassword>
          </LoginFormBottom>

          <Button type='primary' size='large' style={{ height: '46px' }} onClick={handleLogin}>
            {' '}
            Giriş Yap
          </Button>

          <Button
            style={{
              fontSize: '16px',
              color: 'rgba(56, 56, 83, 0.90);',
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '16px',
              borderWidth: '2px',
              height: '46px'
            }}
            icon={<GoogleGIcon />}
            size='large'
          >
            Google ile giriş yap
          </Button>
          <RegisterText>
            Hesabınız yok mu?
            <Link to='/kullanici-kaydi'> Kayıt Ol</Link>
          </RegisterText>
        </LoginForm>
      </LoginWrapper>
      <motion.div
        initial={{ translateX: -600, opacity: 0 }}
        animate={{ translateX: 0, opacity: 1 }}
        exit={{ translateX: 600, opacity: 0 }}
      >
        <SliderWrapper>
          <ImageWrapper>
            <img src={SliderImageOne} alt='' />
          </ImageWrapper>

          <CommentWrapper>
            <Comment>
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer quis velit nec eros semper tempus id id
              ligula.”
            </Comment>

            <CommentBottom>
              <Author>
                <h1>Gökhan Balık</h1>
                <p>İmza Group Veri Teknolojileri A.Ş</p>
                <span>Yönetim Kurulu Başkanı</span>
              </Author>
              <AuthorRight>
                <Stars>
                  <StarFillIcon />
                  <StarFillIcon />
                  <StarFillIcon />
                  <StarFillIcon />
                  <StarLineIcon />
                </Stars>
              </AuthorRight>
            </CommentBottom>
          </CommentWrapper>
        </SliderWrapper>
      </motion.div>
    </Container>
  )
}

export default LoginPage
