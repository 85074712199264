import React from 'react'
import { HeaderItem } from '../styles'
import AppsIcon from 'remixicon-react/AppsFillIcon'
import { Button, Divider, Flex, Space } from 'antd'
import { Dropdown } from 'antd'
import { DropdownTitle, ToolsItem, ToolsTitle } from './styles'
import CalculatorIcon from '../../../../../assets/icons/header/CalculatorIcon'
import ExchangeIcon from '../../../../../assets/icons/header/ExchangeIcon'
import CalendarIcon from '../../../../../assets/icons/header/CalendarIcon'
import TodoIcon from '../../../../../assets/icons/header/TodoIcon'
import NoteIcon from '../../../../../assets/icons/header/NoteIcon'
import ReminderIcon from '../../../../../assets/icons/header/ReminderIcon'

const HeaderAppsMenu = () => {
  const contentStyle: React.CSSProperties = {
    backgroundColor: 'var(--white)',
    borderRadius: '0.5rem',
    boxShadow: '0rem 0.125rem 0.75rem 0rem rgba(0, 0, 0, 0.05)',
    minWidth: '23.75rem'
  }

  return (
    <Dropdown
      dropdownRender={() => (
        <div style={contentStyle}>
          <Divider style={{ margin: 0 }} />
          <Flex align='center' justify='space-between' style={{ padding: '0.75rem 1.25rem' }}>
            <DropdownTitle>Araçlar</DropdownTitle>
          </Flex>

          <Divider style={{ margin: 0, marginBottom: '0.375rem' }} />
          <Flex gap={8} justify='space-between' align='center' style={{ padding: '0.75rem 1.25rem' }}>
            <ToolsItem>
              <CalculatorIcon />
              <ToolsTitle>Hesap Makinesi</ToolsTitle>
            </ToolsItem>
            <ToolsItem>
              <ExchangeIcon />
              <ToolsTitle>Kur Çevirici</ToolsTitle>
            </ToolsItem>
            <ToolsItem>
              <CalendarIcon />
              <ToolsTitle>Takvim</ToolsTitle>
            </ToolsItem>
          </Flex>
          <Flex gap={8} justify='space-between' align='center' style={{ padding: '0rem 1.25rem 0.75rem 1.25rem' }}>
            <ToolsItem>
              <TodoIcon />
              <ToolsTitle>Yapılacaklar</ToolsTitle>
            </ToolsItem>
            <ToolsItem>
              <NoteIcon />
              <ToolsTitle>Notlarım</ToolsTitle>
            </ToolsItem>
            <ToolsItem>
              <ReminderIcon />
              <ToolsTitle>Hatırlatıcı</ToolsTitle>
            </ToolsItem>
          </Flex>

          <Divider style={{ margin: 0 }} />
          <Flex style={{padding: '0.75rem 1.25rem'}}>
          <Button
              type='primary'
              style={{
                minHeight: '2.375rem',
                minWidth: '7.5rem',
                width: '100%',
              }}
            >
              Tümünü Gör{' '}
            </Button>
          </Flex>
         
        </div>
      )}
    >
      <HeaderItem>
        <AppsIcon size="1.25rem" />
      </HeaderItem>
    </Dropdown>
  )
}

export default HeaderAppsMenu
