import React from 'react'
import { Container, Companies, Header, SubTitle, Title } from './styles'
import CompanyCard from '../CompanyCard'
import CompanyCardBlank from '../CompanyCard/blank'
import { useAppSelector } from '../../../../../services'
import { User } from '../../../../../interfaces/user'
import { Subscription } from '../../../../../interfaces/subscription'

const ComapanyContent = () => {

  const user: User = useAppSelector((state) => state.auth.user);
  const subscription: Subscription = useAppSelector((state) => state.subscriptions.subscription);
  
  return (
    <Container>
      <Header>
        <Title>Hesaplar Arasında Geçiş Yap</Title>
        <SubTitle>
          Muhasebia ile hesaplarınız arasında geçiş yapmak çok kolay. <span>Detaylı Bilgi</span>
        </SubTitle>
      </Header>
      <Companies>
        {user.companies?.map((company) => (
          <CompanyCard key={company} companyId={company} />
        ))}
       {subscription.companyToken > 0 && <CompanyCardBlank />}
      </Companies>
    </Container>
  )
}

export default ComapanyContent
