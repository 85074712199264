import React from 'react';
import { Container } from '../../../styles';
import CustomerStatsComponent from './components/product-stats/PrdouctStats';

import PurchasesIcon from '../../../../../../assets/icons/products/alislar.svg';
import SalesIcon from '../../../../../../assets/icons/products/satislar.svg';
import TotalStockIcon from '../../../../../../assets/icons/products/toplam-stok.svg';
import StockValueIcon from '../../../../../../assets/icons/products/stok-degeri.svg';

const statsData = [
  { title: 'Alış Fiyatı', value: '12.345,21 ₺', icon: PurchasesIcon, color: '40, 191, 148' },
  { title: 'Satış Fiyatı', value: '2.945,21 ₺', icon: SalesIcon, color: '86, 90, 233'},
  { title: 'Toplam Stok', value: '1.200 Adet', icon: TotalStockIcon, color: '255, 61, 0'},
  { title: 'Stok Değeri', value: '62.345,11 ₺', icon: StockValueIcon, color: '35, 183, 229'},
];

const CustomerStats = () => {
  return (
    <Container>
      {statsData.map((stats, index) => (
        <CustomerStatsComponent
          key={index}
          title={stats.title}
          value={stats.value}
          icon={stats.icon}
          color={stats.color}
        />
      ))}
    </Container>
  );
}

export default CustomerStats;
