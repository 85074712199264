import { useEffect, useState } from 'react'
import {
  CurrencyText,
  Container,
  Content,
  CurrencyTitle,
  Icon,
  Title,
  CurrencyAmount,
  CurrencyState,
  Graphic,
  Amount
} from '../styles'

import DolarIcon from '../../../../../assets/icons/dashboard/currency/dolar.svg'
import TopIcon from 'remixicon-react/ArrowDropUpFillIcon'
import { Area } from '@ant-design/plots'

const DashboardDolarCurrency = () => {
  const [data, setData] = useState([
    {
      Date: '2023-02-12',
      amount: 41.47
    },
    {
      Date: '2023-02-13',
      amount: 43.3
    },
    {
      Date: '2023-02-14',
      amount: 40.52
    },
    {
      Date: '2023-02-15',
      amount: 40.51
    },
    {
      Date: '2023-02-16',
      amount: 41.47
    },
    {
      Date: '2023-02-17',
      amount: 43.44
    },
    {
      Date: '2024-02-18',
      amount: 46.13
    }
  ])

  const graphicColor = data[data.length - 1].amount > data[data.length - 2].amount ? '#28BF94' : '#FF4D4F'

  const config = {
    data,
    color: graphicColor,
    xField: 'Date',
    yField: 'amount',
    yAxis: {
      min: data.map((item) => item.amount).sort()[0] - 1,
      max: data.map((item) => item.amount).sort()[data.length - 1] + 1,
      label: false,
      grid: null
    },
    renderer: 'svg',
    areaStyle: () => ({
      fill: `l(270) 0:var(--white) 1:${graphicColor}`
    }),
    height: 70,
    xAxis: false,
    tooltip: false,
  }

  return (
    <Container>
      <Title>
        <Icon>
          <img src={DolarIcon} alt='dolar-icon' />
        </Icon>
        <CurrencyTitle>Dolar - USD</CurrencyTitle>
      </Title>

      <Content>
        <CurrencyText>Dolar - Türk Lirası</CurrencyText>
        <Amount>
          <CurrencyAmount color={graphicColor}>{data[data.length -1].amount} ₺</CurrencyAmount>
          <CurrencyState color={graphicColor}>
            {/* <TopIcon size={20} /> <span>%12</span> */}
            <TopIcon size={20} /> <span>{(data[data.length - 1].amount - data[data.length - 2].amount).toFixed(2)}</span>
          </CurrencyState>
        </Amount>
      </Content>

      <Graphic>
        <Area {...config} />
      </Graphic>
    </Container>
  )
}

export default DashboardDolarCurrency
