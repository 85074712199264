import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";

import themeSlice from './theme/themeSlice';
import authSlice from "./authSlice";
import subscriptionSlice from "./subscriptionSlice";
import customersSlice from "./customersSlice";
import suppliersSlice from "./suppliersSlice";
import productsSlice from "./productsSlice";
import variantsSlice from "./variantsSlice";

const store = configureStore({
  reducer: {
    theme: themeSlice,
    auth: authSlice,
    subscriptions: subscriptionSlice,
    customers: customersSlice,
    suppliers: suppliersSlice,
    products: productsSlice,
    variants: variantsSlice,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
