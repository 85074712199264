import { ExclamationCircleFilled } from '@ant-design/icons'
import { Space, Table } from 'antd'
import React, { useState } from 'react'
import type { ColumnsType } from 'antd/es/table'
import { TableActions, TableActionsLink } from '../styles'
import { Modal } from 'antd'

import useWindowWidth from '../../../../../utils/useWindowWidth'
import { Link, useNavigate } from 'react-router-dom'
import currencyList from '../../../../../mocks/enums/currencyList'

const { confirm } = Modal

interface DataType {
  _id: number
  name: string
  salesPrice: number
  salesCurrency: string
  stock: number
}

const ProdcutsListTable = ({ setHasItemSelect, products, setPage, loading, deleteProductHandler }: any) => {
  const width = useWindowWidth()
  const navigate = useNavigate()
  let data = products.data
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>(null)


  console.log(products, 'products')
  const formatMoney = (money: number) => {
    return money.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }

  const getCurrecy = (currency: string) => {
    return currencyList.find((item) => item.value === currency)?.symbol
  }

  console.log(data)

  const handleDeleteButton = (key: any) => {
    setSelectedRowKeys(key)
    showDeleteModal(key)
    data = data.filter((item: any) => item._id.toString() === key)
  }

  const showDeleteModal = (key: any) => {
    confirm({
      title: 'Ürünü Sil',
      icon: <ExclamationCircleFilled />,
      content: 'Seçilen ürünü silmek istediğine emin misin?',
      okText: 'Evet, sil',
      cancelText: 'Vazgeç',

      // remove selectedRowKeys for promise deleteProductLoading
      onOk() {
        return new Promise((resolve, reject) => {
          deleteProductHandler(key)
          setTimeout(resolve, 500)
        })
          .then(() => {
            setSelectedRowKeys(null)
            setHasItemSelect(false)
          })
          .catch(() => console.log('Silme işlemi başarısız oldu'))
      },
      onCancel() {
        setSelectedRowKeys(null)
      }
    })
  }

  const salesPriceColumn =
    width >= 768
      ? [
          {
            title: 'Satış Fiyatı',
            dataIndex: 'salesPrice',
            width: '26%',
            sorter: (a: DataType, b: DataType) => a.salesPrice - b.salesPrice,
            render: (money: number, row:any) => (money ? `${formatMoney(money)} ${getCurrecy(row.salesCurrency)}` : '-')
          }
        ]
      : []

  const stockColumn =
    width >= 768
      ? [
          {
            title: 'Stok Miktarı',
            dataIndex: 'stock',
            width: '26%',
            sorter: (a: DataType, b: DataType) => a.stock - b.stock,
            render: (stock: number) => (stock ? `${stock} adet` : '-')
          }
        ]
      : []

  const actionsColumn =
    width >= 768
      ? [
          {
            title: 'Aksiyonlar',
            dataIndex: '',
            key: 'x',
            render: (row: any) => (
              <Space size='middle'>
                <TableActionsLink to={`/urun/${row._id}`} style={{ fontWeight: '700', color: 'var(--primary)' }}>Detaylar</TableActionsLink>
                <TableActions onClick={() =>
                navigate("/urunler/yeni", {
                  state: {
                    data: row,
                  },
                })
              } >Düzenle</TableActions>
                <TableActionsLink to="#" onClick={() => handleDeleteButton(row._id)} style={{ color: '#FF3D00' }}>
                  Sil
                </TableActionsLink>
              </Space>
            )
          }
        ]
      : []

  const columns: ColumnsType<DataType> = [
    {
      title: 'Ürün / Hizmet Adı',
      dataIndex: 'name',
      width: width >= 768 ? '26%' : '90%',
      render: (text: string, row: any) => <Link to={`/urun/${row._id}`}>{text}</Link>
    },
    ...salesPriceColumn,
    ...stockColumn,
    ...actionsColumn
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setHasItemSelect(selectedRows.length > 0 ? true : false)
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === 'Disabled User',
      name: record.name
    })
  }
  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        ...rowSelection
      }}
      columns={columns}
      rowKey={(record) => record._id}
      dataSource={data}
      loading={loading}
      pagination={{
        total: products.length,
        pageSize: 10,
        onChange: (page: number) => {
          setPage(page)
        }
      }}
      bordered
    />
  )
}

export default ProdcutsListTable
