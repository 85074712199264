import styled from 'styled-components'

const gradientCondition = (primaryColor: any) => {
  return primaryColor === '#565ae9' ? 'linear-gradient(90deg, #676eae 0%, #919bf8 100%)' : `linear-gradient(90deg, var(--primary) 0%, rgba(var(--primary-rgb), 0.62) 100%)`
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--white);
  position: relative;
  padding: 0.25rem;
  border-radius: 0.75rem;
  box-shadow: 0rem 0.125rem 0.125rem 0rem rgba(0, 0, 0, 0.06);
`

export const Header = styled.div<{ themeColor: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => gradientCondition(props.themeColor)};
  border-radius: 0.5625rem 0.5625rem 0.125rem 0.125rem;
  font-family: 'Lato', sans-serif;
  font-size: 0.719rem;
  font-weight: 500;
  color: #fff;
  padding: 0.5rem 0.8125rem;

  b {
    font-weight: 700;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato', sans-serif;
  gap: 0.375rem;
  padding: 0.625rem 0rem;
`
export const Day = styled.div`
  color: var(--primary);
  font-size: 3.125rem;
  font-weight: 900;
`
export const Time = styled.div`
  color: var(--text-second);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.125rem;
`
export const Month = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
`
export const DayName = styled.div`
  font-size: 0.875rem;
  font-weight: 300;
`

export const Detail = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 0.25rem;
  padding: 0.625rem 0.25rem;
`
export const DetailItem = styled.div`
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
`
export const DetailTitle = styled.div`
  background: rgba(var(--black-rgb), 0.05);
  border-radius: 0.1875rem;
  padding: 0.375rem 0.75rem;
  color: rgba(var(--black-rgb), 0.5);
  width: max-content;
  font-size: 0.5rem;
  font-weight: 600;
`
export const DetailCount = styled.div`
  color: var(--text-second);

  font-size: 0.875rem;
  font-weight: 700;
`
