import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 12px;
  border: 1px solid rgba(var(--black-rgb), 0.07);
  font-family: 'Roboto', sans-serif;

  .ant-tabs {
    width: 100%;
  }

  .ant-tabs-nav-wrap {
    width: 100%;
    display: flex !important;
  }

  .ant-tabs-nav-list {
    width: 100%;
    display: flex !important;
    justify-content: space-around;

    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: 0;
    }
  }
  .ant-tabs-tab {
    @media (max-width: 768px) {
      margin: 0 !important;
    }
  }
  .ant-tabs-tab-btn {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;
    font-weight: 600;

    @media (max-width: 768px) {
      justify-content: center;
      font-size: 14px;
    }
  }

  .ant-tabs-tab-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-table-cell {
    width: max-content;
  }
`
