import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import './assets/styles/reset.css'
import { GlobalStyles, darkTheme, lightTheme } from './assets/styles/global'
import "react-toastify/dist/ReactToastify.css";
import 'react-loading-skeleton/dist/skeleton.css'

import Turkish from 'antd/lib/locale/tr_TR'

import { Provider, useSelector } from 'react-redux'
import store from './services'

import { theme } from 'antd'
import { ToastContainer } from 'react-toastify'


const ThemeProvider = () => {
  const themeColor = useSelector((state: any) => state.theme.themeColor)
  const themeRgbColor = useSelector((state: any) => state.theme.themeRgbColor)
  const themeMode = useSelector((state: any) => state.theme.themeMode)
  const themeFontSize = useSelector((state: any) => state.theme.themeFontSize)

  const { defaultAlgorithm, darkAlgorithm } = theme

  const selectedTheme = themeMode === 'light' ? lightTheme : darkTheme

  return (
    <ConfigProvider
      locale={Turkish}
      theme={{
        token: { colorPrimary: themeColor, colorBorder: 'rgba(0, 0, 0, 0.07)' },
        algorithm: themeMode === 'light' ? defaultAlgorithm : darkAlgorithm,
        components:{
          Tabs:{
            cardBg: '#F3F3F3',
            cardHeight: 62,
          }
        }
      }}
    >
      <GlobalStyles theme={{ themeColor, themeRgbColor, themeFontSize, selectedTheme }} />
      <App />
      <ToastContainer theme="colored" />
    </ConfigProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider />
    </Provider>
  </BrowserRouter>
)
