import styled from 'styled-components'

import PremiumBg from '../../../../../assets/images/dashboard/premium-bg.png'
import PremiumBgClose from '../../../../../assets/images/dashboard/premium-bg-close.png'

interface ContainerProps {
  menuState: boolean
  onClick?: any
}

export const UpgradePremium = styled.div<ContainerProps>`
  background-image: ${(props) => (props.menuState ? `url(${PremiumBgClose})` : `url(${PremiumBg})`)};
  height: 5.3125rem;
  border-radius: ${(props) => (props.menuState ? '0rem' : '0.625rem')};
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: ${(props) => (props.menuState ? '0rem' : '1.5rem')};
  margin-right: ${(props) => (props.menuState ? '0rem' : '1.5rem')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  font-family: 'Lato', sans-serif;
  cursor: pointer;
  user-select: none;
  margin-top: 2.25rem;
  color: #fff;

  p {
    font-size: 0.875rem;
    font-weight: 600;
    width: max-content;
  }

  b {
    font-size: ${(props) => (props.menuState ? '1.375rem' : '1.625rem')};
    font-weight: 700;
    margin-top: 0.125rem;
    width: max-content;
  }

  span {
    font-size: 0.75rem;
    width: max-content;
  }
`
