import React from "react";
import { Link } from "react-router-dom";
import { Container, Copyright, Version } from "./styles";

const Footer = () => {
  return (
    <Container>
      <Copyright>
        © 2024 - Tüm Hakları Saklıdır <a href="https://muhasebia.com" style={{ fontWeight: 500 }}>Muhasebia</a>
      </Copyright>

      <Version>
        Versiyon: <Link to="/degisiklilk-gunlugu" style={{ fontWeight: 500 }}>Beta 0.0.1</Link>
      </Version>
    </Container>
  );
};

export default Footer;
