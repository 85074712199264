import React from 'react'
import {
  Button,
  ButtonIcon,
  ButtonText,
  Buttons,
  Container,
  Muhasebia,
  Texts,
  WelcomeDescription,
  WelcomeText
} from './styled'

import AddCircleLineIcon from 'remixicon-react/AddCircleLineIcon'

const DashboardWelcomeComponent = ({fullName, customers}: any) => {
  return (
    <Container>
      <Texts>
        <Muhasebia>Muhasebia Dünyamıza</Muhasebia>
        <WelcomeText>
          Hoşgeldin, <b>{fullName?.split(' ')[0]}</b>
        </WelcomeText>
        <WelcomeDescription>
          Kullanım için hemen <u>tura</u> başlayabiliriz veya daha detaylı öğrenmek için <u>dökümantasyonu</u> okuyabiliriz.
        </WelcomeDescription>
      </Texts>

      <Buttons>
        <Button to='/musteriler/yeni'>
          <ButtonIcon>
            <AddCircleLineIcon />
          </ButtonIcon>
          {
            customers > 0 ? (
              <ButtonText>İlk Tedarikçimizi Ekleyelim</ButtonText>
            ) : (
              <ButtonText>İlk Müşterimizi Ekleyelim</ButtonText>
            )
          }
        </Button>
        <Button to='/satislar/yeni'>
          <ButtonIcon>
            <AddCircleLineIcon />
          </ButtonIcon>
          <ButtonText>İlk Satışlarımızı Yapalım</ButtonText>
        </Button>
        <Button to='/efatura-basvuru'>
          <ButtonIcon>
            <AddCircleLineIcon />
          </ButtonIcon>
          <ButtonText>E-Faturaya Başvuralım</ButtonText>
        </Button>
      </Buttons>
    </Container>
  )
}

export default DashboardWelcomeComponent
