import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const TableActionsLink = styled(Link)`
  transition: all 0.4s;
  &:hover {
    opacity: 0.7;
  }
`
export const TableActions = styled.div`
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    color: #1890ff;
    opacity: 0.7;
  }
`