import { useState } from 'react'
import { Col, Row, Tooltip, Tag, Modal, Input, ColorPicker, Flex } from 'antd'
import { AddLabelButton, AntSelect, AntTreeSelect, Container, ItemContainer } from './styles'
import { InformationIcon, Title } from '../../../../../components/input/styles'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'

import ListCheck2Icon from 'remixicon-react/ListCheck2Icon'
import FormInput from '../../../../../components/input'
import AddCircleLineIcon from 'remixicon-react/AddCircleLineIcon'
import HashtagIcon from 'remixicon-react/HashtagIcon'
import type { Color, ColorPickerProps } from 'antd/es/color-picker'
import useWindowWidth from '../../../../../utils/useWindowWidth'

const treeData = [
  {
    title: 'Çalışan1',
    value: '1',
    key: '1'
  },
  {
    title: 'Çalışan2',
    value: '2',
    key: '2'
  }
]

const tagRender = (props: CustomTagProps) => {
  const { label, value, closable, onClose } = props
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  )
}

const SuppliersOtherInformationTab = ({setData}: any) => {
  const width = useWindowWidth()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [classificationName, setClassificationName] = useState('')
  const [classificationColor, setClassificationColor] = useState('#1677ff')

  const [formatHex, setFormatHex] = useState<ColorPickerProps['format']>('hex')

  const [options, setOptions] = useState([
    { value: '#8b7700cf', label: 'E-Ticaret' },
    { value: '#007c00cf', label: 'Yakın Çevre' },
    { value: '#80001ccf', label: 'İş' },
    { value: '#008383cf', label: 'Etiket adı' }
  ])

  const handleColorChange = (color: any) => {
    setClassificationColor(color.toHexString())
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setOptions([...options, { value: classificationColor, label: classificationName }])
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Container>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Sınıflandırma Ekle
              <Tooltip
                title={
                  <>
                    Müşterilerinizi raporlarken gruplama amacıyla kullanabileceğiniz alandır. Burada gözüken değerleri{' '}
                    <a href='#'>
                      <b>"Ayarlar&gt;Tanımlar"</b>
                    </a>{' '}
                    menüsünden dilediğiniz gibi değiştirebilirsiniz.
                  </>
                }
                getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
              >
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <AntSelect mode='multiple' tagRender={tagRender} style={{ width: '100%' }} options={options} size='large' />
            <ListCheck2Icon color='var(--icon)' style={{ position: 'absolute', left: 22, bottom: 12 }} />
            <AddLabelButton
              type='primary'
              size='large'
              style={{ position: 'absolute', right: 12, bottom: 8 }}
              onClick={showModal}
            >
              <AddCircleLineIcon size={20} />
            </AddLabelButton>
            <Modal
              title='Yeni Sınıflandırma Ekle'
              open={isModalOpen}
              onOk={handleOk}
              okText='Kaydet'
              onCancel={handleCancel}
              cancelText='İptal'
              maskClosable={false}
            >
              <Row
                style={{ marginTop: '1rem', marginBottom: '1.2rem' }}
                align='middle'
                justify='space-between'
                gutter={[16, 0]}
              >
                <Col span={width < 768 ? 24 : 12}>
                  <Input
                    onChange={(e) => setClassificationName(e.target.value)}
                    placeholder='Sınıflandırma adı'
                    size='large'
                  />
                </Col>
                <Col
                  span={width < 768 ? 24 : 12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: width < 768 ? 'flex-start' : 'flex-end',
                    marginTop: width < 768 ? '1rem' : '0rem'
                  }}
                >
                  <ColorPicker
                    format={formatHex}
                    value={classificationColor}
                    onChange={handleColorChange}
                    onFormatChange={setFormatHex}
                    size='large'
                    showText={(color) => <span>Etiket Rengi ({color.toHexString()})</span>}
                  />
                </Col>
              </Row>
            </Modal>
          </ItemContainer>
        </Col>
        <Col span={24}>
          <ItemContainer>
            <Title>
              Yetkili Çalışanlar
              <Tooltip title='Dilerseniz bu müşteriyi sadece seçtiğiniz çalışanların görmesini sağlayabilirsiniz.'>
                <InformationIcon size='1.35rem' color='var(--icon)' />
              </Tooltip>
            </Title>
            <AntTreeSelect
              treeData={treeData}
              treeCheckable='true'
              placeholder='Hangi çalışanlar görebilsin? Tümü için boş bırakın.'
            />
          </ItemContainer>
        </Col>
      </Row>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <FormInput
            label='Firma Kodu'
            icon={<HashtagIcon size='1.5rem' />}
            placeholder='Varsa Muhasebe & Barkod kodu'
            tooltip='Müşterinizin eskiden verdiğiniz bir kodu varsa onu girebilir veya barkod verebilirsiniz.'
            tooltipPosition='input'
            name="accountingCode"
            onChange={setData}
          />
        </Col>
        <Col span={24}>
          <FormInput
            label='Müşteri Notu'
            icon={<HashtagIcon size='1.5rem' />}
            placeholder='Müşteri notunu giriniz...'
            textArea={true}
            name="note"
            onChange={setData}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default SuppliersOtherInformationTab
