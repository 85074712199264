import styled from 'styled-components'

import Background from '../../../assets/images/company/change-company-bg.jpg'

export const Container = styled.div`
  font-family: 'Roboto', sans-serif;
  background-image: url(${Background});
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    height: auto;
  }
`

export const Content = styled.div`
  border-radius: 24px;
  background: var(--White, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  min-height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    border-radius: 0px;
  }
`
