import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`

export const DropdownTitle = styled.p`
  font-size: 1.125rem;
  color: var(--text-second);
  font-weight: 700;
`

export const NotificationCount = styled.div`
  font-size: 0.625rem;
  color: #23b7e5;
  font-weight: 800;
  background-color: #ecf7fd;
  padding: 0.25rem 0.5rem;
  border-radius: 0.125rem;
  cursor: pointer;
`

export const Notification = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1.0625rem 0.9375rem;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const Icon = styled.div<{ color: any }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => `${props.color}1A`};
  color: ${(props) => props.color};
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 624.9375rem;
`

export const Texts = styled.div<{ color: any }>`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;

  p {
    color: var(--text-primary);
    font-size: 0.8125rem;
    font-weight: 600;
    b {
      color: ${(props) => props.color};
    }
  }
  span {
    font-size: 0.6875rem;
    color: var(--icon);

    b {
      color: ${(props) => props.color};
      font-weight: 500;
    }
  }
`

export const CloseButton = styled.div`
  display: flex;
  color: var(--icon);
  cursor: pointer;
`
