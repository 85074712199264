import Layout from '../../components/layout'
import { Col, Row } from 'antd'
import DashboardCalendarComponent from './components/calendar'
import DashboardWelcomeComponent from './components/welcome'
import DashboardTurnoverComponent from './components/turnover'
import DashboardExpenseComponent from './components/expense'
import DashboardStockComponent from './components/stock'
import DashboardBox from '../../components/dashboard/box'
import DashboardAsetsComponent from './components/assets'
import DashboardDebtsComponent from './components/debts'
import DashobardUpcomingExpenseComponent from './components/upcoming-expenses'
import DashobardUpcomingLoanPaymentsComponent from './components/upcoming-loan-payments'
import DashobardLatestActivityComponent from './components/latest-activity'
import DashboardDolarCurrency from './components/currency/dolar'
import DashboardEuroCurrency from './components/currency/euro'
import Skeleton from 'react-loading-skeleton'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../services'
import { getCurrentCompany } from '../../services/authSlice'

const DashboardPage = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  const user = useAppSelector((state) => state.auth.user)
  const company = useAppSelector((state) => state.auth.company)
  useEffect(() => {
    dispatch(getCurrentCompany())
  }
  , [dispatch])
  return (
    <Layout>
      <Row justify='space-between' gutter={16}>
        <Col span={24} xl={{ span: 13 }}>
          <Row gutter={16}>
            <Col span={24} xl={{ span: 8 }}>
              {!loading ? <DashboardCalendarComponent /> : <Skeleton width='100%' height='172px' />}
            </Col>
            <Col span={24} xl={{ span: 8 }}>
              {!loading ? <DashboardDolarCurrency /> : <Skeleton width='100%' height='172px' />}
            </Col>
            <Col span={24} xl={{ span: 8 }}>
              {!loading ? <DashboardEuroCurrency /> : <Skeleton width='100%' height='172px' />}
            </Col>
            <Col span={24} xl={{ span: 8 }}>
              {!loading ? (
                <DashboardTurnoverComponent />
              ) : (
                <Skeleton style={{ marginTop: 30 }} width='100%' height='144px' />
              )}
            </Col>
            <Col span={24} xl={{ span: 8 }}>
              {!loading ? (
                <DashboardExpenseComponent />
              ) : (
                <Skeleton style={{ marginTop: 30 }} width='100%' height='144px' />
              )}
            </Col>
            <Col span={24} xl={{ span: 8 }}>
              {!loading ? (
                <DashboardStockComponent />
              ) : (
                <Skeleton style={{ marginTop: 30 }} width='100%' height='144px' />
              )}
            </Col>
          </Row>
        </Col>
        <Col span={0} lg={{ span: 11 }}>
          <Row gutter={16}>
            <Col span={0} lg={{ span: 24 }}>
              {!loading ? <DashboardWelcomeComponent fullName={user.fullName} customers={company?.customers?.length} /> : <Skeleton width='100%' height='346px' />}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify='space-between' gutter={16}>
        <Col span={24} xl={{ span: 17 }}>
          <Row justify='space-between' gutter={16}>
            <Col span={24} xl={{ span: 12 }}>
              {!loading ? (
                <DashboardBox title='Varlıklarım' link='#'>
                  <DashboardAsetsComponent />
                </DashboardBox>
              ) : (
                <Skeleton style={{ marginTop: 26 }} width='100%' height='371px' />
              )}
            </Col>
            <Col span={24} xl={{ span: 12 }}>
              {!loading ? (
                <DashboardBox title='Borçlarım' link='#'>
                  <DashboardDebtsComponent />
                </DashboardBox>
              ) : (
                <Skeleton style={{ marginTop: 26 }} width='100%' height='371px' />
              )}
            </Col>
            <Col span={24} xl={{ span: 12 }}>
              <DashboardBox title='Vadesi Geçen Açık Hesap Alacaklar'>İçerik Gelecek</DashboardBox>
            </Col>
            <Col span={24} xl={{ span: 12 }}>
              <DashboardBox title='Vadesi Geçen Açık Hesap Alacaklar'>İçerik Gelecek</DashboardBox>
            </Col>
            <Col span={24} xl={{ span: 12 }}>
              <DashboardBox title='Vadesi Geçen Açık Hesap Alacaklar'>İçerik Gelecek</DashboardBox>
            </Col>
            <Col span={24} xl={{ span: 12 }}>
              <DashboardBox title='Vadesi Geçen Açık Hesap Alacaklar'>İçerik Gelecek</DashboardBox>
            </Col>
          </Row>
        </Col>
        <Col span={24} xl={{ span: 7 }}>
          <Row gutter={16}>
            <Col span={24} xl={{ span: 24 }}>
              {!loading ? (
                <DashboardBox title='Yaklaşan Masraflarım' link='#' bottomButton linkText='Tüm Masraflarımızı Gör'>
                  <DashobardUpcomingExpenseComponent />
                </DashboardBox>
              ) : (
                <Skeleton style={{ marginTop: 26 }} width='100%' height='453px' />
              )}
            </Col>
            <Col span={24} xl={{ span: 24 }}>
              <DashboardBox title='Yaklaşan Kredi Ödemelerim' link='#' bottomButton linkText='Tüm Kredilerimizi Gör'>
                <DashobardUpcomingLoanPaymentsComponent />
              </DashboardBox>
            </Col>
            <Col span={24} xl={{ span: 24 }}>
              <DashboardBox title='Son İşlemlerimiz' link='#' bottomButton linkText='Tüm İşlemlerimizi Gör'>
                <DashobardLatestActivityComponent />
              </DashboardBox>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default DashboardPage
