import React from 'react'
import { Container, Count, Name, Title } from './style'
import useNumberFormatter from '../../../../utils/useNumberFormatter'

const ProfileSummary = (props: any) => {
  return (
    <Container color={props.color}>
      <Title>TOPLAM</Title>
      <Count>{useNumberFormatter(props.count)}</Count>
      <Name>{props.name}</Name>
    </Container>
  )
}

export default ProfileSummary