import React from "react";

function DiscountIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.25rem"
      height="1.25rem"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        stroke="#23B7E5"
        strokeWidth="1.5"
        d="M6.841 1.518a1.555 1.555 0 012.318 0l.544.608a1.555 1.555 0 001.245.516l.817-.046a1.555 1.555 0 011.638 1.64l-.045.816a1.556 1.556 0 00.516 1.244l.608.544a1.556 1.556 0 010 2.319l-.608.544a1.555 1.555 0 00-.516 1.245l.045.816a1.555 1.555 0 01-1.639 1.64l-.816-.046a1.556 1.556 0 00-1.244.516l-.544.608a1.555 1.555 0 01-2.319 0l-.544-.608a1.556 1.556 0 00-1.245-.516l-.817.045a1.555 1.555 0 01-1.638-1.64l.045-.815a1.556 1.556 0 00-.516-1.244l-.608-.545a1.555 1.555 0 010-2.318l.608-.544a1.555 1.555 0 00.516-1.245l-.045-.817a1.555 1.555 0 011.64-1.638l.815.045a1.555 1.555 0 001.244-.515l.545-.608v-.001z"
      ></path>
      <path
        stroke="#23B7E5"
        strokeLinejoin="round"
        strokeWidth="2.25"
        d="M6.056 6.056h.008v.007h-.008v-.007zm3.889 3.888h.007v.008h-.007v-.008z"
      ></path>
      <path
        stroke="#23B7E5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.333 5.667l-4.666 4.666"
      ></path>
    </svg>
  );
}

export default DiscountIcon;
