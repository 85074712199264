import React from 'react'
import { Container, StatusCreditor, StatusDebtor, Title, Value } from './styles'

interface CustomerStatsComponentProps {
  title: string
  value: string
  icon: string
  color: string
  status?: 'debtor' | 'creditor'
}

const SupplierStatsComponent = ({ title, value, icon, color, status }: CustomerStatsComponentProps) => {
  return (
    <Container color={color} background={icon}>
      <Title>{title}</Title>
      <Value>{value}</Value>

      {status &&
        (status === 'debtor' ? <StatusDebtor>Alacaklı</StatusDebtor> : <StatusCreditor>Borçlu</StatusCreditor>)}
    </Container>
  )
}

export default SupplierStatsComponent
