import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
`

export const ItemContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.4375rem;
  width: 100%;
  
  .ant-alert-message {
    color: #4b84a8;
    font-size: 1rem;
    font-weight: 700;
  }

  .ant-alert-description {
    color: #4b84a8;
    font-size: 0.875rem;
    font-weight: 400;
  }
`