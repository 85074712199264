import React, { useEffect } from 'react'
import { UpgradePremium } from './style'
import { useAppDispatch, useAppSelector } from '../../../../../services'
import { getSubscriptionInfo } from '../../../../../services/subscriptionSlice/api'
import { motion } from 'framer-motion'
import Skeleton from 'react-loading-skeleton'

const SidebarSubscriptionBox = ({ menuState }: any) => {
  const dispatch = useAppDispatch()
  const subscription = useAppSelector((state: any) => state.subscriptions.subscription)

  useEffect(() => {
    dispatch(getSubscriptionInfo())
  }, [dispatch])

  const remainingDaysCalculator = (endDate: any, startDate: any) => {
    const remainingDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24))
    return remainingDays
  }

  const remainingTrialDay = remainingDaysCalculator(
    new Date(subscription.trialEndDate).getTime(),
    new Date(subscription.trialStartDate).getTime()
  )
  const remainingSubscriptionDay = remainingDaysCalculator(
    new Date(subscription.subscriptionEndDate).getTime(),
    new Date(subscription.subscriptionStartDate).getTime()
  )

  return subscription.type === 'trial' ? (
    <UpgradePremium menuState={menuState}>
      {remainingTrialDay > 0 ? (
        <React.Fragment>
          {!menuState && (
            <motion.p
              initial={{ opacity: 0, translateY: 10 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: -10 }}
              transition={{ duration: 0.5 }}
            >
              Planınızı yükseltin
            </motion.p>
          )}
          <b> {remainingTrialDay} Gün</b>
          {!menuState && (
            <motion.span
              initial={{ opacity: 0, translateY: -10 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: 10 }}
              transition={{ duration: 0.5 }}
            >
              Deneme Süreniz Kaldı
            </motion.span>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {!menuState && (
            <motion.p
              initial={{ opacity: 0, translateY: 10 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: -10 }}
              transition={{ duration: 0.5 }}
            >
              Deneme Süreniz
            </motion.p>
          )}
          {Object.keys(subscription).length > 0 ? (
            <b>Bitti</b>
          ) : (
            <Skeleton width={100} height={25} baseColor='#6346cc' />
          )}
          {!menuState && (
            <motion.span
              initial={{ opacity: 0, translateY: -10 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: 10 }}
              transition={{ duration: 0.5 }}
            >
              Abonelik Satın Almalısın
            </motion.span>
          )}
        </React.Fragment>
      )}
    </UpgradePremium>
  ) : (
    <UpgradePremium menuState={menuState}>
      {remainingSubscriptionDay > 0 ? (
        <React.Fragment>
          {!menuState && (
            <motion.p
              initial={{ opacity: 0, translateY: 10 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: -10 }}
              transition={{ duration: 0.5 }}
            >
              Planınızı yükseltin
            </motion.p>
          )}
          <b> {remainingSubscriptionDay} Gün</b>
          {!menuState && (
            <motion.span
              initial={{ opacity: 0, translateY: -10 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: 10 }}
              transition={{ duration: 0.5 }}
            >
              Aboneliğiniz Kaldı
            </motion.span>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {!menuState && (
            <motion.p
              initial={{ opacity: 0, translateY: 10 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: -10 }}
              transition={{ duration: 0.5 }}
            >
              {Object.keys(subscription).length > 0 ? (
                <p>Aboneliğiniz</p>
              ) : (
                <Skeleton width={100} height={22} baseColor='#6346cc' />
              )}
            </motion.p>
          )}
          {Object.keys(subscription).length > 0 ? (
            <b>Bitti</b>
          ) : (
            <Skeleton width={100} height={25} baseColor='#6346cc' />
          )}

          {!menuState && (
            <motion.span
              initial={{ opacity: 0, translateY: -10 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: 10 }}
              transition={{ duration: 0.5 }}
            >
              {Object.keys(subscription).length > 0 ? (
                <span>Abonelik Satın Almalısın</span>
              ) : (
                <Skeleton width={100} height={22} baseColor='#6346cc' />
              )}
            </motion.span>
          )}
        </React.Fragment>
      )}
    </UpgradePremium>
  )
}

export default SidebarSubscriptionBox
