import React, { useEffect } from 'react'
import Layout from '../../../components/layout'
import { useParams } from 'react-router-dom'
import { Container, StickyLeft, Right, Link } from './styles'
import SupplierStats from './components/Right/Stats'
import SuppliersTransactions from './components/Right/Transactions'
import SupplierDetailTables from './components/Right/Tables'
import { useAppDispatch, useAppSelector } from '../../../services'
import { getSupplierById } from '../../../services/suppliersSlice/api'
import DetailLeft from '../../../components/detail/DetailLeft'

import UserFillIcon from "remixicon-react/UserFillIcon";
import PhoneFillIcon from "remixicon-react/PhoneFillIcon";
import MailFillIcon from "remixicon-react/MailFillIcon";
import UserStarFillIcon from "remixicon-react/UserStarFillIcon";
import { Tooltip } from 'antd'
import Skeleton from 'react-loading-skeleton'
import DetailFooter from '../../../components/detail/DetailFooter'

import FileAddFillIcon from 'remixicon-react/FileAddFillIcon'
import Building2FillIcon from 'remixicon-react/Building2FillIcon'
import DetailTitle from '../../../components/detail/DetailTitle'

interface SupplierDataItem {
  icon: JSX.Element;
  label: string;
  content: JSX.Element | null;
}

const SuppliersDetailPage = () => {
  const supplierId = useParams<{ id: string }>().id || ''
  const dispatch = useAppDispatch()
  const {_id, name, authPerson, phone, mail, trIdNumber} = useAppSelector((state) => state.suppliers.supplier)

  useEffect(() => {
    dispatch(getSupplierById(supplierId))
  }
  , [dispatch, supplierId])


  const supplierData: SupplierDataItem[] = [];
  const loading = useAppSelector((state) => state.suppliers.loading);

  const pushInfo = (icon: JSX.Element, label: string, content: JSX.Element | null) => {
    if (content !== undefined && content !== null) {
      supplierData.push({ icon, label, content });
    }else if(loading){
      supplierData.push({ icon, label, content: <Skeleton width={200} /> });
    }
  };
  
  pushInfo(<UserFillIcon size={18} />, "Adı Soyadı", authPerson ? <span>{authPerson}</span> : null);
  pushInfo(<MailFillIcon size={18} />, "Eposta Adresi", mail && mail.length > 0 ? (mail.length === 1 ? (<Link href={`mailto:${mail[0]}`}>{mail[0]}</Link>) : (<Tooltip title={mail.map((p: string) => (<div key={p}><Link style={{ color: "white" }} href={`mailto:${p}`}>{p}</Link></div>))}><Link href={`mailto:${mail[0]}`}>{mail[0]}</Link></Tooltip>)) : null);  
  pushInfo(<PhoneFillIcon size={18} />, "Telefon", phone && phone.length > 0 ? (phone.length === 1 ? (<Link href={`tel:${phone[0]}`}>{phone[0]}</Link>) : (<Tooltip title={phone.map((p: string) => (<div key={p}><Link style={{ color: "white" }} href={`tel:${p}`}>{p}</Link></div>))}><Link href={`tel:${phone[0]}`}>{phone[0]}</Link></Tooltip>)) : null);
  pushInfo(<UserStarFillIcon size={18} />, "Vergi D. / TC", trIdNumber ? <span>{trIdNumber}</span> : null);

  const footerData = [
    {
      icon: <FileAddFillIcon size={20} />,
      label: "Yeni Not Ekle",
      style: { background: '#565AE9' }
    },
    {
      icon: <Building2FillIcon size={20} />,
      label: "Şubeleri Yönet",
      style: { background: '#FF3D00' }
    }
  ]

  return (
    <Layout>
      <Container>
        <StickyLeft>
          <DetailTitle name={name} id={_id} />
          <DetailLeft data={supplierData} />
          <DetailFooter data={footerData} />
        </StickyLeft>
        <Right>
          <SupplierStats />
          <SuppliersTransactions />
          <SupplierDetailTables />
        </Right>
      </Container>
    </Layout>
  )
}

export default SuppliersDetailPage
