import { useEffect, useState } from 'react'
import CustomersDetailedListTable from './detailed-list'
import CustomersTableFilter from './filter'
import CustomersListTable from './list'
import CustomersSelectedItemActions from './filter/components/selected-actions'
import { AnimatePresence, motion } from 'framer-motion'
import CustomersGridTable from './grid'
import { useAppDispatch, useAppSelector } from '../../../../services'
import { getCustomers } from '../../../../services/customersSlice'
import { deleteCustomer } from '../../../../services/customersSlice/api'

const CustomersTable = ({customers}: any) => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector((state) => state.customers.loading)
  const [page, setPage] = useState(1)

  const [tableViewType, setTableViewType] = useState('list')
  const [hasItemSelect, setHasItemSelect] = useState(false)

  useEffect(() => {
    dispatch(getCustomers({ page: page }))
  }, [page, dispatch])

  // tablolar arası geçişte sayfa numarasını sıfırlıyoruz
  useEffect(() => {setPage(1)}, [tableViewType])

  // müşteriyi silerken bekliyoruz sonra tekrardan çekiyoruz listeden kaybolması için
  const deleteCustomerHandler = async (customerId: string) => {
    await dispatch(deleteCustomer(customerId))
    dispatch(getCustomers({ page: page }))
  }

  return (
    <>
      <AnimatePresence>
        <div style={{ position: 'relative' }}>
          {hasItemSelect ? (
            <motion.div
              initial={{ opacity: 0, position: 'absolute', left: -24 }}
              key='CustomersSelectedItemActions'
              animate={{ opacity: 1, position: 'relative', left: 0 }}
              exit={{ opacity: 0, position: 'absolute', left: -24 }}
              transition={{ duration: 0.3 }}
            >
              <CustomersSelectedItemActions />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0, position: 'absolute', right: -24 }}
              key='CustomersTableFilter'
              animate={{ opacity: 1, position: 'relative', right: 0 }}
              exit={{ opacity: 0, position: 'absolute', right: -24 }}
            >
              <CustomersTableFilter tableViewType={tableViewType} setTableViewType={setTableViewType} />
            </motion.div>
          )}
        </div>
      </AnimatePresence>

      {tableViewType === 'list' && (
        <CustomersListTable
          setHasItemSelect={setHasItemSelect}
          customers={customers}
          setPage={setPage}
          loading={loading}
          deleteCustomerHandler={deleteCustomerHandler}
        />
      )}
      {tableViewType === 'detailed' && <CustomersDetailedListTable setHasItemSelect={setHasItemSelect} />}
      {tableViewType === 'grid' && <CustomersGridTable setPage={setPage} loading={loading} customers={customers} />}
    </>
  )
}

export default CustomersTable
