import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpRequest, RequestType } from '../../plugins/httpRequest'
import { Variants } from '../../interfaces/variants'

export const getVariants = createAsyncThunk(
  'getVariants',
  async () => {
    const response = await HttpRequest<null, Variants>({
      url: 'variants',
      method: RequestType.GET,
    })
    return response
  }
)

export const createVariant = createAsyncThunk('createVariant', async function (variant: Variants) {
  const response = await HttpRequest({
    url: 'variants',
    method: RequestType.POST,
    body: variant
  })
  return response
})

export const updateVariant = createAsyncThunk('updateVariant', async function ({ variantId, variant }: { variantId: string; variant: Variants }) {
  const response = await HttpRequest({
    url: `variants/${variantId}`,
    method: RequestType.PATCH,
    body: variant
  })
  return response
})

export const updateVariantValue = createAsyncThunk('updateVariantValue', async function ({ variantId, variantValue }: { variantId: string; variantValue: any }) {
  console.log(variantId, 'variantId')
  const response = await HttpRequest({
    url: `variants/${variantId}`,
    method: RequestType.PATCH,
    body: { variantValues: variantValue }
  })
  return response
})

export const deleteVariant = createAsyncThunk('deleteVariant', async function (variantId: string) {
  const response = await HttpRequest({
    url: `variants/${variantId}`,
    method: RequestType.DELETE
  })
  return response
})
