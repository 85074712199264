import React, { useState } from 'react'
import { Badge, Button, Divider, Dropdown, Flex } from 'antd'
import { HeaderItem } from '../styles'
import {
  CloseButton,
  Content,
  DropdownTitle,
  Icon,
  Notification,
  NotificationCount,
  Texts,
} from './styles'
import NotificationIcon from 'remixicon-react/Notification2FillIcon'
import GiftIcon from 'remixicon-react/GiftLineIcon'
import DiscountIcon from '../../../../../assets/icons/header/DiscountIcon'
import ShieldLineIcon from 'remixicon-react/ShieldLineIcon'
import TimeLineIcon from 'remixicon-react/TimeLineIcon'
import CheckboxCircleLineIcon from 'remixicon-react/CheckboxCircleLineIcon'
import CloseIcon from 'remixicon-react/CloseFillIcon'
import { AnimatePresence, motion } from 'framer-motion'

const notificationsData = [
  {
    color: '#845ADE',
    icon: <GiftIcon size="1.25rem" />,
    title: 'Sana bir hediyemiz var',
    content: '<b>1 Yıllık</b> paket alana <b>2 Ay</b> bizden hediye.'
  },
  {
    color: '#23B7E5',
    icon: <DiscountIcon />,
    title: 'Sana Özel İndirim',
    content: 'Sana özel <b>%10</b> indirimimiz mevcut'
  },
  {
    color: '#E791BC',
    icon: <ShieldLineIcon size="1.25rem" />,
    title: 'Güvenlik Güncelleştirmesi',
    content: '1 Yıllık paket alana 2 Ay bizden hediye.'
  },
  {
    color: '#F5B74A',
    icon: <TimeLineIcon size="1.25rem" />,
    title: 'İşleme Alındı <b>ID: #11643211</b>',
    content: 'Bildirim ile alakalı mesaj gelecek'
  },
  {
    color: '#28BF94',
    icon: <CheckboxCircleLineIcon size="1.25rem" />,
    title: 'Faturanız başarıyla işlendi',
    content: '<b>ID: 7731451</b> ile alakalı mesaj gelecek'
  }
]

const HeaderNotificationsMenu = () => {
  const [visibleNotifications, setVisibleNotifications] = useState(new Array(notificationsData.length).fill(true))

  const handleNotificationClose = (index: number) => {
    const updatedVisibility = [...visibleNotifications]
    updatedVisibility[index] = false
    setVisibleNotifications(updatedVisibility)
  }

  const clearAllNotifications = () => {
    setVisibleNotifications(new Array(notificationsData.length).fill(false))
  }

  const hasVisibleNotifications = visibleNotifications.some((visible) => visible)

  return (
    <Dropdown
      dropdownRender={() => (
        <div
          style={{
            backgroundColor: 'var(--white)',
            borderRadius: '0.5rem',
            boxShadow: '0rem 0.125rem 0.75rem 0rem rgba(0, 0, 0, 0.05)',
            minWidth: '20.625rem'
          }}
        >
          <Flex align='center' justify='space-between' style={{ padding: '0.75rem 1.25rem' }}>
            <DropdownTitle>Bildirimler</DropdownTitle>
            <NotificationCount>{visibleNotifications.filter((visible) => visible).length} okunmamış</NotificationCount>
          </Flex>
          <Divider style={{ margin: 0 }} />
          <Divider style={{ margin: 0 }} />
          <Flex vertical>
            <AnimatePresence>
            {notificationsData.map(
              (notification, index) =>
                visibleNotifications[index] && (
                    <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.2 }}
                    key={index}
                    >
                    <Notification>
                      <Content>
                        <Icon color={notification.color}>{notification.icon}</Icon>
                        <Texts color={notification.color}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: notification.title
                            }}
                            />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: notification.content
                            }}
                            />
                        </Texts>
                      </Content>
                      <CloseButton onClick={() => handleNotificationClose(index)}>
                        <CloseIcon />
                      </CloseButton>
                    </Notification>
                    <Divider style={{ margin: 0 }} />
                    </motion.div>
                    )
                    )}
                    </AnimatePresence>
          </Flex>
          <Flex
            align='center'
            justify={hasVisibleNotifications ? 'space-between' : 'center'}
            style={{ padding: '0.75rem 1.25rem' }}
          >
            <Button
              type='primary'
              style={{
                minHeight: '2.375rem',
                minWidth: '7.5rem',
                width: hasVisibleNotifications ? 'auto' : '100%'
              }}
            >
              Tümünü Gör{' '}
            </Button>
            {hasVisibleNotifications && (
              <Button type='dashed' style={{ minHeight: '2.375rem', minWidth: '7.5rem' }} onClick={clearAllNotifications}>
                Tümünü Temizle
              </Button>
            )}
          </Flex>
        </div>
      )}
    >
      <HeaderItem>
        <Badge
          count={visibleNotifications.filter((visible) => visible).length}
          size='small'
          style={{ backgroundColor: '#23B7E5', fontSize: '0.625rem'}}
        >
          <NotificationIcon size="1.25rem" />
        </Badge>
      </HeaderItem>
    </Dropdown>
  )
}

export default HeaderNotificationsMenu
