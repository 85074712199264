import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.375rem;
  padding-right: 1.375rem;
  height: 100%;
  color: var(--text-second);
`
