import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 2rem;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
`

export const SubscriptionBox = styled.div`
  background: #FF3D001a;
  color: #FF3D00;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
`

export const SBTexts = styled.div`
  border: 1px solid #FF3D001a;
  border-bottom: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 14px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`

export const SBText = styled.div`
  font-weight: 700;
  font-size: 14px;
`

export const SBCount = styled.div`
  font-size: 32px;
  font-weight: 900;
`

export const SBFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  gap: 8px;
  background: #FF3D00;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: #FF6E40;
  }
`

export const SubscriptionDetailBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 400px;

  @media (max-width: 1300px) {
    max-width: 100%;
    gap: 24px;
    border-top: 1px solid rgba(var(--black-rgb), 0.07);
    padding-top: 32px;
    font-size: 14px;
    svg {
      width: 16px;
    }
    button {
      font-size: 12px;
    }
  }
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`

export const ItemText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const ItemContent = styled.div`
font-weight: 700;
`
