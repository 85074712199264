import { Container } from './styles'

import HeaderProfileMenu from './profile'
import HeaderNotificationsMenu from './notifications'
import HeaderAppsMenu from './apps'
import HeaderSearchButton from './search'
import HeaderUserSettingsButton from './settings'

import useWindowWidth from '../../../../utils/useWindowWidth'

const HeaderRight = () => {
  const width = useWindowWidth()
  return (
    <Container>
      {width > 768 ? (
        <>
          <HeaderSearchButton />
          <HeaderUserSettingsButton />

          <HeaderAppsMenu />
          <HeaderNotificationsMenu />
          <HeaderProfileMenu />
        </>
      ) : (
        <>
          <HeaderSearchButton />
          <HeaderNotificationsMenu />
          <HeaderProfileMenu />
        </>
      )}
    </Container>
  )
}

export default HeaderRight
