import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpRequest, RequestType } from '../../plugins/httpRequest'
import { Suppliers } from '../../interfaces/suppliers';
import { PaginationReponse } from '../../interfaces/general'

export const getSuppliers = createAsyncThunk(
  'getSuppliers',
  async ({ page, pageSize = 10 }: { page: number; pageSize?: number }) => {
    const response = await HttpRequest<null, PaginationReponse<Suppliers>>({
      url: 'suppliers',
      method: RequestType.GET,
      params: {
        page: page,
        pageSize: pageSize
      }
    })
    return response
  }
)

export const getSupplierById = createAsyncThunk('getSupplierById', async function (supplierId: string) {
  const response = await HttpRequest({
    url: `suppliers/${supplierId}`,
    method: RequestType.GET
  })
  return response
})

export const createSupplier = createAsyncThunk('createSupplier', async function (supplier: Suppliers) {
  const response = await HttpRequest({
    url: 'suppliers',
    method: RequestType.POST,
    body: supplier
  })
  return response
})

export const updateSupplier = createAsyncThunk('updateSupplier', async function ({ supplierId, supplier }: { supplierId: string; supplier: Suppliers }) {
  const response = await HttpRequest({
    url: `suppliers/${supplierId}`,
    method: RequestType.PATCH,
    body: supplier
  })
  return response
})

export const deleteSupplier = createAsyncThunk('deleteSupplier', async function (supplierId: string) {
  const response = await HttpRequest({
    url: `suppliers/${supplierId}`,
    method: RequestType.DELETE
  })
  return response
})
