import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 0.75rem;
  background: var(--white);
  margin-top: 1.625rem;
  box-shadow: 0rem 0.125rem 0.125rem 0rem rgba(0, 0, 0, 0.06);
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.15);
  justify-content: space-between;
  padding: 1rem 1.5rem;
`
export const Title = styled.div<{ $title_lenght: number }>`
  color: var(--text-second);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Roboto', sans-serif;
  font-size: ${({ $title_lenght }) => ($title_lenght > 30 ? '1.125rem' : '1.1875rem')};
  font-weight: 700;
`
export const Action = styled.div`
  background: rgba(var(--primary-rgb), 0.15);
  border-radius: 0.375rem;
  color: var(--primary);
  font-size: 0.6875rem;
  display: flex;
  align-items: center;
  gap: 0.125rem;
  padding: 0.25rem 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  min-height: 1.5rem;

  svg {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    background: rgba(var(--primary-rgb), 0.25);

    svg {
      transform: translateX(0.125rem);
    }
  }

  @media (max-width: 48rem) {
    display: none;
  }
`

export const Content = styled.div`
  padding: 1.0625rem 1.25rem;
  background: rgba(var(--black-rgb), 0.01);
`

export const BottomButton = styled.div`
  background: rgba(var(--primary-rgb), 0.15);
  text-align: center;
  border-radius: 0rem 0rem 0.75rem 0.75rem;
  color: var(--primary);
  font-size: 1rem;
  font-weight: 600;
  padding: 0.625rem 0rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  a {
    &:hover {
      color: var(--primary);
    }
  }

  &:hover {
    background: rgba(var(--primary-rgb), 0.25);
  }
`
